import { useMatch } from "@tanstack/react-router";

import { Actions } from "../../components";
import { RoleAddContent, RoleDeleteContent, RoleEditContent } from "../role";
import { UserDeleteContent } from "./delete";
import { UserEditContent } from "./edit";
import { UserImpersonateContent } from "./impersonate";

export const UserActionPanel = () => {
  const { Modal, Slide, props } = useMatch({
    from: "/_admin/users/$userId",
    select: (s) => {
      const props = {
        userId: s.params.userId,
        entityType: s.search.entity,
        roleId: s.search.role || "",
      };

      switch (s.search.action) {
        case "add":
          return { Slide: RoleAddContent, props };
        case "delete":
          if (s.search.role) return { Modal: RoleDeleteContent, props };
          return { Modal: UserDeleteContent, props };
        case "edit":
          if (s.search.role) return { Slide: RoleEditContent, props };
          return { Slide: UserEditContent, props };
        case "impersonate":
          return {
            Modal: UserImpersonateContent,
            props,
          };
      }

      return { props };
    },
  });

  return (
    <Actions
      modal={Modal && <Modal {...props} />}
      slide={Slide && <Slide {...props} />}
    />
  );
};
