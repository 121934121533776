import { IconBucketDroplet, IconTemperatureSun } from "@tabler/icons-react";
import { useInfiniteQuery, useSuspenseQuery } from "@tanstack/react-query";
import { formatDate, subDays } from "date-fns";

import {
  displayPercent,
  displaySignal,
  displayTemperature,
  isNotNullOrUndefined,
  roundFloat,
  specialChars,
} from "@joy/shared-utils";

import {
  Box,
  Chart,
  displayBattery,
  displaySignalStrength,
  pageParts,
} from "../../components";
import { IndicatorRow } from "../../components/indicators";
import {
  gaugeMax,
  gaugeObservationQuery,
  gaugeQuery,
  gaugeUnit,
} from "../../data";

export const GaugeObservationsPanel = ({ gaugeId }: { gaugeId: string }) => {
  const { data, isLoading } = useInfiniteQuery(
    gaugeObservationQuery(gaugeId, 14),
  );
  const {
    data: { technology, observation },
  } = useSuspenseQuery(gaugeQuery(gaugeId));

  const maxReading = data?.length
    ? Math.max(gaugeMax[technology], ...data.map((d) => d.reading))
    : 100;

  return (
    <div className={pageParts.page}>
      <Box header={{ title: "Current Status" }}>
        <IndicatorRow
          indicators={[
            {
              label: "Reading",
              icon: <IconBucketDroplet />,
              value: `${
                isNotNullOrUndefined(observation?.reading)
                  ? roundFloat(observation.reading, 3)
                  : specialChars.endash
              } ${gaugeUnit[technology]}`,
            },
            isNotNullOrUndefined(observation?.temperature)
              ? {
                  label: "Temperature",
                  icon: <IconTemperatureSun />,
                  value: displayTemperature(observation.temperature),
                }
              : undefined,
            isNotNullOrUndefined(observation?.voltage)
              ? {
                  label: "Battery",
                  icon: displayBattery(observation.voltage),
                  value: displayPercent(observation.voltage),
                }
              : undefined,

            isNotNullOrUndefined(observation?.signal)
              ? {
                  label: "Signal",
                  icon: displaySignalStrength(observation.signal),
                  value: displaySignal(observation.signal),
                }
              : undefined,
          ]}
        />
      </Box>
      <Box header={{ title: "History" }}>
        <Chart
          xScale={{
            type: "time",
            clamp: true,
          }}
          yScale={{ type: "linear", clamp: true, domain: [0, 100] }}
          yFormat={(d) => `${d}`}
          data={data}
          loading={isLoading}
          mock={() => {
            const end = new Date();
            return [
              {
                id: "",
                reading: 20,
                updatedAt: subDays(end, 14),
              },
              {
                id: "",
                reading: 60,
                updatedAt: subDays(end, 11),
              },
              {
                id: "",
                reading: 50,
                updatedAt: subDays(end, 6),
              },
              {
                id: "",
                reading: 85,
                updatedAt: subDays(end, 2),
              },
              {
                id: "",
                reading: 80,
                updatedAt: end,
              },
            ];
          }}
          series={[
            {
              key: "reading",
              label: "Reading",
              variant: "emerald",
              default: true,
              type: "area",
              xAccessor: (d) => new Date(d.updatedAt),
              yAccessor: (d) => (d.reading / maxReading) * 100,
            },
            {
              key: "signal",
              label: "Signal",
              variant: "purple",
              default: true,
              type: "line",
              xAccessor: (d) => new Date(d.updatedAt),
              yAccessor: (d) => d.signal && (d.signal / 30) * 100,
            },
            {
              key: "temperature",
              label: "Temperature",
              variant: "red",
              default: false,
              type: "line",
              xAccessor: (d) => new Date(d.updatedAt),
              yAccessor: (d) => d.temperature,
            },
            {
              key: "battery",
              label: "Battery",
              variant: "sky",
              default: false,
              type: "line",
              xAccessor: (d) => new Date(d.updatedAt),
              yAccessor: (d) => d.voltage,
            },
          ]}
          tooltip={({ item }) => (
            <>
              <p className="font-semibold">
                {item.updatedAt
                  ? formatDate(item.updatedAt, "LLLL d, haaa")
                  : specialChars.endash}
              </p>
              <p>
                <span className="font-semibold">Reading:</span>{" "}
                {observation?.reading
                  ? roundFloat(observation.reading, 3)
                  : specialChars.endash}{" "}
                {gaugeUnit[technology]}
              </p>
              {item.signal && (
                <p>
                  <span className="font-semibold">Signal:</span>{" "}
                  {displaySignal(item.signal)}
                </p>
              )}
              {item.temperature && (
                <p>
                  <span className="font-semibold">Temp:</span>{" "}
                  {displayTemperature(item.temperature)}
                </p>
              )}
              {item.voltage && (
                <p>
                  <span className="font-semibold">Battery:</span>{" "}
                  {displayPercent(item.voltage)}
                </p>
              )}
            </>
          )}
        />
      </Box>
    </div>
  );
};
