import { standardSchemaValidator, useForm } from "@tanstack/react-form";
import {
  queryOptions,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";

import { currentTimezone } from "@joy/shared-utils";

import { useValidators } from "../../hooks/validation";
import { accountQuery } from "./accounts";
import { request, requestFn } from "./base";
import {
  CreateScheduleDocument,
  DeleteScheduleDocument,
  ScheduleDocument,
  ScheduleEntityType,
  ScheduleFragmentFragment,
  ScheduleInterval,
  UpdateScheduleDocument,
} from "./operations.generated";
import { rolesQuery } from "./roles";

export const scheduleQuery = (id: string, initial?: ScheduleFragmentFragment) =>
  queryOptions({
    queryKey: ["schedule", id],
    initialData: initial
      ? { __typename: "Query", schedule: initial }
      : undefined,
    queryFn: () => request(ScheduleDocument, { id }),
    select: (data) => ({ ...data.schedule! }),
  });

const accountScheduleValidation = {
  interval: z.enum(["Week", "Month"], {
    invalid_type_error: "Please select a schedule type",
  }),
  timezone: z.object(
    { value: z.string() },
    { invalid_type_error: "Please select a timezone" },
  ),
  date: z.string().regex(/^\d*$/, "Must be a valid number"),
  time: z.object(
    { hour: z.number().min(0).max(23) },
    { invalid_type_error: "Please select a time" },
  ),
  users: z.array(
    z.object(
      { id: z.string(), email: z.string().email() },
      { invalid_type_error: "Please select a user" },
    ),
  ),
};

const createScheduleFn = requestFn(CreateScheduleDocument);

export const useCreateSchedule = ({
  entityId,
  entityType,
}: {
  entityId: string;
  entityType: ScheduleEntityType;
}) => {
  const { data: users } = useInfiniteQuery(rolesQuery(entityId, entityType));
  const queryClient = useQueryClient();

  const { error, mutateAsync, reset } = useMutation({
    mutationFn: createScheduleFn,
    onSuccess: async (result) => {
      queryClient.invalidateQueries(scheduleQuery(result.createSchedule.id));

      switch (result.createSchedule.entityType) {
        case "account": {
          queryClient.setQueryData(
            accountQuery(result.createSchedule.entityId).queryKey,
            (existing) => {
              if (!existing?.account) return undefined;

              return {
                ...existing,
                account: {
                  ...existing.account,
                  schedules: [
                    result.createSchedule,
                    ...existing.account.schedules,
                  ],
                },
              };
            },
          );
          break;
        }
      }
    },
  });

  const form = useForm({
    defaultValues: {
      timezone: {
        value: currentTimezone(),
      },
      interval: "Week" as ScheduleInterval,
      date: "1",
      days: {
        Sunday: true,
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true,
      },
      startTime: {
        hour: 8,
      },
      users: [] as { id: string; email: string }[],
    },
    onSubmit: async ({
      value: { interval, timezone, date, days, startTime, users },
    }) => {
      await mutateAsync({
        id: crypto.randomUUID(),
        entityId,
        entityType,
        fields: {
          interval,
          days,
          date: parseInt(date),
          startTime: {
            hour: startTime.hour,
            minute: 0,
          },
          timezone: timezone.value,
          users: users.map((user) => user.email),
        },
      });
    },
    onSubmitInvalid: () => {
      reset();
    },
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(
    accountScheduleValidation,
    form.state.submissionAttempts,
  );

  return { users, error, form, validators };
};

const updateScheduleFn = requestFn(UpdateScheduleDocument);

export const useUpdateSchedule = (
  id: string,
  initial?: ScheduleFragmentFragment,
) => {
  const { data } = useSuspenseQuery(scheduleQuery(id, initial));
  const { data: users } = useInfiniteQuery(
    rolesQuery(data.entityId, data.entityType),
  );

  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: updateScheduleFn,
    onSuccess: async (result) => {
      queryClient.invalidateQueries(scheduleQuery(id));

      switch (result.updateSchedule.entityType) {
        case "account": {
          queryClient.setQueryData(
            accountQuery(result.updateSchedule.entityId).queryKey,
            (existing) => {
              if (!existing?.account) return undefined;

              return {
                ...existing,
                account: {
                  ...existing.account,
                  schedules: existing.account.schedules.map((s) =>
                    s.id === result.updateSchedule.id
                      ? result.updateSchedule
                      : s,
                  ),
                },
              };
            },
          );
          break;
        }
      }
    },
  });

  const form = useForm({
    defaultValues: {
      timezone: {
        value: data.timezone || currentTimezone(),
      },
      interval: data.interval || "Week",
      date: (data.date || 1).toString(),
      days: {
        Sunday: data.days?.Sunday ?? true,
        Monday: data.days?.Monday ?? true,
        Tuesday: data.days?.Tuesday ?? true,
        Wednesday: data.days?.Wednesday ?? true,
        Thursday: data.days?.Thursday ?? true,
        Friday: data.days?.Friday ?? true,
        Saturday: data.days?.Saturday ?? true,
      },
      startTime: {
        hour: data.startTime.hour ?? 8,
      },
      users: data.users || [],
    },
    onSubmit: async ({
      value: { interval, timezone, date, days, startTime, users },
    }) => {
      await mutateAsync({
        id,
        fields: {
          interval,
          days,
          date: parseInt(date),
          startTime: {
            hour: startTime.hour,
            minute: 0,
          },
          timezone: timezone.value,
          users: users.map((user) => user.email),
        },
      });
    },
    onSubmitInvalid: () => {
      reset();
    },
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(
    accountScheduleValidation,
    form.state.submissionAttempts,
  );

  return { users, data, error, form, validators };
};

export type AccountScheduleContentProps = Pick<
  ReturnType<typeof useUpdateSchedule>,
  "form" | "validators" | "users"
>;

const deleteScheduleFn = requestFn(DeleteScheduleDocument);

export const useDeleteSchedule = (
  id: string,
  initial?: ScheduleFragmentFragment,
) => {
  const queryClient = useQueryClient();
  const { data } = useSuspenseQuery(scheduleQuery(id, initial));
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: deleteScheduleFn,
    onSuccess: async (deleted) => {
      if (deleted.deleteSchedule) {
        queryClient.removeQueries(scheduleQuery(id));

        switch (data.entityType) {
          case "account": {
            queryClient.setQueryData(
              accountQuery(data.entityId).queryKey,
              (existing) => {
                if (!existing?.account) return undefined;

                return {
                  ...existing,
                  account: {
                    ...existing.account,
                    schedules: existing.account.schedules.filter(
                      (s) => s.id !== id,
                    ),
                  },
                };
              },
            );
            break;
          }
        }
      }
    },
  });

  return { data, error, isPending, onDelete: () => mutateAsync({ id }) };
};
