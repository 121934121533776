import { IconMail } from "@tabler/icons-react";
import { infiniteQueryOptions } from "@tanstack/react-query";

import { Word } from "../helpers";
import { request } from "./base";
import { EmailsDocument } from "./operations.generated";

export const email: Word = {
  icon: IconMail,
  article: "an",
  singular: "email",
  plural: "emails",
};

export const userEmailsQuery = (id: string) =>
  infiniteQueryOptions({
    queryKey: ["emails", id],
    queryFn: ({ pageParam }) =>
      request(EmailsDocument, {
        userId: id,
        limit: 100,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.emails.next,
    initialPageParam: "",
    enabled: !!id,
    select: (data) =>
      data.pages
        .flatMap((p) => p.emails.emails)
        .map((e) => ({ latest: e.events.at(-1), ...e })),
  });
