import {
  Button as HeadlessButton,
  ButtonProps as HeadlessButtonProps,
} from "@headlessui/react";
import { Link, LinkProps, RegisteredRouter } from "@tanstack/react-router";
import clsx from "clsx";
import {
  AnchorHTMLAttributes,
  DetailedHTMLProps,
  ForwardedRef,
  ReactNode,
  forwardRef,
} from "react";

import { tw } from "@joy/shared-utils";

import { scheme } from "./scheme";

export const buttonVariants = {
  action: tw`bg-sky-600 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600`,
  brand: tw`bg-emerald-600 text-white shadow-sm hover:bg-emerald-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600`,
  cancel: tw`text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:ring-transparent`,
  danger: tw`bg-red-600 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700`,
  standard: tw`${scheme.standard} shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:ring-transparent`,
};

export const linkVariants = {
  action: tw`text-sky-600 hover:text-sky-700`,
  brand: tw`text-emerald-600 hover:text-emerald-700`,
  cancel: tw`text-gray-600 hover:text-gray-700`,
  standard: tw`text-gray-800 underline hover:text-gray-900`,
  danger: tw`text-red-700 hover:text-red-800`,
} satisfies Record<keyof typeof buttonVariants, string>;

export const menuVariants = {
  action: tw`text-sky-600 hover:bg-sky-100 hover:text-sky-800 aria-pressed:bg-sky-50 aria-pressed:text-sky-800 data-[focus]:bg-sky-100 data-[open]:bg-sky-100 data-[focus]:text-sky-800`,
  brand: tw`text-emerald-600 hover:bg-emerald-100 hover:text-emerald-800 aria-pressed:bg-emerald-50 aria-pressed:text-emerald-800 data-[focus]:bg-emerald-100 data-[open]:bg-emerald-100 data-[focus]:text-emerald-800`,
  cancel: tw`text-gray-500 hover:bg-gray-100 hover:text-gray-800 aria-pressed:bg-gray-50 aria-pressed:text-gray-800 data-[focus]:bg-gray-100 data-[open]:bg-gray-100 data-[focus]:text-gray-800`,
  standard: tw`text-gray-600 hover:bg-sky-100 hover:text-sky-800 aria-pressed:bg-sky-50 aria-pressed:text-sky-800 data-[focus]:bg-sky-100 data-[open]:bg-sky-100 data-[focus]:text-sky-800`,
  danger: tw`text-red-700 hover:bg-red-100 hover:text-red-800 aria-pressed:bg-red-50 aria-pressed:text-red-800 data-[focus]:bg-red-100 data-[open]:bg-red-100 data-[focus]:text-red-800`,
} satisfies Record<keyof typeof buttonVariants, string>;

export const buttonKinds = {
  standard: tw`flex flex-none items-center justify-center gap-2 whitespace-nowrap rounded-md px-3 py-2 text-sm font-semibold tracking-wide disabled:cursor-not-allowed disabled:opacity-50`,
  full: tw`mt-5 flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-sm font-semibold tracking-wide disabled:cursor-not-allowed disabled:opacity-50`,
  link: tw`inline-flex max-w-full items-center gap-2 font-semibold disabled:cursor-not-allowed disabled:opacity-50`,
  menu: tw`flex items-center gap-2 rounded-sm p-1.5 text-sm font-medium transition-colors duration-100 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 sm:rounded-lg`,
};

const kindVariants = {
  standard: buttonVariants,
  full: buttonVariants,
  link: linkVariants,
  menu: menuVariants,
} satisfies Record<keyof typeof buttonKinds, typeof buttonVariants>;

export type ButtonClassProps = {
  kind?: keyof typeof buttonKinds;
  variant?: keyof typeof buttonVariants;
  className?: string;
};

export type ButtonContentProps = {
  icon?: (props: { className: string; stroke?: number }) => ReactNode;
  pressed?: boolean;
  text?: string;
  collapseText?: boolean;
};

const buttonClass = ({
  kind = "standard",
  variant = "action",
  className,
}: ButtonClassProps) =>
  clsx(className, buttonKinds[kind], kindVariants[kind][variant]);

const buttonContent = ({
  icon: Icon,
  text,
  collapseText,
  pressed,
}: ButtonContentProps) => (
  <>
    {Icon && <Icon className="size-5" stroke={pressed ? 2.4 : 2} />}
    {text && (
      <span
        className={clsx(
          "truncate whitespace-nowrap",
          Icon && collapseText && "sr-only sm:not-sr-only",
        )}
      >
        {text}
      </span>
    )}
  </>
);

export const ExternalLink = ({
  kind,
  variant,
  className,
  icon,
  pressed,
  text,
  collapseText,
  ...props
}: ButtonClassProps &
  ButtonContentProps &
  DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >) => (
  <a {...props} className={buttonClass({ kind, variant, className })}>
    {buttonContent({ icon, pressed, text, collapseText })}
  </a>
);

export type LinkButtonProps<TTo extends string = ""> = ButtonClassProps &
  ButtonContentProps &
  LinkProps<"a", RegisteredRouter, string, TTo>;

export const LinkButton = <Tto extends string>({
  kind,
  variant,
  className,
  icon,
  pressed,
  text,
  collapseText,
  ...props
}: LinkButtonProps<Tto>) => (
  <Link {...props} className={buttonClass({ kind, variant, className })}>
    {buttonContent({ icon, pressed, text, collapseText })}
  </Link>
);

export type ButtonProps = ButtonClassProps &
  ButtonContentProps &
  HeadlessButtonProps;

export const Button = forwardRef(
  (
    {
      kind,
      variant,
      className,
      icon,
      collapseText,
      text,
      pressed,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <HeadlessButton
      {...props}
      ref={ref}
      aria-pressed={pressed}
      className={buttonClass({ kind, variant, className })}
    >
      {buttonContent({ icon, text, collapseText, pressed })}
    </HeadlessButton>
  ),
);
