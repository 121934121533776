import { createFileRoute, redirect } from "@tanstack/react-router";
import { format } from "date-fns";

export const Route = createFileRoute("/_super/invoices/$monthYear")({
  params: {
    parse: ({ monthYear }) => {
      const [year, month] = monthYear.split("-").map((v) => parseInt(v, 10));
      if (!year || !month) throw redirect({ to: "/invoices" });

      return {
        monthYear: { year, month },
      };
    },
    stringify: ({ monthYear }) => ({
      monthYear: `${monthYear.year}-${monthYear.month.toString().padStart(2, "0")}`,
    }),
  },
  head: ({ params }) => ({
    meta: [
      {
        title: format(
          new Date(params.monthYear.year, params.monthYear.month - 1),
          "MMMM yyyy",
        ),
      },
    ],
  }),
});
