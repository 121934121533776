import { Checkbox, CheckboxProps, Field, Label } from "@headlessui/react";
import { IconCheck } from "@tabler/icons-react";
import { FieldApi } from "@tanstack/react-form";
import clsx from "clsx";
import { ReactNode } from "react";

import { tw } from "@joy/shared-utils";

const checkVariants = {
  sky: tw`data-[checked]:bg-sky-700 data-[checked]:ring-sky-700`,
  emerald: tw`data-[checked]:bg-emerald-500 data-[checked]:ring-emerald-500`,
  red: tw`data-[checked]:bg-red-500 data-[checked]:ring-red-500`,
  purple: tw`data-[checked]:bg-purple-500 data-[checked]:ring-purple-500`,
};

export type CheckVariant = keyof typeof checkVariants;

type CheckInputProps = {
  label?: string;
  description?: ReactNode;
  variant?: CheckVariant;
};

export const CheckInput = ({
  id,
  name,
  label,
  description,
  className,
  variant = "sky",
  ...props
}: CheckInputProps & CheckboxProps) => (
  <Field className={clsx("relative flex items-center gap-3", className)}>
    <Checkbox
      id={id}
      name={name}
      className={clsx(
        "group flex size-4 flex-none items-center justify-center rounded p-0.5 text-white outline-offset-2 ring-1 ring-inset ring-gray-300 transition data-[disabled]:opacity-40",
        checkVariants[variant],
      )}
      {...props}
    >
      <IconCheck
        className="size-full opacity-0 transition-opacity group-data-[checked]:opacity-100"
        stroke={4}
      />
    </Checkbox>
    {!!(label || description) && (
      <Label htmlFor={id} className="text-sm font-medium text-gray-900">
        {label}{" "}
        <span className="truncate font-normal text-gray-500">
          {description}
        </span>
      </Label>
    )}
  </Field>
);

export const CheckInputField = <D extends boolean>({
  field,
  ...props
}: {
  field: FieldApi<any, any, any, any, D>;
} & CheckInputProps &
  CheckboxProps) => (
  <CheckInput
    id={field.name}
    name={field.name}
    checked={field.state.value}
    onBlur={field.handleBlur}
    onChange={(e) => field.handleChange(e as D)}
    {...props}
  />
);
