import isEqual from "lodash/isEqual.js";

export type KeyFromVal<T, V> = {
  [K in keyof T]: V extends T[K] ? K : never;
}[keyof T];

export type Inverse<M extends Record<string | number, string | number>> = {
  [K in M[keyof M]]: KeyFromVal<M, K>;
};

export const invert = <M extends Record<string | number, string | number>>(
  input: M,
) =>
  Object.fromEntries(
    Object.entries(input).map(([k, v]) => [v, k]),
  ) as Inverse<M>;

export const diffKeys = (
  previous: object,
  current: object,
  except: string[],
) => {
  const previousKeys = Object.keys(previous);
  const currentKeys = Object.keys(current);

  const deletedKeys = previousKeys.filter((p) => currentKeys.indexOf(p) < 0);
  const updatedKeys = currentKeys.filter(
    (c) =>
      except.indexOf(c) < 0 &&
      !isEqual(
        (current as Record<string, any>)[c],
        (previous as Record<string, any>)[c],
      ),
  );

  return [...deletedKeys, ...updatedKeys];
};
