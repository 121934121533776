import { IconAlertTriangle } from "@tabler/icons-react";
import { format } from "date-fns";

import { ModalContent } from "../../components";
import { useDeleteInvoice } from "../../data";

export const InvoiceDeleteContent = ({ invoiceId }: { invoiceId: string }) => {
  const { data, error, isPending, onDelete } = useDeleteInvoice(invoiceId);

  return (
    <ModalContent
      icon={IconAlertTriangle}
      variant="danger"
      title={`Delete invoice for ${data.customer.name} - ${format(
        new Date(data.year, data.month - 1, 1),
        "MMMM yyyy",
      )}`}
      description={`Are you sure you want to delete ${data.linkedInvoice ? `invoice ${data.linkedInvoice.number}` : "this invoice"}? This action cannot be undone.`}
      error={error}
      onSubmit={onDelete}
      loading={{ show: isPending, text: "Deleting..." }}
      buttons={[
        { text: "Delete", type: "submit", variant: "danger", autoFocus: true },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
