import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/_super/invoices/")({
  beforeLoad: async () => {
    const startDate = new Date();
    throw redirect({
      to: "/invoices/$monthYear",
      params: {
        monthYear: {
          year: startDate.getFullYear(),
          month: startDate.getMonth() + 1,
        },
      },
      replace: true,
    });
  },
});
