import { IconEye, IconEyeOff, IconSearch } from "@tabler/icons-react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Link, createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import { z } from "zod";

import {
  Button,
  List,
  ListInput,
  StatsHeader,
  TablePage,
  TextInput,
  Time,
  Tree,
  pageParts,
} from "../../../components";
import { log, logGroupsQuery, logsQuery } from "../../../data";
import { useDebounce, useTable } from "../../../hooks";

const validateSearch = z.object({
  group: z.string().optional(),
  from: z.coerce.date().optional(),
  filter: z.string().optional(),
});

export const Route = createFileRoute("/_god/dev/logs")({
  validateSearch,
  component: Component,
  head: () => ({ meta: [{ title: "Log Search" }] }),
});

const levelColours: Record<string, string> = {
  INFO: "bg-sky-100",
  ERROR: "bg-red-100",
  WARN: "bg-yellow-100",
  DEBUG: "bg-blue-100",
  INIT_START: "bg-orange-100",
  START: "bg-orange-100",
  END: "bg-orange-100",
  REPORT: "bg-orange-100",
};

export function Component() {
  const logGroups = useInfiniteQuery(logGroupsQuery());
  const navigate = Route.useNavigate();
  const { group, from, filter } = Route.useSearch();

  const queryOptions = useDebounce(() => ({ from, filter }), 500, [
    from,
    filter,
  ]);
  const logs = useTable(logsQuery(group, queryOptions), {
    columnDefs: (c) => [
      c.accessor("timestamp", {
        meta: {
          className: "text-xs flex-1",
        },
        cell: (v) => <Time date={v.getValue()} />,
      }),
      c.accessor("requestId", {
        meta: {
          className: "text-xs",
        },
      }),
      c.accessor("ipAddress", {
        meta: {
          className: "text-xs",
        },
      }),
      c.accessor("level", {
        meta: {
          className: "text-xs",
        },
        filterFn: "arrIncludesSome",
        cell: (v) => {
          const value = v.getValue() || "INFO";
          return (
            <div
              className={clsx(
                "rounded-full px-3 py-0.5 tracking-wide",
                levelColours[value] || levelColours.INFO,
              )}
            >
              {value}
            </div>
          );
        },
      }),
      c.accessor("parsed", {
        meta: {
          className: "w-full",
        },
        cell: (v) =>
          v.getValue()?.map((item, idx) => (
            <Tree
              key={idx}
              className="text-sm"
              data={item}
              renderers={{
                input: (v) => (
                  <Link
                    to="/dev/parse"
                    search={{ type: "tektcp", data: v }}
                    target="_blank"
                    className="whitespace-pre-line text-wrap break-all underline decoration-dotted"
                  >
                    {v}
                  </Link>
                ),
              }}
            />
          )),
      }),
      c.accessor("content", {
        meta: {
          className: "w-full",
        },
        cell: (v) => {
          const [show, setShow] = useState(false);
          return (
            v.getValue() && (
              <div>
                <span
                  className={clsx(
                    "whitespace-pre-line text-wrap break-all font-mono text-sm",
                    show ? "" : "line-clamp-1",
                  )}
                >
                  {v.getValue()}
                </span>
                <Button
                  className="text-xs"
                  kind="menu"
                  icon={show ? IconEyeOff : IconEye}
                  text={show ? "Hide Log" : "Show Log"}
                  onClick={() => setShow(!show)}
                />
              </div>
            )
          );
        },
      }),
    ],
    canFilter: false,
    word: log,
  });

  const levelOptions = useMemo(
    () =>
      Array.from(
        logs.table.table.getColumn("level")?.getFacetedUniqueValues().keys() ||
          [],
      ),
    [logs.table.table.getColumn("level")?.getFacetedUniqueValues()],
  );

  return (
    <TablePage
      {...logs}
      views={{ table: List }}
      actions={
        <div className={pageParts.actionItems}>
          <div>
            <ListInput
              className="w-80 max-w-full"
              placeholder="Select a log group"
              options={logGroups.data || []}
              value={group || null}
              onChange={(e) =>
                navigate({
                  search: { group: e || undefined, filter, from },
                  replace: true,
                })
              }
            />
          </div>
          <div>
            <TextInput
              className="max-w-80"
              icon={IconSearch}
              placeholder="Search logs..."
              value={filter}
              onChange={(e) =>
                navigate({
                  search: { filter: e.currentTarget.value, group, from },
                  replace: true,
                })
              }
            />
          </div>
          <div>
            <ListInput
              className="w-36 max-w-full"
              options={levelOptions}
              placeholder="Log level"
              empty="No log levels"
              value={(logs.column.value[0]?.value as string[]) || []}
              onChange={(o) =>
                logs.column.onChange(
                  o.length ? [{ id: "level", value: o }] : [],
                )
              }
              maxDisplay={1}
              multiple
            />
          </div>
          <div className="flex-1" />
          <div>
            <TextInput
              placeholder="From"
              type="datetime-local"
              value={from ? format(from, "yyyy-MM-dd'T'HH:mm") : ""}
              onChange={(e) =>
                navigate({
                  search: {
                    from: new Date(e.currentTarget.value),
                    group,
                    filter,
                  },
                  replace: true,
                })
              }
            />
          </div>
        </div>
      }
    >
      <StatsHeader
        stats={[
          {
            label: "Log Groups",
            value: logGroups.data?.length,
          },
          ...logs.stats,
        ]}
      />
    </TablePage>
  );
}
