import { tw } from "@joy/shared-utils";

export const bg = "bg-gray-50";
export const text = "text-gray-900";

export const scheme = {
  standard: tw`${bg} ${text}`,
  dim: tw`text-gray-500`,
  brand: tw`text-emerald-700`,
};
