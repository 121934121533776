import { Clerk } from "@clerk/clerk-js/headless";
import Clarity from "@microsoft/clarity";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { TeamPermission, hasTeamPermission } from "@joy/shared-utils";

import { config } from "../config";

let tokenGetter: () => Promise<string | null | undefined> = () =>
  Promise.resolve(undefined);

export const getAuthToken = () => tokenGetter();

export type AuthContext = {
  client: Clerk;
  isAuthenticated: boolean;
  isImpersonating: boolean;
  email: string | undefined;
  hasTeamPermission: (needed: TeamPermission) => boolean;
};

export const useSetupAuth = () => {
  const initialized = useRef(false);
  const [loading, setLoading] = useState(false);
  const [context, setContext] = useState<AuthContext>();

  const initialize = useCallback(async () => {
    const client = new Clerk(config.authId, {
      proxyUrl: is_local ? "/auth" : undefined,
    });
    await client.load();

    if (client.session) tokenGetter = client.session.getToken;

    if (client.user) {
      Clarity.identify(client.user.id, client.session?.id);

      if (client.user.primaryEmailAddress)
        Clarity.setTag("email", client.user.primaryEmailAddress?.emailAddress);
      if (client.user.publicMetadata.jlteam)
        Clarity.setTag("jlteam", client.user.publicMetadata.jlteam);
    }

    setContext({
      client,
      isAuthenticated: !!client.user,
      isImpersonating: !!client.session?.actor,
      email: client.user?.primaryEmailAddress?.emailAddress,
      hasTeamPermission: hasTeamPermission(client.user?.publicMetadata.jlteam),
    });
  }, []);

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    const timeout = setTimeout(() => {
      setLoading(true);
    }, 1000);

    initialize()
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        clearTimeout(timeout);
        setTimeout(() => setLoading(false), 700);
      });
  }, []);

  return { loading, context };
};

const authContext = createContext<AuthContext>({
  client: null!,
  isAuthenticated: false,
  isImpersonating: false,
  email: undefined,
  hasTeamPermission: () => false,
});

export const AuthProvider = authContext.Provider;

export const useAuth = () => useContext(authContext);
