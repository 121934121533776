import { standardSchemaValidator, useForm } from "@tanstack/react-form";
import { z } from "zod";

import { useValidators } from "../../hooks";
import { useAuth } from "./context";

const profileValidation = {
  firstName: z.string().min(1, "Please enter your first name"),
  lastName: z.string().min(1, "Please enter your last name"),
};

export const useUpdateProfile = () => {
  const {
    client: { user },
  } = useAuth();

  const form = useForm({
    defaultValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      phone: user?.unsafeMetadata.phone || "",
      company: user?.unsafeMetadata.company || "",
      title: user?.unsafeMetadata.title || "",
    },
    onSubmit: async ({ value }) => {
      await user?.update({
        firstName: value.firstName,
        lastName: value.lastName,
        unsafeMetadata: {
          phone: value.phone,
          company: value.company,
          title: value.title,
        },
      });
    },
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(
    profileValidation,
    form.state.submissionAttempts,
  );

  return { form, validators, user };
};
