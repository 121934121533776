import clsx from "clsx";
import { VolumeUnits } from "convert-units/definitions/volume";

import {
  displayPercent,
  displayVolumeRatio,
  specialChars,
} from "@joy/shared-utils";

export type GaugeProps = {
  value: number | undefined;
  maximum: number | undefined;
  unit: VolumeUnits | null | undefined;
  percent: number | undefined;
  height: string;
  colour: string;
  alertLevels?: { threshold: number }[] | null;
};

export const Gauge = ({
  value,
  maximum,
  unit,
  percent,
  height,
  colour,
  alertLevels,
}: GaugeProps) => (
  <div className="mx-2 my-3 flex flex-col items-stretch">
    <div className="flex divide-x divide-gray-400 whitespace-nowrap">
      <div
        className="shrink pr-1 text-right font-semibold"
        style={{ flexBasis: height }}
      >
        {displayPercent(percent) || specialChars.endash}
      </div>
      <div className="flex-none pl-1">
        {displayVolumeRatio(value, maximum, unit) || specialChars.endash}
      </div>
    </div>
    <div className="mt-0.5">
      <svg
        viewBox="0 0 120 80"
        className={clsx("relative h-2 w-3 -translate-x-1/2")}
        style={{ left: height, color: colour }}
      >
        <polygon points="0,0 60,80 120,0" fill="currentColor" />
      </svg>
    </div>
    <div className="relative flex h-4 w-full items-stretch overflow-hidden rounded-br-lg rounded-tl-lg">
      <div style={{ width: height, backgroundColor: colour }} />
      <div className="flex-1 bg-gray-300" />
    </div>
    <div className="relative h-5 w-full">
      {alertLevels?.map(({ threshold }, idx) => (
        <div
          key={idx}
          style={{ left: `${threshold}%` }}
          className="absolute flex -translate-x-1/2 flex-col items-center justify-center text-sm font-semibold text-gray-500"
        >
          <div className="h-1 w-px bg-gray-300" />
          <span>{threshold}%</span>
        </div>
      ))}
    </div>
  </div>
);
