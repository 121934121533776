import clsx from "clsx";

import { toTitle } from "@joy/shared-utils";

import { TablePage, Time, dateCell } from "../../components";
import { email, userEmailsQuery } from "../../data";
import { useTable } from "../../hooks";

const statusColors = {
  queued: "bg-yellow-100",
  sent: "bg-green-100",
  failed: "bg-red-100",
};

export const UserEmailsPanel = ({ userId }: { userId: string }) => {
  const emails = useTable(userEmailsQuery(userId), {
    columnDefs: (c) => [
      c.accessor("status", {
        header: "Status",
        cell: (v) => (
          <span
            className={clsx(
              "rounded-full px-3 py-1 font-semibold",
              statusColors[v.getValue()],
            )}
          >
            {toTitle(v.getValue())}
          </span>
        ),
      }),
      c.accessor("createdAt", dateCell({ header: "Sent" })),
      c.accessor("latest", {
        header: "Latest Event",
        cell: (v) => (
          <Time
            prefix={v.getValue()?.__typename.replace("Event", "")}
            date={v.getValue()?.at}
          />
        ),
      }),
    ],
    word: email,
  });

  return <TablePage {...emails} />;
};
