import {
  IconBusinessplan,
  IconCalendarPlus,
  IconCurrencyDollar,
  IconHistory,
  IconNotebook,
  IconSquareRoundedPlus,
  IconTrashX,
} from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";

import { displayPrice, pluralize } from "@joy/shared-utils";

import { Entity, EntityCreated, EntityUpdated } from "../../components";
import { planQuery, useAuth } from "../../data";

export const PlanInfoPanel = ({ planId }: { planId: string }) => {
  const { hasTeamPermission } = useAuth();
  const { data } = useSuspenseQuery(planQuery(planId));

  return (
    <Entity
      title="Plan Info"
      edit={
        hasTeamPermission("super")
          ? {
              search: { action: "edit" },
              replace: true,
            }
          : undefined
      }
      item={data}
      details={[
        [
          [IconBusinessplan, "Code", (c) => c.code],
          [IconNotebook, "Description", (c) => c.description],
          [
            IconCurrencyDollar,
            "Amount",
            (c) => `${displayPrice(c.amount)} / ${c.model} / month`,
          ],
          [
            IconCalendarPlus,
            "Prepaid",
            (c) =>
              c.prepaid &&
              pluralize(c.prepaid, "1 prepaid month", "prepaid months"),
          ],
        ],
        [
          [IconSquareRoundedPlus, "Created at", EntityCreated],
          [IconHistory, "Updated", EntityUpdated],
        ],
      ]}
      actions={[
        hasTeamPermission("super")
          ? {
              kind: "link",
              variant: "danger",
              search: { action: "delete" },
              replace: true,
              icon: IconTrashX,
              text: "Delete Plan",
            }
          : undefined,
      ]}
    />
  );
};
