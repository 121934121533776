import {
  FieldProps,
  Field as HeadlessField,
  Label as HeadlessLabel,
  LabelProps,
} from "@headlessui/react";
import clsx from "clsx";
import { ForwardedRef, forwardRef } from "react";

import { tw } from "@joy/shared-utils";

export const fieldParts = {
  row: tw`flex items-center gap-2`,
  checks: tw`flex flex-col gap-3 p-2`,
  group: tw`relative flex flex-col gap-3 overflow-hidden rounded-md bg-gray-100 p-3`,
};

export const fieldKinds = {
  column: tw`flex flex-1 flex-col items-stretch gap-2`,
  sub: tw`flex flex-1 flex-col items-stretch gap-1`,
};

export const Field = ({
  kind = "column",
  className,
  ...props
}: { kind?: keyof typeof fieldKinds } & FieldProps) => (
  <HeadlessField className={clsx(fieldKinds[kind], className)} {...props} />
);

export const labelKinds = {
  standard: tw`block self-start text-sm font-medium text-gray-900`,
  error: tw`block text-left text-sm font-normal text-red-700`,
};

export const Label = forwardRef(
  (
    {
      kind = "standard",
      className,
      ...props
    }: { kind?: keyof typeof labelKinds } & LabelProps,
    ref: ForwardedRef<HTMLLabelElement>,
  ) => (
    <HeadlessLabel
      ref={ref}
      className={clsx(labelKinds[kind], className)}
      {...props}
    />
  ),
);
