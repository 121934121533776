import { Row, Table as TanstackTable, flexRender } from "@tanstack/react-table";
import clsx from "clsx";
import { RefObject } from "react";

import { tw } from "@joy/shared-utils";

import { TankFill } from "../panels";
import { EmptyTable, EmptyTableProps } from "./cells";
import { Progress } from "./navigation";

export type GridProps<I> = {
  table: TanstackTable<I>;
  container: {
    ref: RefObject<HTMLDivElement>;
    itemRef: RefObject<HTMLDivElement>;
    className?: string;
  };
  updating?: boolean;
  loading?: boolean;
  empty?: EmptyTableProps;
};

const gridStyles = {
  item: tw`relative flex size-60 flex-col items-center justify-center overflow-hidden rounded-lg bg-white shadow ring-1 ring-black/5`,
  field: tw`relative max-w-full overflow-hidden px-2`,
};

export const GridItem = <I,>({
  row,
  className,
}: {
  row: Row<I>;
  className?: string;
}) => (
  <button
    type="button"
    className={clsx(
      className,
      gridStyles.item,
      row.getCanSelect() ? "cursor-pointer" : "cursor-not-allowed",
    )}
    onClick={row.getToggleSelectedHandler()}
  >
    {row.getVisibleCells().map((cell) => (
      <div
        key={cell.id}
        className={clsx(
          cell.column.columnDef.meta?.className || gridStyles.field,
        )}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </div>
    ))}
  </button>
);

const GridLoading = ({ index }: { index: number }) => (
  <div className={gridStyles.item}>
    <div className={"absolute inset-0 flex animate-pulse flex-col"}>
      <TankFill height={`${(index + 1) * 40}%`} colour="#94a3b8" />
    </div>
  </div>
);

export const Grid = <I,>({
  table,
  container,
  updating,
  loading,
  empty,
}: GridProps<I>) => (
  <div
    ref={container.ref}
    className={clsx(
      "flex min-w-full flex-wrap justify-center gap-3 pb-1",
      container.className,
    )}
  >
    <Progress show={updating} position="top-0" />
    {table.getRowModel().rows.map((row) => (
      <GridItem key={row.id} row={row} />
    ))}
    {loading &&
      Array.from({ length: 2 }, (_, i) => (
        <GridLoading key={`loading-${i}`} index={i} />
      ))}
    {!loading && !!empty && table.getRowCount() === 0 && (
      <div className="flex flex-1 flex-col items-center justify-center px-2 py-8">
        <EmptyTable {...empty} />
      </div>
    )}
    <div ref={container.itemRef} />
  </div>
);
