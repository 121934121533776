import { InputProps, ListboxProps } from "@headlessui/react";
import { Suspense } from "react";

import { tw } from "@joy/shared-utils";

import { LinkButton, LinkButtonProps } from "./button";
import { ListInput, ListInputProps, TextInput, TextInputProps } from "./forms";
import { Table, TableProps } from "./table";

export const pageParts = {
  page: tw`flex h-full min-h-0 flex-col gap-3`,
  actions: tw`container mx-auto flex flex-wrap items-center justify-between gap-2`,
  actionItems: tw`order-3 flex w-full flex-wrap gap-2 md:order-none md:w-auto md:flex-1`,
  contents: tw`container mx-auto min-h-0 flex-1`,
};

const Views = <TData, TView extends string>({
  table,
  value,
  variant,
  views,
}: {
  table: TableProps<TData>;
  value: TView;
  variant?: TableProps<TData>["variant"];
  views?: Record<TView, typeof Table>;
}) => {
  const View = (views && views[value]) || Table;

  return (
    <Suspense>
      <View {...table} variant={variant} />
    </Suspense>
  );
};

export const TablePage = <TData, TView extends string = "table">({
  actions,
  children,
  filter,
  create,
  table,
  view,
  views,
  variant,
}: {
  actions?: React.ReactNode;
  children?: React.ReactNode;
  filter: (TextInputProps & InputProps) | undefined;
  create: LinkButtonProps | undefined;
  table: TableProps<TData>;
  view: ListInputProps<TView> & ListboxProps<any, TView> & { value: TView };
  views?: Record<TView, typeof Table>;
  variant?: TableProps<TData>["variant"];
}) => (
  <div className={pageParts.page}>
    {children}
    {filter || actions || view.options.length > 1 || create ? (
      <div className={pageParts.actions}>
        {filter && <TextInput {...filter} />}
        {actions}
        {view.options.length > 1 && (
          <div>
            <ListInput {...view} />
          </div>
        )}
        {create && <LinkButton {...create} />}
      </div>
    ) : null}
    <div className={pageParts.contents}>
      <Views table={table} views={views} value={view.value} variant={variant} />
    </div>
  </div>
);
