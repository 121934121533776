import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

import { gql } from "./util";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: Date | string; output: Date | string };
  DateTimeISO: { input: Date | string; output: Date | string };
  JSONObject: { input: Record<string, any>; output: Record<string, any> };
};

export type Account = {
  __typename?: "Account";
  config?: Maybe<AccountConfig>;
  createdAt: Scalars["DateTimeISO"]["output"];
  createdBy: User;
  customer?: Maybe<Customer>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  plan?: Maybe<Plan>;
  product: AccountProduct;
  schedules: Array<Schedule>;
  tanks: TankList;
  updatedAt: Scalars["DateTimeISO"]["output"];
  updatedBy: User;
};

export type AccountTanksArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type AccountConfig =
  | ColibriConfig
  | JoyGaugeConfig
  | ProGaugeConfig
  | RmsDeltaConfig
  | SmartFillConfig;

export type AccountInput = {
  config?: InputMaybe<Scalars["JSONObject"]["input"]>;
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  planId?: InputMaybe<Scalars["ID"]["input"]>;
  product?: InputMaybe<AccountProduct>;
};

export type AccountList = {
  __typename?: "AccountList";
  accounts: Array<Account>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export const AccountProduct = {
  Colibri: "Colibri",
  JoyGauge: "JoyGauge",
  ProGauge: "ProGauge",
  RmsDelta: "RMSDelta",
  SmartFill: "SmartFill",
} as const;

export type AccountProduct =
  (typeof AccountProduct)[keyof typeof AccountProduct];
export type Alert = {
  __typename?: "Alert";
  actual: Scalars["Int"]["output"];
  expected: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  scenario: AlertScenario;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type AlertLevel = {
  __typename?: "AlertLevel";
  polarity: AlertLevelPolarity;
  threshold: Scalars["Int"]["output"];
};

export type AlertLevelInput = {
  polarity: AlertLevelPolarity;
  threshold: Scalars["Int"]["input"];
};

export const AlertLevelPolarity = {
  Above: "above",
  Below: "below",
} as const;

export type AlertLevelPolarity =
  (typeof AlertLevelPolarity)[keyof typeof AlertLevelPolarity];
export type AlertList = {
  __typename?: "AlertList";
  alerts: Array<Alert>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type AlertRate = {
  __typename?: "AlertRate";
  period: Scalars["Int"]["output"];
  polarity: AlertRatePolarity;
  threshold: Scalars["Int"]["output"];
};

export type AlertRateInput = {
  period: Scalars["Int"]["input"];
  polarity: AlertRatePolarity;
  threshold: Scalars["Int"]["input"];
};

export const AlertRatePolarity = {
  Falling: "falling",
  Rising: "rising",
} as const;

export type AlertRatePolarity =
  (typeof AlertRatePolarity)[keyof typeof AlertRatePolarity];
export const AlertScenario = {
  LevelAbove: "level_above",
  LevelBelow: "level_below",
  RateFalling: "rate_falling",
  RateRising: "rate_rising",
} as const;

export type AlertScenario = (typeof AlertScenario)[keyof typeof AlertScenario];
export type ApnSetting = {
  __typename?: "ApnSetting";
  authenticated: Scalars["Boolean"]["output"];
  gateway: Scalars["String"]["output"];
  password: Scalars["String"]["output"];
  username: Scalars["String"]["output"];
};

export type BounceEvent = {
  __typename?: "BounceEvent";
  at: Scalars["DateTimeISO"]["output"];
  subType: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type Change = {
  __typename?: "Change";
  action: ChangeAction;
  createdAt: Scalars["DateTimeISO"]["output"];
  createdBy: User;
  current?: Maybe<Scalars["JSONObject"]["output"]>;
  id: Scalars["ID"]["output"];
  previous?: Maybe<Scalars["JSONObject"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  updatedBy: User;
};

export const ChangeAction = {
  Create: "create",
  Delete: "delete",
  Update: "update",
} as const;

export type ChangeAction = (typeof ChangeAction)[keyof typeof ChangeAction];
export const ChangeEntity = {
  Account: "account",
  Customer: "customer",
  Document: "document",
  Gauge: "gauge",
  Plan: "plan",
  Role: "role",
  Tank: "tank",
} as const;

export type ChangeEntity = (typeof ChangeEntity)[keyof typeof ChangeEntity];
export type ChangeList = {
  __typename?: "ChangeList";
  changes: Array<Change>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type CippusConfig = {
  __typename?: "CippusConfig";
  offsetHeight: Scalars["Float"]["output"];
  offsetVolume: Scalars["Float"]["output"];
  specificGravityActual: Scalars["Float"]["output"];
  specificGravityProbe: Scalars["Float"]["output"];
};

export type CippusDetail = {
  __typename?: "CippusDetail";
  connection: Scalars["String"]["output"];
  model?: Maybe<Scalars["String"]["output"]>;
  range: Scalars["String"]["output"];
};

export type ClickEvent = {
  __typename?: "ClickEvent";
  at: Scalars["DateTimeISO"]["output"];
  ipAddress: Scalars["String"]["output"];
  link: Scalars["String"]["output"];
  userAgent: Scalars["String"]["output"];
};

export type ColibriConfig = {
  __typename?: "ColibriConfig";
  url?: Maybe<Scalars["String"]["output"]>;
};

export type Comment = {
  __typename?: "Comment";
  createdAt: Scalars["DateTimeISO"]["output"];
  createdBy: User;
  id: Scalars["ID"]["output"];
  message: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  updatedBy: User;
};

export const CommentEntity = {
  Account: "account",
  Gauge: "gauge",
  Tank: "tank",
} as const;

export type CommentEntity = (typeof CommentEntity)[keyof typeof CommentEntity];
export type CommentInput = {
  entityId?: InputMaybe<Scalars["ID"]["input"]>;
  entityType?: InputMaybe<CommentEntity>;
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type CommentList = {
  __typename?: "CommentList";
  comments: Array<Comment>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type ComplaintEvent = {
  __typename?: "ComplaintEvent";
  at: Scalars["DateTimeISO"]["output"];
  type: Scalars["String"]["output"];
};

export type ControlSetting = {
  __typename?: "ControlSetting";
  bundAlarm: Scalars["Boolean"]["output"];
  crcCheck: Scalars["Boolean"]["output"];
  network?: Maybe<Scalars["String"]["output"]>;
  verboseLogging: Scalars["Boolean"]["output"];
};

export type Customer = {
  __typename?: "Customer";
  accounts: AccountList;
  createdAt: Scalars["DateTimeISO"]["output"];
  createdBy: User;
  id: Scalars["ID"]["output"];
  interval: Scalars["Int"]["output"];
  linkedContact?: Maybe<LinkedContact>;
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  updatedBy: User;
};

export type CustomerAccountsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type CustomerInput = {
  interval?: InputMaybe<Scalars["Int"]["input"]>;
  linkedContact?: InputMaybe<LinkedContactInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type CustomerList = {
  __typename?: "CustomerList";
  customers: Array<Customer>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type DeliveryDelayEvent = {
  __typename?: "DeliveryDelayEvent";
  at: Scalars["DateTimeISO"]["output"];
  type: Scalars["String"]["output"];
};

export type DeliveryEvent = {
  __typename?: "DeliveryEvent";
  at: Scalars["DateTimeISO"]["output"];
  response: Scalars["String"]["output"];
};

export type DeliverySetting = {
  __typename?: "DeliverySetting";
  attempts: Scalars["Int"]["output"];
  period: Scalars["Int"]["output"];
};

export type Document = {
  __typename?: "Document";
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  createdBy: User;
  html?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  updatedBy: User;
};

export type DocumentInput = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type DocumentList = {
  __typename?: "DocumentList";
  documents: Array<Document>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export const DynamicAlarmPolarity = {
  Falling: "falling",
  Rising: "rising",
} as const;

export type DynamicAlarmPolarity =
  (typeof DynamicAlarmPolarity)[keyof typeof DynamicAlarmPolarity];
export type DynamicAlarmSetting = {
  __typename?: "DynamicAlarmSetting";
  enabled: Scalars["Boolean"]["output"];
  polarity?: Maybe<DynamicAlarmPolarity>;
  rate?: Maybe<Scalars["Int"]["output"]>;
};

export type Email = {
  __typename?: "Email";
  createdAt: Scalars["DateTimeISO"]["output"];
  createdBy: User;
  events: Array<EmailEvent>;
  id: Scalars["ID"]["output"];
  status: EmailStatus;
  template: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  updatedBy: User;
  user: User;
};

export type EmailEvent =
  | BounceEvent
  | ClickEvent
  | ComplaintEvent
  | DeliveryDelayEvent
  | DeliveryEvent
  | OpenEvent
  | RejectEvent
  | RenderingFailureEvent
  | SendEvent
  | SpamCheckEvent
  | SubscriptionEvent;

export type EmailList = {
  __typename?: "EmailList";
  emails: Array<Email>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export const EmailStatus = {
  Failed: "failed",
  Queued: "queued",
  Sent: "sent",
} as const;

export type EmailStatus = (typeof EmailStatus)[keyof typeof EmailStatus];
export type FairbanksStockLevel = {
  __typename?: "FairbanksStockLevel";
  height?: Maybe<Scalars["Float"]["output"]>;
  ibank?: Maybe<Scalars["String"]["output"]>;
  tank?: Maybe<Scalars["String"]["output"]>;
  temperature?: Maybe<Scalars["Float"]["output"]>;
  time?: Maybe<Scalars["String"]["output"]>;
  volume?: Maybe<Scalars["Float"]["output"]>;
  water?: Maybe<Scalars["Float"]["output"]>;
};

export type FairbanksTank = {
  __typename?: "FairbanksTank";
  id: Scalars["String"]["output"];
  items: Array<FairbanksStockLevel>;
};

export type FairbanksUserList = {
  __typename?: "FairbanksUserList";
  next?: Maybe<Scalars["String"]["output"]>;
  users: Array<Scalars["String"]["output"]>;
};

export type Fee = {
  __typename?: "Fee";
  account: Account;
  amount: Scalars["Int"]["output"];
  discount?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["ID"]["output"];
  invoiceId: Scalars["ID"]["output"];
  plan: Plan;
  prepaid?: Maybe<Scalars["Int"]["output"]>;
  quantity: Scalars["Int"]["output"];
  tank?: Maybe<Tank>;
};

export type FeeInput = {
  discount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FeeList = {
  __typename?: "FeeList";
  fees: Array<Fee>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type Gauge = {
  __typename?: "Gauge";
  account?: Maybe<Account>;
  config: GaugeConfig;
  createdAt: Scalars["DateTimeISO"]["output"];
  createdBy: User;
  customer?: Maybe<Customer>;
  deactivatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  detail: GaugeDetail;
  id: Scalars["ID"]["output"];
  model?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  observations: ObservationList;
  product: GaugeProduct;
  responses: GaugeResponseList;
  setting?: Maybe<GaugeSetting>;
  strappingTable: Array<StrapRow>;
  tank?: Maybe<Tank>;
  technology: GaugeTechnology;
  updatedAt: Scalars["DateTimeISO"]["output"];
  updatedBy: User;
};

export type GaugeObservationsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type GaugeResponsesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type GaugeConfig =
  | CippusConfig
  | TekAdcConfig
  | TekUltrasonicConfig
  | UnregisteredConfig;

export type GaugeDetail =
  | CippusDetail
  | TekAdcDetail
  | TekUltrasonicDetail
  | UnregisteredDetail;

export type GaugeInput = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  config?: InputMaybe<Scalars["JSONObject"]["input"]>;
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  product?: InputMaybe<GaugeProduct>;
  strappingTable?: InputMaybe<Array<StrapRowInput>>;
  technology?: InputMaybe<GaugeTechnology>;
};

export type GaugeList = {
  __typename?: "GaugeList";
  gauges: Array<Gauge>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export const GaugeProduct = {
  Cippus: "Cippus",
  TekAdc: "TekADC",
  TekUltrasonic: "TekUltrasonic",
  Unregistered: "Unregistered",
} as const;

export type GaugeProduct = (typeof GaugeProduct)[keyof typeof GaugeProduct];
export type GaugeResponse = {
  __typename?: "GaugeResponse";
  id: Scalars["ID"]["output"];
  items: Scalars["String"]["output"];
  product: GaugeResponseProduct;
  settings: GaugeResponseSettings;
  timezone: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type GaugeResponseList = {
  __typename?: "GaugeResponseList";
  next?: Maybe<Scalars["String"]["output"]>;
  responses: Array<GaugeResponse>;
};

export const GaugeResponseProduct = {
  TekAdc: "TekADC",
  TekUltrasonic: "TekUltrasonic",
} as const;

export type GaugeResponseProduct =
  (typeof GaugeResponseProduct)[keyof typeof GaugeResponseProduct];
export type GaugeResponseSettings =
  | TekAdcResponseSettings
  | TekUltrasonicResponseSettings;

export type GaugeSetting = {
  __typename?: "GaugeSetting";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTimeISO"]["output"];
  createdBy: User;
  id: Scalars["ID"]["output"];
  product: GaugeSettingProduct;
  settings: GaugeSettings;
  timezone: Scalars["String"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  updatedBy: User;
};

export type GaugeSettingInput = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  settings?: InputMaybe<Scalars["JSONObject"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export const GaugeSettingProduct = {
  TekAdc: "TekADC",
  TekUltrasonic: "TekUltrasonic",
} as const;

export type GaugeSettingProduct =
  (typeof GaugeSettingProduct)[keyof typeof GaugeSettingProduct];
export type GaugeSettings = TekAdcSettings | TekUltrasonicSettings;

export const GaugeTechnology = {
  Adc: "ADC",
  Pressure: "Pressure",
  Temperature: "Temperature",
  TemperatureAndPressure: "TemperatureAndPressure",
  Ultrasonic: "Ultrasonic",
  Unknown: "Unknown",
} as const;

export type GaugeTechnology =
  (typeof GaugeTechnology)[keyof typeof GaugeTechnology];
export type Invite = {
  __typename?: "Invite";
  createdAt: Scalars["DateTimeISO"]["output"];
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  jlteam?: Maybe<TeamPermission>;
  updatedAt: Scalars["DateTimeISO"]["output"];
};

export type InviteList = {
  __typename?: "InviteList";
  invites: Array<Invite>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type Invoice = {
  __typename?: "Invoice";
  adjustment?: Maybe<Scalars["Int"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  createdBy: User;
  customer: Customer;
  fees: FeeList;
  id: Scalars["ID"]["output"];
  linkedInvoice?: Maybe<LinkedInvoice>;
  month: Scalars["Int"]["output"];
  updatedAt: Scalars["DateTimeISO"]["output"];
  updatedBy: User;
  year: Scalars["Int"]["output"];
};

export type InvoiceFeesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type InvoiceInput = {
  adjustment?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InvoiceList = {
  __typename?: "InvoiceList";
  invoices: Array<Invoice>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type JoyGaugeConfig = {
  __typename?: "JoyGaugeConfig";
  gauges: GaugeList;
};

export type JoyGaugeConfigGaugesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type Level = {
  __typename?: "Level";
  id: Scalars["ID"]["output"];
  maximum: Scalars["Float"]["output"];
  percent: Scalars["Float"]["output"];
  rssi?: Maybe<Scalars["Float"]["output"]>;
  signal?: Maybe<Scalars["Float"]["output"]>;
  src?: Maybe<Scalars["Float"]["output"]>;
  temperature?: Maybe<Scalars["Float"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  value: Scalars["Float"]["output"];
  voltage?: Maybe<Scalars["Float"]["output"]>;
};

export type LevelList = {
  __typename?: "LevelList";
  levels: Array<Level>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type LinkedContact = {
  __typename?: "LinkedContact";
  accountNumber?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type LinkedContactInput = {
  accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type LinkedContactList = {
  __typename?: "LinkedContactList";
  contacts: Array<LinkedContact>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type LinkedInvoice = {
  __typename?: "LinkedInvoice";
  id: Scalars["ID"]["output"];
  link: Scalars["String"]["output"];
  number: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
};

export type Log = {
  __typename?: "Log";
  content?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  ipAddress?: Maybe<Scalars["String"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  parsed?: Maybe<Array<Scalars["JSONObject"]["output"]>>;
  requestId?: Maybe<Scalars["String"]["output"]>;
  timestamp: Scalars["DateTimeISO"]["output"];
};

export type LogGroupList = {
  __typename?: "LogGroupList";
  logGroups: Array<Scalars["String"]["output"]>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type LogList = {
  __typename?: "LogList";
  logs: Array<Log>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type LoggerSetting = {
  __typename?: "LoggerSetting";
  frequency: Scalars["Int"]["output"];
  sampling: Scalars["Int"]["output"];
};

export const MeasurementSetting = {
  Standard: "standard",
  Waveguide: "waveguide",
} as const;

export type MeasurementSetting =
  (typeof MeasurementSetting)[keyof typeof MeasurementSetting];
export type Mutation = {
  __typename?: "Mutation";
  createAccount: Account;
  createComment: Comment;
  createCustomer: Customer;
  createDocument: Document;
  createGauge: Gauge;
  createPlan: Plan;
  createProduct: Product;
  createRole: Role;
  createSchedule: Schedule;
  deleteAccount: Scalars["Boolean"]["output"];
  deleteComment: Scalars["Boolean"]["output"];
  deleteCustomer: Scalars["Boolean"]["output"];
  deleteDocument: Scalars["Boolean"]["output"];
  deleteGauge: Scalars["Boolean"]["output"];
  deleteInvite: Scalars["Boolean"]["output"];
  deleteInvoice: Scalars["Boolean"]["output"];
  deletePlan: Scalars["Boolean"]["output"];
  deleteProduct: Scalars["Boolean"]["output"];
  deleteRole: Scalars["Boolean"]["output"];
  deleteSchedule: Scalars["Boolean"]["output"];
  deleteUser: Scalars["Boolean"]["output"];
  generateInvoices: Scalars["Int"]["output"];
  impersonateUser: Scalars["String"]["output"];
  inviteUser?: Maybe<Invite>;
  regenerateInvoice: Invoice;
  updateAccount: Account;
  updateComment: Comment;
  updateCustomer: Customer;
  updateDocument: Document;
  updateFee: Fee;
  updateGauge: Gauge;
  updateGaugeSetting: GaugeSetting;
  updateInvoice: Invoice;
  updatePlan: Plan;
  updateProduct: Product;
  updateRole: Role;
  updateSchedule: Schedule;
  updateTank: Tank;
  updateUser?: Maybe<User>;
  verifyAccount: Scalars["Boolean"]["output"];
  verifyGauge: Scalars["Boolean"]["output"];
};

export type MutationCreateAccountArgs = {
  fields: AccountInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreateCommentArgs = {
  fields: CommentInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreateCustomerArgs = {
  fields: CustomerInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreateDocumentArgs = {
  fields: DocumentInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreateGaugeArgs = {
  fields: GaugeInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreatePlanArgs = {
  fields: PlanInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreateProductArgs = {
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  fields: ProductInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreateRoleArgs = {
  fields: RoleInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreateScheduleArgs = {
  entityId: Scalars["ID"]["input"];
  entityType: ScheduleEntityType;
  fields: ScheduleInput;
  id: Scalars["ID"]["input"];
};

export type MutationDeleteAccountArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteCommentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteCustomerArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteDocumentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteGaugeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInviteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInvoiceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeletePlanArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteProductArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteRoleArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteScheduleArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationGenerateInvoicesArgs = {
  month: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type MutationImpersonateUserArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationInviteUserArgs = {
  email: Scalars["String"]["input"];
  jlteam?: InputMaybe<TeamPermission>;
};

export type MutationRegenerateInvoiceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationUpdateAccountArgs = {
  fields: AccountInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateCommentArgs = {
  fields: CommentInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateCustomerArgs = {
  fields: CustomerInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateDocumentArgs = {
  fields: DocumentInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateFeeArgs = {
  fields: FeeInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateGaugeArgs = {
  fields: GaugeInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateGaugeSettingArgs = {
  fields: GaugeSettingInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateInvoiceArgs = {
  fields: InvoiceInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdatePlanArgs = {
  fields: PlanInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateProductArgs = {
  fields: ProductInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateRoleArgs = {
  id: Scalars["ID"]["input"];
  permissions: Array<RolePermission>;
};

export type MutationUpdateScheduleArgs = {
  fields: ScheduleInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateTankArgs = {
  fields: TankInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateUserArgs = {
  fields: UserInput;
  id: Scalars["ID"]["input"];
};

export type MutationVerifyAccountArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationVerifyGaugeArgs = {
  id: Scalars["ID"]["input"];
};

export type NetworkSetting = {
  __typename?: "NetworkSetting";
  band: Scalars["String"]["output"];
  operator: Scalars["String"]["output"];
};

export type Observation = {
  __typename?: "Observation";
  id: Scalars["ID"]["output"];
  reading: Scalars["Float"]["output"];
  rssi?: Maybe<Scalars["Float"]["output"]>;
  signal?: Maybe<Scalars["Float"]["output"]>;
  sleep?: Maybe<Scalars["Float"]["output"]>;
  src?: Maybe<Scalars["Float"]["output"]>;
  temperature?: Maybe<Scalars["Float"]["output"]>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  voltage?: Maybe<Scalars["Float"]["output"]>;
};

export type ObservationList = {
  __typename?: "ObservationList";
  next?: Maybe<Scalars["String"]["output"]>;
  observations: Array<Observation>;
};

export type OpenEvent = {
  __typename?: "OpenEvent";
  at: Scalars["DateTimeISO"]["output"];
  ipAddress: Scalars["String"]["output"];
  userAgent: Scalars["String"]["output"];
};

export type Plan = {
  __typename?: "Plan";
  accounts: AccountList;
  amount: Scalars["Int"]["output"];
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  createdBy: User;
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  model: PlanModel;
  prepaid?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: User;
};

export type PlanAccountsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type PlanInput = {
  amount?: InputMaybe<Scalars["Int"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<PlanModel>;
  prepaid?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PlanList = {
  __typename?: "PlanList";
  next?: Maybe<Scalars["String"]["output"]>;
  plans: Array<Plan>;
};

export const PlanModel = {
  Account: "account",
  Tank: "tank",
} as const;

export type PlanModel = (typeof PlanModel)[keyof typeof PlanModel];
export type ProGaugeConfig = {
  __typename?: "ProGaugeConfig";
  maximum?: Maybe<Scalars["JSONObject"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type Product = {
  __typename?: "Product";
  colour: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  createdBy: User;
  customerId: Scalars["ID"]["output"];
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: User;
};

export type ProductInput = {
  colour?: InputMaybe<Scalars["String"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProductList = {
  __typename?: "ProductList";
  next?: Maybe<Scalars["String"]["output"]>;
  products: Array<Product>;
};

export type QualitySetting = {
  __typename?: "QualitySetting";
  rssi: Scalars["Int"]["output"];
  src: Scalars["Int"]["output"];
};

export type Query = {
  __typename?: "Query";
  account?: Maybe<Account>;
  accountAccess: Array<RolePermission>;
  accounts: AccountList;
  changes: ChangeList;
  comments: CommentList;
  customer?: Maybe<Customer>;
  customers: CustomerList;
  document?: Maybe<Document>;
  documents: DocumentList;
  emails: EmailList;
  fairbanksTanks: Array<FairbanksTank>;
  fairbanksUsers: FairbanksUserList;
  gauge?: Maybe<Gauge>;
  gaugeAccess: Array<RolePermission>;
  gauges: GaugeList;
  invites: InviteList;
  invoice?: Maybe<Invoice>;
  invoices: InvoiceList;
  linkedContacts: LinkedContactList;
  logGroups: LogGroupList;
  logs: LogList;
  parse: Scalars["JSONObject"]["output"];
  plan?: Maybe<Plan>;
  plans: PlanList;
  product?: Maybe<Product>;
  products: ProductList;
  role?: Maybe<Role>;
  roles: RoleList;
  schedule?: Maybe<Schedule>;
  tank?: Maybe<Tank>;
  tankAccess: Array<RolePermission>;
  tankRoles: RoleList;
  tanks: TankList;
  user: User;
  userRoles: RoleList;
  users: UserList;
};

export type QueryAccountArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAccountAccessArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAccountsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryChangesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  entityId: Scalars["ID"]["input"];
  entityType: ChangeEntity;
  limit: Scalars["Int"]["input"];
};

export type QueryCommentsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  entityId: Scalars["ID"]["input"];
  entityType: CommentEntity;
  limit: Scalars["Int"]["input"];
};

export type QueryCustomerArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryCustomersArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryDocumentArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryDocumentsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryEmailsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  userId: Scalars["ID"]["input"];
};

export type QueryFairbanksTanksArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryFairbanksUsersArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryGaugeArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGaugeAccessArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGaugesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryInvitesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryInvoiceArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryInvoicesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  month: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
};

export type QueryLinkedContactsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  search: Scalars["String"]["input"];
};

export type QueryLogGroupsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryLogsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<Scalars["String"]["input"]>;
  from?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
  group: Scalars["String"]["input"];
  limit: Scalars["Int"]["input"];
};

export type QueryParseArgs = {
  data: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
};

export type QueryPlanArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryPlansArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryProductArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryProductsArgs = {
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryRoleArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryRolesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  entityId: Scalars["ID"]["input"];
  entityType: RoleEntityType;
  limit: Scalars["Int"]["input"];
};

export type QueryScheduleArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTankArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTankAccessArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTankRolesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  tankId: Scalars["ID"]["input"];
};

export type QueryTanksArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryUserArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryUserRolesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  userId: Scalars["ID"]["input"];
};

export type QueryUsersArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type RmsDeltaConfig = {
  __typename?: "RMSDeltaConfig";
  serialNumbers?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type Range = {
  __typename?: "Range";
  max: Scalars["Float"]["output"];
  min: Scalars["Float"]["output"];
};

export type RejectEvent = {
  __typename?: "RejectEvent";
  at: Scalars["DateTimeISO"]["output"];
  reason: Scalars["String"]["output"];
};

export type RenderingFailureEvent = {
  __typename?: "RenderingFailureEvent";
  at: Scalars["DateTimeISO"]["output"];
  error: Scalars["String"]["output"];
};

export type Role = {
  __typename?: "Role";
  createdAt: Scalars["DateTimeISO"]["output"];
  createdBy: User;
  entity: RoleEntity;
  entityId: Scalars["ID"]["output"];
  entityType: RoleEntityType;
  id: Scalars["ID"]["output"];
  permissions: Array<RolePermission>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  updatedBy: User;
  user: User;
};

export type RoleEntity = Account | Customer | Tank;

export const RoleEntityType = {
  Account: "account",
  Customer: "customer",
  Tank: "tank",
} as const;

export type RoleEntityType =
  (typeof RoleEntityType)[keyof typeof RoleEntityType];
export type RoleInput = {
  entityId: Scalars["ID"]["input"];
  entityType: RoleEntityType;
  permissions: Array<RolePermission>;
  userEmail: Scalars["String"]["input"];
};

export type RoleList = {
  __typename?: "RoleList";
  next?: Maybe<Scalars["String"]["output"]>;
  roles: Array<Role>;
};

export const RolePermission = {
  Install: "install",
  Manage: "manage",
  Read: "read",
  Write: "write",
} as const;

export type RolePermission =
  (typeof RolePermission)[keyof typeof RolePermission];
export type Schedule = {
  __typename?: "Schedule";
  date?: Maybe<Scalars["Int"]["output"]>;
  days?: Maybe<ScheduleDays>;
  entity: ScheduleEntity;
  entityId: Scalars["ID"]["output"];
  entityType: ScheduleEntityType;
  id: Scalars["ID"]["output"];
  interval: ScheduleInterval;
  startTime: ScheduleStartTime;
  startTimeUTC: Scalars["String"]["output"];
  timezone: Scalars["String"]["output"];
  users: Array<User>;
};

export type ScheduleDays = {
  __typename?: "ScheduleDays";
  Friday: Scalars["Boolean"]["output"];
  Monday: Scalars["Boolean"]["output"];
  Saturday: Scalars["Boolean"]["output"];
  Sunday: Scalars["Boolean"]["output"];
  Thursday: Scalars["Boolean"]["output"];
  Tuesday: Scalars["Boolean"]["output"];
  Wednesday: Scalars["Boolean"]["output"];
};

export type ScheduleDaysInput = {
  Friday: Scalars["Boolean"]["input"];
  Monday: Scalars["Boolean"]["input"];
  Saturday: Scalars["Boolean"]["input"];
  Sunday: Scalars["Boolean"]["input"];
  Thursday: Scalars["Boolean"]["input"];
  Tuesday: Scalars["Boolean"]["input"];
  Wednesday: Scalars["Boolean"]["input"];
};

export type ScheduleEntity = Account;

export const ScheduleEntityType = {
  Account: "account",
} as const;

export type ScheduleEntityType =
  (typeof ScheduleEntityType)[keyof typeof ScheduleEntityType];
export const ScheduleEvery = {
  Fortnight: "Fortnight",
  Month: "Month",
  Week: "Week",
} as const;

export type ScheduleEvery = (typeof ScheduleEvery)[keyof typeof ScheduleEvery];
export type ScheduleInput = {
  date?: InputMaybe<Scalars["Int"]["input"]>;
  days?: InputMaybe<ScheduleDaysInput>;
  interval?: InputMaybe<ScheduleInterval>;
  startTime?: InputMaybe<ScheduleStartTimeInput>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export const ScheduleInterval = {
  Month: "Month",
  Week: "Week",
} as const;

export type ScheduleInterval =
  (typeof ScheduleInterval)[keyof typeof ScheduleInterval];
export type ScheduleSetting = {
  __typename?: "ScheduleSetting";
  days: ScheduleDays;
  endTime?: Maybe<Scalars["Int"]["output"]>;
  every: ScheduleEvery;
  frequency: Scalars["Int"]["output"];
  startTime: ScheduleStartTime;
};

export type ScheduleStartTime = {
  __typename?: "ScheduleStartTime";
  hour: Scalars["Int"]["output"];
  minute: Scalars["Int"]["output"];
};

export type ScheduleStartTimeInput = {
  hour: Scalars["Int"]["input"];
  minute: Scalars["Int"]["input"];
};

export type SendEvent = {
  __typename?: "SendEvent";
  at: Scalars["DateTimeISO"]["output"];
};

export type ServerSetting = {
  __typename?: "ServerSetting";
  host: Scalars["String"]["output"];
  port: Scalars["Int"]["output"];
};

export type SmartFillConfig = {
  __typename?: "SmartFillConfig";
  reference?: Maybe<Scalars["String"]["output"]>;
  secret?: Maybe<Scalars["String"]["output"]>;
};

export type SpamCheckEvent = {
  __typename?: "SpamCheckEvent";
  at: Scalars["DateTimeISO"]["output"];
  ipAddress: Scalars["String"]["output"];
  userAgent: Scalars["String"]["output"];
};

export const StaticAlarmPolarity = {
  Higher: "higher",
  Lower: "lower",
} as const;

export type StaticAlarmPolarity =
  (typeof StaticAlarmPolarity)[keyof typeof StaticAlarmPolarity];
export type StaticAlarmSetting = {
  __typename?: "StaticAlarmSetting";
  enabled: Scalars["Boolean"]["output"];
  error?: Maybe<Scalars["Int"]["output"]>;
  polarity?: Maybe<StaticAlarmPolarity>;
  threshold?: Maybe<Scalars["Int"]["output"]>;
};

export type StrapRow = {
  __typename?: "StrapRow";
  l: Scalars["Float"]["output"];
  mm: Scalars["Float"]["output"];
};

export type StrapRowInput = {
  l: Scalars["Float"]["input"];
  mm: Scalars["Float"]["input"];
};

export type SubscriptionEvent = {
  __typename?: "SubscriptionEvent";
  at: Scalars["DateTimeISO"]["output"];
  source: Scalars["String"]["output"];
};

export type Tank = {
  __typename?: "Tank";
  account?: Maybe<Account>;
  accountProduct: AccountProduct;
  activatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  alertLevels?: Maybe<Array<AlertLevel>>;
  alertRates?: Maybe<Array<AlertRate>>;
  alertUsers?: Maybe<Array<User>>;
  alerts: AlertList;
  createdAt: Scalars["DateTimeISO"]["output"];
  createdBy: User;
  customerId: Scalars["ID"]["output"];
  gauge?: Maybe<Gauge>;
  id: Scalars["ID"]["output"];
  levels: LevelList;
  location?: Maybe<TankLocation>;
  name: Scalars["String"]["output"];
  tankProduct?: Maybe<Product>;
  unit?: Maybe<TankUnit>;
  updatedAt: Scalars["DateTimeISO"]["output"];
  updatedBy: User;
};

export type TankAlertsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type TankLevelsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TankInput = {
  activatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  alertLevels?: InputMaybe<Array<AlertLevelInput>>;
  alertRates?: InputMaybe<Array<AlertRateInput>>;
  alertUsers?: InputMaybe<Array<Scalars["String"]["input"]>>;
  location?: InputMaybe<TankLocationInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  productId?: InputMaybe<Scalars["ID"]["input"]>;
  unit?: InputMaybe<TankUnit>;
};

export type TankList = {
  __typename?: "TankList";
  next?: Maybe<Scalars["String"]["output"]>;
  tanks: Array<Tank>;
};

export type TankLocation = {
  __typename?: "TankLocation";
  display: Scalars["String"]["output"];
  latitude: Scalars["Float"]["output"];
  longitude: Scalars["Float"]["output"];
};

export type TankLocationInput = {
  display: Scalars["String"]["input"];
  latitude: Scalars["Float"]["input"];
  longitude: Scalars["Float"]["input"];
};

export const TankUnit = {
  Gal: "gal",
  L: "l",
} as const;

export type TankUnit = (typeof TankUnit)[keyof typeof TankUnit];
export const TeamPermission = {
  Admin: "admin",
  God: "god",
  Super: "super",
} as const;

export type TeamPermission =
  (typeof TeamPermission)[keyof typeof TeamPermission];
export type TekAdcConfig = {
  __typename?: "TekADCConfig";
  offsetHeight: Scalars["Float"]["output"];
  offsetVolume: Scalars["Float"]["output"];
  rangeAnalog: Range;
  rangeDigital: Range;
  rangeHeight: Range;
  rangeProbe: Range;
  specificGravityActual: Scalars["Float"]["output"];
  specificGravityProbe: Scalars["Float"]["output"];
};

export type TekAdcDetail = {
  __typename?: "TekADCDetail";
  firmware: Version;
  hardware: Version;
  model?: Maybe<Scalars["String"]["output"]>;
};

export type TekAdcResponseSettings = {
  __typename?: "TekADCResponseSettings";
  apn?: Maybe<ApnSetting>;
  battery?: Maybe<Scalars["Int"]["output"]>;
  control?: Maybe<ControlSetting>;
  delay?: Maybe<Scalars["Int"]["output"]>;
  delivery?: Maybe<DeliverySetting>;
  dynamicAlarms?: Maybe<Array<DynamicAlarmSetting>>;
  eStop?: Maybe<Scalars["Int"]["output"]>;
  fStop?: Maybe<Scalars["Int"]["output"]>;
  fallbackPhone?: Maybe<Scalars["String"]["output"]>;
  listen?: Maybe<Scalars["Int"]["output"]>;
  logger?: Maybe<LoggerSetting>;
  network?: Maybe<NetworkSetting>;
  password?: Maybe<Scalars["String"]["output"]>;
  schedule?: Maybe<ScheduleSetting>;
  server?: Maybe<ServerSetting>;
  staticAlarms?: Maybe<Array<StaticAlarmSetting>>;
};

export type TekAdcSettings = {
  __typename?: "TekADCSettings";
  dynamicAlarms?: Maybe<Array<DynamicAlarmSetting>>;
  password?: Maybe<Scalars["String"]["output"]>;
  schedule?: Maybe<ScheduleSetting>;
  staticAlarms?: Maybe<Array<StaticAlarmSetting>>;
};

export type TekUltrasonicConfig = {
  __typename?: "TekUltrasonicConfig";
  mountToBottom: Scalars["Float"]["output"];
  offsetVolume: Scalars["Float"]["output"];
};

export type TekUltrasonicDetail = {
  __typename?: "TekUltrasonicDetail";
  firmware: Version;
  hardware: Version;
  model?: Maybe<Scalars["String"]["output"]>;
};

export type TekUltrasonicResponseSettings = {
  __typename?: "TekUltrasonicResponseSettings";
  apn?: Maybe<ApnSetting>;
  control?: Maybe<ControlSetting>;
  delay?: Maybe<Scalars["Int"]["output"]>;
  delivery?: Maybe<DeliverySetting>;
  dynamicAlarms?: Maybe<Array<DynamicAlarmSetting>>;
  fallbackPhone?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<Scalars["Int"]["output"]>;
  listen?: Maybe<Scalars["Int"]["output"]>;
  logger?: Maybe<LoggerSetting>;
  measurement?: Maybe<MeasurementSetting>;
  network?: Maybe<NetworkSetting>;
  password?: Maybe<Scalars["String"]["output"]>;
  quality?: Maybe<QualitySetting>;
  schedule?: Maybe<ScheduleSetting>;
  server?: Maybe<ServerSetting>;
  staticAlarms?: Maybe<Array<StaticAlarmSetting>>;
};

export type TekUltrasonicSettings = {
  __typename?: "TekUltrasonicSettings";
  dynamicAlarms?: Maybe<Array<DynamicAlarmSetting>>;
  height?: Maybe<Scalars["Int"]["output"]>;
  measurement?: Maybe<MeasurementSetting>;
  password?: Maybe<Scalars["String"]["output"]>;
  quality?: Maybe<QualitySetting>;
  schedule?: Maybe<ScheduleSetting>;
  staticAlarms?: Maybe<Array<StaticAlarmSetting>>;
};

export type UnregisteredConfig = {
  __typename?: "UnregisteredConfig";
  config?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UnregisteredDetail = {
  __typename?: "UnregisteredDetail";
  detail?: Maybe<Scalars["Boolean"]["output"]>;
  model?: Maybe<Scalars["String"]["output"]>;
};

export type User = {
  __typename?: "User";
  company?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTimeISO"]["output"];
  email: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  identities?: Maybe<Array<Scalars["String"]["output"]>>;
  jlteam?: Maybe<TeamPermission>;
  lastActiveAt?: Maybe<Scalars["DateTimeISO"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  picture?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type UserInput = {
  company?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  jlteam?: InputMaybe<TeamPermission>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserList = {
  __typename?: "UserList";
  next?: Maybe<Scalars["String"]["output"]>;
  users: Array<User>;
};

export type Version = {
  __typename?: "Version";
  major: Scalars["Int"]["output"];
  minor: Scalars["Int"]["output"];
};

export type CreateAccountMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: AccountInput;
}>;

export type CreateAccountMutation = {
  __typename?: "Mutation";
  createAccount: {
    __typename?: "Account";
    id: string;
    name: string;
    product: AccountProduct;
    createdAt: Date | string;
    updatedAt: Date | string;
    schedules: Array<{
      __typename?: "Schedule";
      id: string;
      startTimeUTC: string;
      timezone: string;
      interval: ScheduleInterval;
      date?: number | null;
      entityId: string;
      entityType: ScheduleEntityType;
      startTime: {
        __typename?: "ScheduleStartTime";
        hour: number;
        minute: number;
      };
      days?: {
        __typename?: "ScheduleDays";
        Monday: boolean;
        Tuesday: boolean;
        Wednesday: boolean;
        Thursday: boolean;
        Friday: boolean;
        Saturday: boolean;
        Sunday: boolean;
      } | null;
      users: Array<{
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      }>;
    }>;
    customer?: {
      __typename?: "Customer";
      id: string;
      name: string;
      interval: number;
      createdAt: Date | string;
      updatedAt: Date | string;
      linkedContact?: {
        __typename?: "LinkedContact";
        id: string;
        name: string;
        accountNumber?: string | null;
      } | null;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    plan?: {
      __typename?: "Plan";
      id: string;
      code: string;
      description?: string | null;
      amount: number;
      prepaid?: number | null;
      model: PlanModel;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    config?:
      | { __typename: "ColibriConfig"; url?: string | null }
      | {
          __typename: "JoyGaugeConfig";
          gauges: {
            __typename?: "GaugeList";
            gauges: Array<{
              __typename?: "Gauge";
              id: string;
              name: string;
              technology: GaugeTechnology;
              model?: string | null;
            }>;
          };
        }
      | {
          __typename: "ProGaugeConfig";
          userId?: string | null;
          timezone?: string | null;
          maximum?: Record<string, any> | null;
        }
      | { __typename: "RMSDeltaConfig"; serialNumbers?: Array<string> | null }
      | {
          __typename: "SmartFillConfig";
          reference?: string | null;
          secret?: string | null;
        }
      | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type DeleteAccountMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteAccountMutation = {
  __typename?: "Mutation";
  deleteAccount: boolean;
};

export type AccountFragmentFragment = {
  __typename?: "Account";
  id: string;
  name: string;
  product: AccountProduct;
  createdAt: Date | string;
  updatedAt: Date | string;
  config?:
    | { __typename: "ColibriConfig"; url?: string | null }
    | {
        __typename: "JoyGaugeConfig";
        gauges: {
          __typename?: "GaugeList";
          gauges: Array<{
            __typename?: "Gauge";
            id: string;
            name: string;
            technology: GaugeTechnology;
            model?: string | null;
          }>;
        };
      }
    | {
        __typename: "ProGaugeConfig";
        userId?: string | null;
        timezone?: string | null;
        maximum?: Record<string, any> | null;
      }
    | { __typename: "RMSDeltaConfig"; serialNumbers?: Array<string> | null }
    | {
        __typename: "SmartFillConfig";
        reference?: string | null;
        secret?: string | null;
      }
    | null;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type AccountQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type AccountQuery = {
  __typename?: "Query";
  accountAccess: Array<RolePermission>;
  account?: {
    __typename?: "Account";
    id: string;
    name: string;
    product: AccountProduct;
    createdAt: Date | string;
    updatedAt: Date | string;
    schedules: Array<{
      __typename?: "Schedule";
      id: string;
      startTimeUTC: string;
      timezone: string;
      interval: ScheduleInterval;
      date?: number | null;
      entityId: string;
      entityType: ScheduleEntityType;
      startTime: {
        __typename?: "ScheduleStartTime";
        hour: number;
        minute: number;
      };
      days?: {
        __typename?: "ScheduleDays";
        Monday: boolean;
        Tuesday: boolean;
        Wednesday: boolean;
        Thursday: boolean;
        Friday: boolean;
        Saturday: boolean;
        Sunday: boolean;
      } | null;
      users: Array<{
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      }>;
    }>;
    customer?: {
      __typename?: "Customer";
      id: string;
      name: string;
      interval: number;
      createdAt: Date | string;
      updatedAt: Date | string;
      linkedContact?: {
        __typename?: "LinkedContact";
        id: string;
        name: string;
        accountNumber?: string | null;
      } | null;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    plan?: {
      __typename?: "Plan";
      id: string;
      code: string;
      description?: string | null;
      amount: number;
      prepaid?: number | null;
      model: PlanModel;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    config?:
      | { __typename: "ColibriConfig"; url?: string | null }
      | {
          __typename: "JoyGaugeConfig";
          gauges: {
            __typename?: "GaugeList";
            gauges: Array<{
              __typename?: "Gauge";
              id: string;
              name: string;
              technology: GaugeTechnology;
              model?: string | null;
            }>;
          };
        }
      | {
          __typename: "ProGaugeConfig";
          userId?: string | null;
          timezone?: string | null;
          maximum?: Record<string, any> | null;
        }
      | { __typename: "RMSDeltaConfig"; serialNumbers?: Array<string> | null }
      | {
          __typename: "SmartFillConfig";
          reference?: string | null;
          secret?: string | null;
        }
      | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type AccountsQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AccountsQuery = {
  __typename?: "Query";
  accounts: {
    __typename?: "AccountList";
    next?: string | null;
    accounts: Array<{
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
      createdAt: Date | string;
      updatedAt: Date | string;
      customer?: {
        __typename?: "Customer";
        id: string;
        name: string;
        interval: number;
        createdAt: Date | string;
        updatedAt: Date | string;
        linkedContact?: {
          __typename?: "LinkedContact";
          id: string;
          name: string;
          accountNumber?: string | null;
        } | null;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      } | null;
      plan?: {
        __typename?: "Plan";
        id: string;
        code: string;
        description?: string | null;
        amount: number;
        prepaid?: number | null;
        model: PlanModel;
        createdAt: Date | string;
        updatedAt: Date | string;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      } | null;
      config?:
        | { __typename: "ColibriConfig"; url?: string | null }
        | {
            __typename: "JoyGaugeConfig";
            gauges: {
              __typename?: "GaugeList";
              gauges: Array<{
                __typename?: "Gauge";
                id: string;
                name: string;
                technology: GaugeTechnology;
                model?: string | null;
              }>;
            };
          }
        | {
            __typename: "ProGaugeConfig";
            userId?: string | null;
            timezone?: string | null;
            maximum?: Record<string, any> | null;
          }
        | { __typename: "RMSDeltaConfig"; serialNumbers?: Array<string> | null }
        | {
            __typename: "SmartFillConfig";
            reference?: string | null;
            secret?: string | null;
          }
        | null;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type AccountTanksQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AccountTanksQuery = {
  __typename?: "Query";
  account?: {
    __typename?: "Account";
    tanks: {
      __typename?: "TankList";
      next?: string | null;
      tanks: Array<{
        __typename?: "Tank";
        id: string;
        name: string;
        unit?: TankUnit | null;
        accountProduct: AccountProduct;
        activatedAt?: Date | string | null;
        customerId: string;
        createdAt: Date | string;
        updatedAt: Date | string;
        levels: {
          __typename?: "LevelList";
          levels: Array<{
            __typename?: "Level";
            id: string;
            value: number;
            maximum: number;
            percent: number;
            signal?: number | null;
            temperature?: number | null;
            voltage?: number | null;
            rssi?: number | null;
            src?: number | null;
            updatedAt: Date | string;
          }>;
        };
        tankProduct?: {
          __typename?: "Product";
          id: string;
          label: string;
          colour: string;
        } | null;
        location?: {
          __typename?: "TankLocation";
          display: string;
          latitude: number;
          longitude: number;
        } | null;
        alertLevels?: Array<{
          __typename?: "AlertLevel";
          threshold: number;
          polarity: AlertLevelPolarity;
        }> | null;
        alertRates?: Array<{
          __typename?: "AlertRate";
          threshold: number;
          polarity: AlertRatePolarity;
          period: number;
        }> | null;
        alertUsers?: Array<{
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        }> | null;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      }>;
    };
  } | null;
};

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: AccountInput;
}>;

export type UpdateAccountMutation = {
  __typename?: "Mutation";
  updateAccount: {
    __typename?: "Account";
    id: string;
    name: string;
    product: AccountProduct;
    createdAt: Date | string;
    updatedAt: Date | string;
    schedules: Array<{
      __typename?: "Schedule";
      id: string;
      startTimeUTC: string;
      timezone: string;
      interval: ScheduleInterval;
      date?: number | null;
      entityId: string;
      entityType: ScheduleEntityType;
      startTime: {
        __typename?: "ScheduleStartTime";
        hour: number;
        minute: number;
      };
      days?: {
        __typename?: "ScheduleDays";
        Monday: boolean;
        Tuesday: boolean;
        Wednesday: boolean;
        Thursday: boolean;
        Friday: boolean;
        Saturday: boolean;
        Sunday: boolean;
      } | null;
      users: Array<{
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      }>;
    }>;
    customer?: {
      __typename?: "Customer";
      id: string;
      name: string;
      interval: number;
      createdAt: Date | string;
      updatedAt: Date | string;
      linkedContact?: {
        __typename?: "LinkedContact";
        id: string;
        name: string;
        accountNumber?: string | null;
      } | null;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    plan?: {
      __typename?: "Plan";
      id: string;
      code: string;
      description?: string | null;
      amount: number;
      prepaid?: number | null;
      model: PlanModel;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    config?:
      | { __typename: "ColibriConfig"; url?: string | null }
      | {
          __typename: "JoyGaugeConfig";
          gauges: {
            __typename?: "GaugeList";
            gauges: Array<{
              __typename?: "Gauge";
              id: string;
              name: string;
              technology: GaugeTechnology;
              model?: string | null;
            }>;
          };
        }
      | {
          __typename: "ProGaugeConfig";
          userId?: string | null;
          timezone?: string | null;
          maximum?: Record<string, any> | null;
        }
      | { __typename: "RMSDeltaConfig"; serialNumbers?: Array<string> | null }
      | {
          __typename: "SmartFillConfig";
          reference?: string | null;
          secret?: string | null;
        }
      | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type VerifyAccountMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type VerifyAccountMutation = {
  __typename?: "Mutation";
  verifyAccount: boolean;
};

export type ChangesQueryVariables = Exact<{
  entityType: ChangeEntity;
  entityId: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ChangesQuery = {
  __typename?: "Query";
  changes: {
    __typename?: "ChangeList";
    next?: string | null;
    changes: Array<{
      __typename?: "Change";
      id: string;
      action: ChangeAction;
      previous?: Record<string, any> | null;
      current?: Record<string, any> | null;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type CreateCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: CommentInput;
}>;

export type CreateCommentMutation = {
  __typename?: "Mutation";
  createComment: {
    __typename?: "Comment";
    id: string;
    message: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    createdBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteCommentMutation = {
  __typename?: "Mutation";
  deleteComment: boolean;
};

export type CommentFragmentFragment = {
  __typename?: "Comment";
  id: string;
  message: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  createdBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type CommentsQueryVariables = Exact<{
  entityId: Scalars["ID"]["input"];
  entityType: CommentEntity;
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CommentsQuery = {
  __typename?: "Query";
  comments: {
    __typename?: "CommentList";
    next?: string | null;
    comments: Array<{
      __typename?: "Comment";
      id: string;
      message: string;
      createdAt: Date | string;
      updatedAt: Date | string;
      createdBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type UpdateCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: CommentInput;
}>;

export type UpdateCommentMutation = {
  __typename?: "Mutation";
  updateComment: {
    __typename?: "Comment";
    id: string;
    message: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    createdBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type CustomerAccountsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CustomerAccountsQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    accounts: {
      __typename?: "AccountList";
      next?: string | null;
      accounts: Array<{
        __typename?: "Account";
        id: string;
        name: string;
        product: AccountProduct;
        createdAt: Date | string;
        updatedAt: Date | string;
        customer?: {
          __typename?: "Customer";
          id: string;
          name: string;
          interval: number;
          createdAt: Date | string;
          updatedAt: Date | string;
          linkedContact?: {
            __typename?: "LinkedContact";
            id: string;
            name: string;
            accountNumber?: string | null;
          } | null;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        } | null;
        plan?: {
          __typename?: "Plan";
          id: string;
          code: string;
          description?: string | null;
          amount: number;
          prepaid?: number | null;
          model: PlanModel;
          createdAt: Date | string;
          updatedAt: Date | string;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        } | null;
        config?:
          | { __typename: "ColibriConfig"; url?: string | null }
          | {
              __typename: "JoyGaugeConfig";
              gauges: {
                __typename?: "GaugeList";
                gauges: Array<{
                  __typename?: "Gauge";
                  id: string;
                  name: string;
                  technology: GaugeTechnology;
                  model?: string | null;
                }>;
              };
            }
          | {
              __typename: "ProGaugeConfig";
              userId?: string | null;
              timezone?: string | null;
              maximum?: Record<string, any> | null;
            }
          | {
              __typename: "RMSDeltaConfig";
              serialNumbers?: Array<string> | null;
            }
          | {
              __typename: "SmartFillConfig";
              reference?: string | null;
              secret?: string | null;
            }
          | null;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      }>;
    };
  } | null;
};

export type LinkedContactsQueryVariables = Exact<{
  search: Scalars["String"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LinkedContactsQuery = {
  __typename?: "Query";
  linkedContacts: {
    __typename?: "LinkedContactList";
    next?: string | null;
    contacts: Array<{
      __typename?: "LinkedContact";
      id: string;
      name: string;
      accountNumber?: string | null;
    }>;
  };
};

export type CreateCustomerMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: CustomerInput;
}>;

export type CreateCustomerMutation = {
  __typename?: "Mutation";
  createCustomer: {
    __typename?: "Customer";
    id: string;
    name: string;
    interval: number;
    createdAt: Date | string;
    updatedAt: Date | string;
    linkedContact?: {
      __typename?: "LinkedContact";
      id: string;
      name: string;
      accountNumber?: string | null;
    } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type DeleteCustomerMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteCustomerMutation = {
  __typename?: "Mutation";
  deleteCustomer: boolean;
};

export type LinkedContactFragmentFragment = {
  __typename?: "LinkedContact";
  id: string;
  name: string;
  accountNumber?: string | null;
};

export type CustomerFragmentFragment = {
  __typename?: "Customer";
  id: string;
  name: string;
  interval: number;
  createdAt: Date | string;
  updatedAt: Date | string;
  linkedContact?: {
    __typename?: "LinkedContact";
    id: string;
    name: string;
    accountNumber?: string | null;
  } | null;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type CustomerQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type CustomerQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: string;
    name: string;
    interval: number;
    createdAt: Date | string;
    updatedAt: Date | string;
    linkedContact?: {
      __typename?: "LinkedContact";
      id: string;
      name: string;
      accountNumber?: string | null;
    } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type CustomersQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CustomersQuery = {
  __typename?: "Query";
  customers: {
    __typename?: "CustomerList";
    next?: string | null;
    customers: Array<{
      __typename?: "Customer";
      id: string;
      name: string;
      interval: number;
      createdAt: Date | string;
      updatedAt: Date | string;
      linkedContact?: {
        __typename?: "LinkedContact";
        id: string;
        name: string;
        accountNumber?: string | null;
      } | null;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: CustomerInput;
}>;

export type UpdateCustomerMutation = {
  __typename?: "Mutation";
  updateCustomer: {
    __typename?: "Customer";
    id: string;
    name: string;
    interval: number;
    createdAt: Date | string;
    updatedAt: Date | string;
    linkedContact?: {
      __typename?: "LinkedContact";
      id: string;
      name: string;
      accountNumber?: string | null;
    } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type CreateDocumentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: DocumentInput;
}>;

export type CreateDocumentMutation = {
  __typename?: "Mutation";
  createDocument: {
    __typename?: "Document";
    id: string;
    title: string;
    content?: string | null;
    html?: string | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteDocumentMutation = {
  __typename?: "Mutation";
  deleteDocument: boolean;
};

export type DocumentFragmentFragment = {
  __typename?: "Document";
  id: string;
  title: string;
  content?: string | null;
  html?: string | null;
  createdAt: Date | string;
  updatedAt: Date | string;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type DocumentQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DocumentQuery = {
  __typename?: "Query";
  document?: {
    __typename?: "Document";
    id: string;
    title: string;
    content?: string | null;
    html?: string | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type DocumentsQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type DocumentsQuery = {
  __typename?: "Query";
  documents: {
    __typename?: "DocumentList";
    next?: string | null;
    documents: Array<{
      __typename?: "Document";
      id: string;
      title: string;
      content?: string | null;
      html?: string | null;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type UpdateDocumentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: DocumentInput;
}>;

export type UpdateDocumentMutation = {
  __typename?: "Mutation";
  updateDocument: {
    __typename?: "Document";
    id: string;
    title: string;
    content?: string | null;
    html?: string | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type EmailFragmentFragment = {
  __typename?: "Email";
  id: string;
  template: string;
  status: EmailStatus;
  createdAt: Date | string;
  updatedAt: Date | string;
  events: Array<
    | {
        __typename: "BounceEvent";
        type: string;
        subType: string;
        at: Date | string;
      }
    | {
        __typename: "ClickEvent";
        userAgent: string;
        ipAddress: string;
        link: string;
        at: Date | string;
      }
    | { __typename: "ComplaintEvent"; type: string; at: Date | string }
    | { __typename: "DeliveryDelayEvent"; type: string; at: Date | string }
    | { __typename: "DeliveryEvent"; response: string; at: Date | string }
    | {
        __typename: "OpenEvent";
        userAgent: string;
        ipAddress: string;
        at: Date | string;
      }
    | { __typename: "RejectEvent"; reason: string; at: Date | string }
    | { __typename: "RenderingFailureEvent"; error: string; at: Date | string }
    | { __typename: "SendEvent"; at: Date | string }
    | {
        __typename: "SpamCheckEvent";
        userAgent: string;
        ipAddress: string;
        at: Date | string;
      }
    | { __typename: "SubscriptionEvent"; source: string; at: Date | string }
  >;
};

export type EmailsQueryVariables = Exact<{
  userId: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type EmailsQuery = {
  __typename?: "Query";
  emails: {
    __typename?: "EmailList";
    next?: string | null;
    emails: Array<{
      __typename?: "Email";
      id: string;
      template: string;
      status: EmailStatus;
      createdAt: Date | string;
      updatedAt: Date | string;
      events: Array<
        | {
            __typename: "BounceEvent";
            type: string;
            subType: string;
            at: Date | string;
          }
        | {
            __typename: "ClickEvent";
            userAgent: string;
            ipAddress: string;
            link: string;
            at: Date | string;
          }
        | { __typename: "ComplaintEvent"; type: string; at: Date | string }
        | { __typename: "DeliveryDelayEvent"; type: string; at: Date | string }
        | { __typename: "DeliveryEvent"; response: string; at: Date | string }
        | {
            __typename: "OpenEvent";
            userAgent: string;
            ipAddress: string;
            at: Date | string;
          }
        | { __typename: "RejectEvent"; reason: string; at: Date | string }
        | {
            __typename: "RenderingFailureEvent";
            error: string;
            at: Date | string;
          }
        | { __typename: "SendEvent"; at: Date | string }
        | {
            __typename: "SpamCheckEvent";
            userAgent: string;
            ipAddress: string;
            at: Date | string;
          }
        | { __typename: "SubscriptionEvent"; source: string; at: Date | string }
      >;
    }>;
  };
};

export type FairbanksTanksQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type FairbanksTanksQuery = {
  __typename?: "Query";
  fairbanksTanks: Array<{
    __typename?: "FairbanksTank";
    id: string;
    items: Array<{
      __typename?: "FairbanksStockLevel";
      ibank?: string | null;
      time?: string | null;
      tank?: string | null;
      volume?: number | null;
      temperature?: number | null;
      water?: number | null;
      height?: number | null;
    }>;
  }>;
};

export type FairbanksUsersQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FairbanksUsersQuery = {
  __typename?: "Query";
  fairbanksUsers: {
    __typename?: "FairbanksUserList";
    next?: string | null;
    users: Array<string>;
  };
};

export type FeeFragmentFragment = {
  __typename?: "Fee";
  id: string;
  invoiceId: string;
  amount: number;
  quantity: number;
  prepaid?: number | null;
  discount?: number | null;
  plan: { __typename?: "Plan"; id: string; code: string; model: PlanModel };
  account: {
    __typename?: "Account";
    id: string;
    name: string;
    product: AccountProduct;
  };
  tank?: { __typename?: "Tank"; id: string; name: string } | null;
};

export type UpdateFeeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: FeeInput;
}>;

export type UpdateFeeMutation = {
  __typename?: "Mutation";
  updateFee: {
    __typename?: "Fee";
    id: string;
    invoiceId: string;
    amount: number;
    quantity: number;
    prepaid?: number | null;
    discount?: number | null;
    plan: { __typename?: "Plan"; id: string; code: string; model: PlanModel };
    account: {
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
    };
    tank?: { __typename?: "Tank"; id: string; name: string } | null;
  };
};

export type CreateGaugeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: GaugeInput;
}>;

export type CreateGaugeMutation = {
  __typename?: "Mutation";
  createGauge: {
    __typename?: "Gauge";
    id: string;
    name: string;
    model?: string | null;
    product: GaugeProduct;
    technology: GaugeTechnology;
    createdAt: Date | string;
    updatedAt: Date | string;
    observations: {
      __typename?: "ObservationList";
      observations: Array<{
        __typename?: "Observation";
        id: string;
        reading: number;
        temperature?: number | null;
        voltage?: number | null;
        sleep?: number | null;
        signal?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
    tank?: { __typename?: "Tank"; id: string; name: string } | null;
    account?: {
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
    } | null;
    customer?: { __typename?: "Customer"; id: string; name: string } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
    strappingTable: Array<{ __typename?: "StrapRow"; mm: number; l: number }>;
    detail:
      | {
          __typename: "CippusDetail";
          model?: string | null;
          connection: string;
          range: string;
        }
      | {
          __typename: "TekADCDetail";
          model?: string | null;
          hardware: { __typename?: "Version"; major: number; minor: number };
          firmware: { __typename?: "Version"; major: number; minor: number };
        }
      | {
          __typename: "TekUltrasonicDetail";
          model?: string | null;
          hardware: { __typename?: "Version"; major: number; minor: number };
          firmware: { __typename?: "Version"; major: number; minor: number };
        }
      | {
          __typename: "UnregisteredDetail";
          model?: string | null;
          detail?: boolean | null;
        };
    config:
      | {
          __typename: "CippusConfig";
          offsetHeight: number;
          offsetVolume: number;
          specificGravityProbe: number;
          specificGravityActual: number;
        }
      | {
          __typename: "TekADCConfig";
          offsetHeight: number;
          offsetVolume: number;
          specificGravityProbe: number;
          specificGravityActual: number;
          rangeDigital: { __typename?: "Range"; min: number; max: number };
          rangeAnalog: { __typename?: "Range"; min: number; max: number };
          rangeProbe: { __typename?: "Range"; min: number; max: number };
          rangeHeight: { __typename?: "Range"; min: number; max: number };
        }
      | {
          __typename: "TekUltrasonicConfig";
          mountToBottom: number;
          offsetVolume: number;
        }
      | { __typename: "UnregisteredConfig"; config?: boolean | null };
  };
};

export type DeleteGaugeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteGaugeMutation = {
  __typename?: "Mutation";
  deleteGauge: boolean;
};

export type GaugeDetailFragment = {
  __typename?: "Gauge";
  strappingTable: Array<{ __typename?: "StrapRow"; mm: number; l: number }>;
  detail:
    | {
        __typename: "CippusDetail";
        model?: string | null;
        connection: string;
        range: string;
      }
    | {
        __typename: "TekADCDetail";
        model?: string | null;
        hardware: { __typename?: "Version"; major: number; minor: number };
        firmware: { __typename?: "Version"; major: number; minor: number };
      }
    | {
        __typename: "TekUltrasonicDetail";
        model?: string | null;
        hardware: { __typename?: "Version"; major: number; minor: number };
        firmware: { __typename?: "Version"; major: number; minor: number };
      }
    | {
        __typename: "UnregisteredDetail";
        model?: string | null;
        detail?: boolean | null;
      };
  config:
    | {
        __typename: "CippusConfig";
        offsetHeight: number;
        offsetVolume: number;
        specificGravityProbe: number;
        specificGravityActual: number;
      }
    | {
        __typename: "TekADCConfig";
        offsetHeight: number;
        offsetVolume: number;
        specificGravityProbe: number;
        specificGravityActual: number;
        rangeDigital: { __typename?: "Range"; min: number; max: number };
        rangeAnalog: { __typename?: "Range"; min: number; max: number };
        rangeProbe: { __typename?: "Range"; min: number; max: number };
        rangeHeight: { __typename?: "Range"; min: number; max: number };
      }
    | {
        __typename: "TekUltrasonicConfig";
        mountToBottom: number;
        offsetVolume: number;
      }
    | { __typename: "UnregisteredConfig"; config?: boolean | null };
};

export type GaugeFragmentFragment = {
  __typename?: "Gauge";
  id: string;
  name: string;
  model?: string | null;
  product: GaugeProduct;
  technology: GaugeTechnology;
  createdAt: Date | string;
  updatedAt: Date | string;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type GaugeResponseFragmentFragment = {
  __typename?: "GaugeResponse";
  id: string;
  timezone: string;
  items: string;
  product: GaugeResponseProduct;
  updatedAt: Date | string;
  settings:
    | {
        __typename: "TekADCResponseSettings";
        listen?: number | null;
        fallbackPhone?: string | null;
        password?: string | null;
        battery?: number | null;
        fStop?: number | null;
        eStop?: number | null;
        delay?: number | null;
        logger?: {
          __typename?: "LoggerSetting";
          frequency: number;
          sampling: number;
        } | null;
        schedule?: {
          __typename?: "ScheduleSetting";
          endTime?: number | null;
          frequency: number;
          every: ScheduleEvery;
          days: {
            __typename?: "ScheduleDays";
            Monday: boolean;
            Tuesday: boolean;
            Wednesday: boolean;
            Thursday: boolean;
            Friday: boolean;
            Saturday: boolean;
            Sunday: boolean;
          };
          startTime: {
            __typename?: "ScheduleStartTime";
            hour: number;
            minute: number;
          };
        } | null;
        control?: {
          __typename?: "ControlSetting";
          network?: string | null;
          bundAlarm: boolean;
          crcCheck: boolean;
          verboseLogging: boolean;
        } | null;
        staticAlarms?: Array<{
          __typename?: "StaticAlarmSetting";
          enabled: boolean;
          polarity?: StaticAlarmPolarity | null;
          threshold?: number | null;
          error?: number | null;
        }> | null;
        dynamicAlarms?: Array<{
          __typename?: "DynamicAlarmSetting";
          enabled: boolean;
          polarity?: DynamicAlarmPolarity | null;
          rate?: number | null;
        }> | null;
        apn?: {
          __typename?: "ApnSetting";
          gateway: string;
          username: string;
          password: string;
          authenticated: boolean;
        } | null;
        server?: {
          __typename?: "ServerSetting";
          host: string;
          port: number;
        } | null;
        network?: {
          __typename?: "NetworkSetting";
          operator: string;
          band: string;
        } | null;
        delivery?: {
          __typename?: "DeliverySetting";
          attempts: number;
          period: number;
        } | null;
      }
    | {
        __typename: "TekUltrasonicResponseSettings";
        listen?: number | null;
        fallbackPhone?: string | null;
        password?: string | null;
        measurement?: MeasurementSetting | null;
        height?: number | null;
        delay?: number | null;
        logger?: {
          __typename?: "LoggerSetting";
          frequency: number;
          sampling: number;
        } | null;
        schedule?: {
          __typename?: "ScheduleSetting";
          endTime?: number | null;
          frequency: number;
          every: ScheduleEvery;
          days: {
            __typename?: "ScheduleDays";
            Monday: boolean;
            Tuesday: boolean;
            Wednesday: boolean;
            Thursday: boolean;
            Friday: boolean;
            Saturday: boolean;
            Sunday: boolean;
          };
          startTime: {
            __typename?: "ScheduleStartTime";
            hour: number;
            minute: number;
          };
        } | null;
        control?: {
          __typename?: "ControlSetting";
          network?: string | null;
          bundAlarm: boolean;
          crcCheck: boolean;
          verboseLogging: boolean;
        } | null;
        staticAlarms?: Array<{
          __typename?: "StaticAlarmSetting";
          enabled: boolean;
          polarity?: StaticAlarmPolarity | null;
          threshold?: number | null;
          error?: number | null;
        }> | null;
        dynamicAlarms?: Array<{
          __typename?: "DynamicAlarmSetting";
          enabled: boolean;
          polarity?: DynamicAlarmPolarity | null;
          rate?: number | null;
        }> | null;
        apn?: {
          __typename?: "ApnSetting";
          gateway: string;
          username: string;
          password: string;
          authenticated: boolean;
        } | null;
        server?: {
          __typename?: "ServerSetting";
          host: string;
          port: number;
        } | null;
        network?: {
          __typename?: "NetworkSetting";
          operator: string;
          band: string;
        } | null;
        delivery?: {
          __typename?: "DeliverySetting";
          attempts: number;
          period: number;
        } | null;
        quality?: {
          __typename?: "QualitySetting";
          rssi: number;
          src: number;
        } | null;
      };
};

export type GaugeSettingFragmentFragment = {
  __typename?: "GaugeSetting";
  id: string;
  active: boolean;
  timezone: string;
  product: GaugeSettingProduct;
  updatedAt: Date | string;
  settings:
    | {
        __typename: "TekADCSettings";
        password?: string | null;
        schedule?: {
          __typename?: "ScheduleSetting";
          endTime?: number | null;
          frequency: number;
          every: ScheduleEvery;
          days: {
            __typename?: "ScheduleDays";
            Monday: boolean;
            Tuesday: boolean;
            Wednesday: boolean;
            Thursday: boolean;
            Friday: boolean;
            Saturday: boolean;
            Sunday: boolean;
          };
          startTime: {
            __typename?: "ScheduleStartTime";
            hour: number;
            minute: number;
          };
        } | null;
        staticAlarms?: Array<{
          __typename?: "StaticAlarmSetting";
          enabled: boolean;
          polarity?: StaticAlarmPolarity | null;
          threshold?: number | null;
          error?: number | null;
        }> | null;
        dynamicAlarms?: Array<{
          __typename?: "DynamicAlarmSetting";
          enabled: boolean;
          polarity?: DynamicAlarmPolarity | null;
          rate?: number | null;
        }> | null;
      }
    | {
        __typename: "TekUltrasonicSettings";
        password?: string | null;
        measurement?: MeasurementSetting | null;
        height?: number | null;
        schedule?: {
          __typename?: "ScheduleSetting";
          endTime?: number | null;
          frequency: number;
          every: ScheduleEvery;
          days: {
            __typename?: "ScheduleDays";
            Monday: boolean;
            Tuesday: boolean;
            Wednesday: boolean;
            Thursday: boolean;
            Friday: boolean;
            Saturday: boolean;
            Sunday: boolean;
          };
          startTime: {
            __typename?: "ScheduleStartTime";
            hour: number;
            minute: number;
          };
        } | null;
        staticAlarms?: Array<{
          __typename?: "StaticAlarmSetting";
          enabled: boolean;
          polarity?: StaticAlarmPolarity | null;
          threshold?: number | null;
          error?: number | null;
        }> | null;
        dynamicAlarms?: Array<{
          __typename?: "DynamicAlarmSetting";
          enabled: boolean;
          polarity?: DynamicAlarmPolarity | null;
          rate?: number | null;
        }> | null;
        quality?: {
          __typename?: "QualitySetting";
          rssi: number;
          src: number;
        } | null;
      };
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type GaugeQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GaugeQuery = {
  __typename?: "Query";
  gaugeAccess: Array<RolePermission>;
  gauge?: {
    __typename?: "Gauge";
    id: string;
    name: string;
    model?: string | null;
    product: GaugeProduct;
    technology: GaugeTechnology;
    createdAt: Date | string;
    updatedAt: Date | string;
    observations: {
      __typename?: "ObservationList";
      observations: Array<{
        __typename?: "Observation";
        id: string;
        reading: number;
        temperature?: number | null;
        voltage?: number | null;
        sleep?: number | null;
        signal?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
    tank?: { __typename?: "Tank"; id: string; name: string } | null;
    account?: {
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
    } | null;
    customer?: { __typename?: "Customer"; id: string; name: string } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
    strappingTable: Array<{ __typename?: "StrapRow"; mm: number; l: number }>;
    detail:
      | {
          __typename: "CippusDetail";
          model?: string | null;
          connection: string;
          range: string;
        }
      | {
          __typename: "TekADCDetail";
          model?: string | null;
          hardware: { __typename?: "Version"; major: number; minor: number };
          firmware: { __typename?: "Version"; major: number; minor: number };
        }
      | {
          __typename: "TekUltrasonicDetail";
          model?: string | null;
          hardware: { __typename?: "Version"; major: number; minor: number };
          firmware: { __typename?: "Version"; major: number; minor: number };
        }
      | {
          __typename: "UnregisteredDetail";
          model?: string | null;
          detail?: boolean | null;
        };
    config:
      | {
          __typename: "CippusConfig";
          offsetHeight: number;
          offsetVolume: number;
          specificGravityProbe: number;
          specificGravityActual: number;
        }
      | {
          __typename: "TekADCConfig";
          offsetHeight: number;
          offsetVolume: number;
          specificGravityProbe: number;
          specificGravityActual: number;
          rangeDigital: { __typename?: "Range"; min: number; max: number };
          rangeAnalog: { __typename?: "Range"; min: number; max: number };
          rangeProbe: { __typename?: "Range"; min: number; max: number };
          rangeHeight: { __typename?: "Range"; min: number; max: number };
        }
      | {
          __typename: "TekUltrasonicConfig";
          mountToBottom: number;
          offsetVolume: number;
        }
      | { __typename: "UnregisteredConfig"; config?: boolean | null };
  } | null;
};

export type GaugesQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GaugesQuery = {
  __typename?: "Query";
  gauges: {
    __typename?: "GaugeList";
    next?: string | null;
    gauges: Array<{
      __typename?: "Gauge";
      id: string;
      name: string;
      model?: string | null;
      product: GaugeProduct;
      technology: GaugeTechnology;
      createdAt: Date | string;
      updatedAt: Date | string;
      observations: {
        __typename?: "ObservationList";
        observations: Array<{
          __typename?: "Observation";
          id: string;
          reading: number;
          temperature?: number | null;
          voltage?: number | null;
          sleep?: number | null;
          signal?: number | null;
          rssi?: number | null;
          src?: number | null;
          updatedAt: Date | string;
        }>;
      };
      account?: {
        __typename?: "Account";
        id: string;
        name: string;
        product: AccountProduct;
      } | null;
      customer?: { __typename?: "Customer"; id: string; name: string } | null;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
      strappingTable: Array<{ __typename?: "StrapRow"; mm: number; l: number }>;
      detail:
        | {
            __typename: "CippusDetail";
            model?: string | null;
            connection: string;
            range: string;
          }
        | {
            __typename: "TekADCDetail";
            model?: string | null;
            hardware: { __typename?: "Version"; major: number; minor: number };
            firmware: { __typename?: "Version"; major: number; minor: number };
          }
        | {
            __typename: "TekUltrasonicDetail";
            model?: string | null;
            hardware: { __typename?: "Version"; major: number; minor: number };
            firmware: { __typename?: "Version"; major: number; minor: number };
          }
        | {
            __typename: "UnregisteredDetail";
            model?: string | null;
            detail?: boolean | null;
          };
      config:
        | {
            __typename: "CippusConfig";
            offsetHeight: number;
            offsetVolume: number;
            specificGravityProbe: number;
            specificGravityActual: number;
          }
        | {
            __typename: "TekADCConfig";
            offsetHeight: number;
            offsetVolume: number;
            specificGravityProbe: number;
            specificGravityActual: number;
            rangeDigital: { __typename?: "Range"; min: number; max: number };
            rangeAnalog: { __typename?: "Range"; min: number; max: number };
            rangeProbe: { __typename?: "Range"; min: number; max: number };
            rangeHeight: { __typename?: "Range"; min: number; max: number };
          }
        | {
            __typename: "TekUltrasonicConfig";
            mountToBottom: number;
            offsetVolume: number;
          }
        | { __typename: "UnregisteredConfig"; config?: boolean | null };
    }>;
  };
};

export type GaugeObservationsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GaugeObservationsQuery = {
  __typename?: "Query";
  gauge?: {
    __typename?: "Gauge";
    observations: {
      __typename?: "ObservationList";
      next?: string | null;
      observations: Array<{
        __typename?: "Observation";
        id: string;
        reading: number;
        temperature?: number | null;
        voltage?: number | null;
        sleep?: number | null;
        signal?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
  } | null;
};

export type GaugeSettingsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GaugeSettingsQuery = {
  __typename?: "Query";
  gaugeAccess: Array<RolePermission>;
  gauge?: {
    __typename?: "Gauge";
    id: string;
    name: string;
    product: GaugeProduct;
    deactivatedAt?: Date | string | null;
    setting?: {
      __typename?: "GaugeSetting";
      id: string;
      active: boolean;
      timezone: string;
      product: GaugeSettingProduct;
      updatedAt: Date | string;
      settings:
        | {
            __typename: "TekADCSettings";
            password?: string | null;
            schedule?: {
              __typename?: "ScheduleSetting";
              endTime?: number | null;
              frequency: number;
              every: ScheduleEvery;
              days: {
                __typename?: "ScheduleDays";
                Monday: boolean;
                Tuesday: boolean;
                Wednesday: boolean;
                Thursday: boolean;
                Friday: boolean;
                Saturday: boolean;
                Sunday: boolean;
              };
              startTime: {
                __typename?: "ScheduleStartTime";
                hour: number;
                minute: number;
              };
            } | null;
            staticAlarms?: Array<{
              __typename?: "StaticAlarmSetting";
              enabled: boolean;
              polarity?: StaticAlarmPolarity | null;
              threshold?: number | null;
              error?: number | null;
            }> | null;
            dynamicAlarms?: Array<{
              __typename?: "DynamicAlarmSetting";
              enabled: boolean;
              polarity?: DynamicAlarmPolarity | null;
              rate?: number | null;
            }> | null;
          }
        | {
            __typename: "TekUltrasonicSettings";
            password?: string | null;
            measurement?: MeasurementSetting | null;
            height?: number | null;
            schedule?: {
              __typename?: "ScheduleSetting";
              endTime?: number | null;
              frequency: number;
              every: ScheduleEvery;
              days: {
                __typename?: "ScheduleDays";
                Monday: boolean;
                Tuesday: boolean;
                Wednesday: boolean;
                Thursday: boolean;
                Friday: boolean;
                Saturday: boolean;
                Sunday: boolean;
              };
              startTime: {
                __typename?: "ScheduleStartTime";
                hour: number;
                minute: number;
              };
            } | null;
            staticAlarms?: Array<{
              __typename?: "StaticAlarmSetting";
              enabled: boolean;
              polarity?: StaticAlarmPolarity | null;
              threshold?: number | null;
              error?: number | null;
            }> | null;
            dynamicAlarms?: Array<{
              __typename?: "DynamicAlarmSetting";
              enabled: boolean;
              polarity?: DynamicAlarmPolarity | null;
              rate?: number | null;
            }> | null;
            quality?: {
              __typename?: "QualitySetting";
              rssi: number;
              src: number;
            } | null;
          };
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    responses: {
      __typename?: "GaugeResponseList";
      responses: Array<{
        __typename?: "GaugeResponse";
        id: string;
        timezone: string;
        items: string;
        product: GaugeResponseProduct;
        updatedAt: Date | string;
        settings:
          | {
              __typename: "TekADCResponseSettings";
              listen?: number | null;
              fallbackPhone?: string | null;
              password?: string | null;
              battery?: number | null;
              fStop?: number | null;
              eStop?: number | null;
              delay?: number | null;
              logger?: {
                __typename?: "LoggerSetting";
                frequency: number;
                sampling: number;
              } | null;
              schedule?: {
                __typename?: "ScheduleSetting";
                endTime?: number | null;
                frequency: number;
                every: ScheduleEvery;
                days: {
                  __typename?: "ScheduleDays";
                  Monday: boolean;
                  Tuesday: boolean;
                  Wednesday: boolean;
                  Thursday: boolean;
                  Friday: boolean;
                  Saturday: boolean;
                  Sunday: boolean;
                };
                startTime: {
                  __typename?: "ScheduleStartTime";
                  hour: number;
                  minute: number;
                };
              } | null;
              control?: {
                __typename?: "ControlSetting";
                network?: string | null;
                bundAlarm: boolean;
                crcCheck: boolean;
                verboseLogging: boolean;
              } | null;
              staticAlarms?: Array<{
                __typename?: "StaticAlarmSetting";
                enabled: boolean;
                polarity?: StaticAlarmPolarity | null;
                threshold?: number | null;
                error?: number | null;
              }> | null;
              dynamicAlarms?: Array<{
                __typename?: "DynamicAlarmSetting";
                enabled: boolean;
                polarity?: DynamicAlarmPolarity | null;
                rate?: number | null;
              }> | null;
              apn?: {
                __typename?: "ApnSetting";
                gateway: string;
                username: string;
                password: string;
                authenticated: boolean;
              } | null;
              server?: {
                __typename?: "ServerSetting";
                host: string;
                port: number;
              } | null;
              network?: {
                __typename?: "NetworkSetting";
                operator: string;
                band: string;
              } | null;
              delivery?: {
                __typename?: "DeliverySetting";
                attempts: number;
                period: number;
              } | null;
            }
          | {
              __typename: "TekUltrasonicResponseSettings";
              listen?: number | null;
              fallbackPhone?: string | null;
              password?: string | null;
              measurement?: MeasurementSetting | null;
              height?: number | null;
              delay?: number | null;
              logger?: {
                __typename?: "LoggerSetting";
                frequency: number;
                sampling: number;
              } | null;
              schedule?: {
                __typename?: "ScheduleSetting";
                endTime?: number | null;
                frequency: number;
                every: ScheduleEvery;
                days: {
                  __typename?: "ScheduleDays";
                  Monday: boolean;
                  Tuesday: boolean;
                  Wednesday: boolean;
                  Thursday: boolean;
                  Friday: boolean;
                  Saturday: boolean;
                  Sunday: boolean;
                };
                startTime: {
                  __typename?: "ScheduleStartTime";
                  hour: number;
                  minute: number;
                };
              } | null;
              control?: {
                __typename?: "ControlSetting";
                network?: string | null;
                bundAlarm: boolean;
                crcCheck: boolean;
                verboseLogging: boolean;
              } | null;
              staticAlarms?: Array<{
                __typename?: "StaticAlarmSetting";
                enabled: boolean;
                polarity?: StaticAlarmPolarity | null;
                threshold?: number | null;
                error?: number | null;
              }> | null;
              dynamicAlarms?: Array<{
                __typename?: "DynamicAlarmSetting";
                enabled: boolean;
                polarity?: DynamicAlarmPolarity | null;
                rate?: number | null;
              }> | null;
              apn?: {
                __typename?: "ApnSetting";
                gateway: string;
                username: string;
                password: string;
                authenticated: boolean;
              } | null;
              server?: {
                __typename?: "ServerSetting";
                host: string;
                port: number;
              } | null;
              network?: {
                __typename?: "NetworkSetting";
                operator: string;
                band: string;
              } | null;
              delivery?: {
                __typename?: "DeliverySetting";
                attempts: number;
                period: number;
              } | null;
              quality?: {
                __typename?: "QualitySetting";
                rssi: number;
                src: number;
              } | null;
            };
      }>;
    };
    observations: {
      __typename?: "ObservationList";
      observations: Array<{
        __typename?: "Observation";
        id: string;
        reading: number;
        temperature?: number | null;
        voltage?: number | null;
        sleep?: number | null;
        signal?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
  } | null;
};

export type UpdateGaugeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: GaugeInput;
}>;

export type UpdateGaugeMutation = {
  __typename?: "Mutation";
  updateGauge: {
    __typename?: "Gauge";
    id: string;
    name: string;
    model?: string | null;
    product: GaugeProduct;
    technology: GaugeTechnology;
    createdAt: Date | string;
    updatedAt: Date | string;
    observations: {
      __typename?: "ObservationList";
      observations: Array<{
        __typename?: "Observation";
        id: string;
        reading: number;
        temperature?: number | null;
        voltage?: number | null;
        sleep?: number | null;
        signal?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
    tank?: { __typename?: "Tank"; id: string; name: string } | null;
    account?: {
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
    } | null;
    customer?: { __typename?: "Customer"; id: string; name: string } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
    strappingTable: Array<{ __typename?: "StrapRow"; mm: number; l: number }>;
    detail:
      | {
          __typename: "CippusDetail";
          model?: string | null;
          connection: string;
          range: string;
        }
      | {
          __typename: "TekADCDetail";
          model?: string | null;
          hardware: { __typename?: "Version"; major: number; minor: number };
          firmware: { __typename?: "Version"; major: number; minor: number };
        }
      | {
          __typename: "TekUltrasonicDetail";
          model?: string | null;
          hardware: { __typename?: "Version"; major: number; minor: number };
          firmware: { __typename?: "Version"; major: number; minor: number };
        }
      | {
          __typename: "UnregisteredDetail";
          model?: string | null;
          detail?: boolean | null;
        };
    config:
      | {
          __typename: "CippusConfig";
          offsetHeight: number;
          offsetVolume: number;
          specificGravityProbe: number;
          specificGravityActual: number;
        }
      | {
          __typename: "TekADCConfig";
          offsetHeight: number;
          offsetVolume: number;
          specificGravityProbe: number;
          specificGravityActual: number;
          rangeDigital: { __typename?: "Range"; min: number; max: number };
          rangeAnalog: { __typename?: "Range"; min: number; max: number };
          rangeProbe: { __typename?: "Range"; min: number; max: number };
          rangeHeight: { __typename?: "Range"; min: number; max: number };
        }
      | {
          __typename: "TekUltrasonicConfig";
          mountToBottom: number;
          offsetVolume: number;
        }
      | { __typename: "UnregisteredConfig"; config?: boolean | null };
  };
};

export type UpdateGaugeSettingMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: GaugeSettingInput;
}>;

export type UpdateGaugeSettingMutation = {
  __typename?: "Mutation";
  updateGaugeSetting: {
    __typename?: "GaugeSetting";
    id: string;
    active: boolean;
    timezone: string;
    product: GaugeSettingProduct;
    updatedAt: Date | string;
    settings:
      | {
          __typename: "TekADCSettings";
          password?: string | null;
          schedule?: {
            __typename?: "ScheduleSetting";
            endTime?: number | null;
            frequency: number;
            every: ScheduleEvery;
            days: {
              __typename?: "ScheduleDays";
              Monday: boolean;
              Tuesday: boolean;
              Wednesday: boolean;
              Thursday: boolean;
              Friday: boolean;
              Saturday: boolean;
              Sunday: boolean;
            };
            startTime: {
              __typename?: "ScheduleStartTime";
              hour: number;
              minute: number;
            };
          } | null;
          staticAlarms?: Array<{
            __typename?: "StaticAlarmSetting";
            enabled: boolean;
            polarity?: StaticAlarmPolarity | null;
            threshold?: number | null;
            error?: number | null;
          }> | null;
          dynamicAlarms?: Array<{
            __typename?: "DynamicAlarmSetting";
            enabled: boolean;
            polarity?: DynamicAlarmPolarity | null;
            rate?: number | null;
          }> | null;
        }
      | {
          __typename: "TekUltrasonicSettings";
          password?: string | null;
          measurement?: MeasurementSetting | null;
          height?: number | null;
          schedule?: {
            __typename?: "ScheduleSetting";
            endTime?: number | null;
            frequency: number;
            every: ScheduleEvery;
            days: {
              __typename?: "ScheduleDays";
              Monday: boolean;
              Tuesday: boolean;
              Wednesday: boolean;
              Thursday: boolean;
              Friday: boolean;
              Saturday: boolean;
              Sunday: boolean;
            };
            startTime: {
              __typename?: "ScheduleStartTime";
              hour: number;
              minute: number;
            };
          } | null;
          staticAlarms?: Array<{
            __typename?: "StaticAlarmSetting";
            enabled: boolean;
            polarity?: StaticAlarmPolarity | null;
            threshold?: number | null;
            error?: number | null;
          }> | null;
          dynamicAlarms?: Array<{
            __typename?: "DynamicAlarmSetting";
            enabled: boolean;
            polarity?: DynamicAlarmPolarity | null;
            rate?: number | null;
          }> | null;
          quality?: {
            __typename?: "QualitySetting";
            rssi: number;
            src: number;
          } | null;
        };
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type VerifyGaugeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type VerifyGaugeMutation = {
  __typename?: "Mutation";
  verifyGauge: boolean;
};

export type InviteUserMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  jlteam?: InputMaybe<TeamPermission>;
}>;

export type InviteUserMutation = {
  __typename?: "Mutation";
  inviteUser?: {
    __typename?: "Invite";
    id: string;
    email: string;
    jlteam?: TeamPermission | null;
    createdAt: Date | string;
    updatedAt: Date | string;
  } | null;
};

export type DeleteInviteMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteInviteMutation = {
  __typename?: "Mutation";
  deleteInvite: boolean;
};

export type InviteFragmentFragment = {
  __typename?: "Invite";
  id: string;
  email: string;
  jlteam?: TeamPermission | null;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type InvitesQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type InvitesQuery = {
  __typename?: "Query";
  invites: {
    __typename?: "InviteList";
    next?: string | null;
    invites: Array<{
      __typename?: "Invite";
      id: string;
      email: string;
      jlteam?: TeamPermission | null;
      createdAt: Date | string;
      updatedAt: Date | string;
    }>;
  };
};

export type DeleteInvoiceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteInvoiceMutation = {
  __typename?: "Mutation";
  deleteInvoice: boolean;
};

export type InvoiceFeesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type InvoiceFeesQuery = {
  __typename?: "Query";
  invoice?: {
    __typename?: "Invoice";
    fees: {
      __typename?: "FeeList";
      next?: string | null;
      fees: Array<{
        __typename?: "Fee";
        id: string;
        invoiceId: string;
        amount: number;
        quantity: number;
        prepaid?: number | null;
        discount?: number | null;
        plan: {
          __typename?: "Plan";
          id: string;
          code: string;
          model: PlanModel;
        };
        account: {
          __typename?: "Account";
          id: string;
          name: string;
          product: AccountProduct;
        };
        tank?: { __typename?: "Tank"; id: string; name: string } | null;
      }>;
    };
  } | null;
};

export type InvoiceFragmentFragment = {
  __typename?: "Invoice";
  id: string;
  month: number;
  year: number;
  adjustment?: number | null;
  createdAt: Date | string;
  updatedAt: Date | string;
  linkedInvoice?: {
    __typename?: "LinkedInvoice";
    id: string;
    number: string;
    status: string;
    link: string;
  } | null;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type GenerateInvoicesMutationVariables = Exact<{
  month: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
}>;

export type GenerateInvoicesMutation = {
  __typename?: "Mutation";
  generateInvoices: number;
};

export type InvoiceQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type InvoiceQuery = {
  __typename?: "Query";
  invoice?: {
    __typename?: "Invoice";
    id: string;
    month: number;
    year: number;
    adjustment?: number | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    customer: { __typename?: "Customer"; id: string; name: string };
    linkedInvoice?: {
      __typename?: "LinkedInvoice";
      id: string;
      number: string;
      status: string;
      link: string;
    } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type InvoicesQueryVariables = Exact<{
  month: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type InvoicesQuery = {
  __typename?: "Query";
  invoices: {
    __typename?: "InvoiceList";
    next?: string | null;
    invoices: Array<{
      __typename?: "Invoice";
      id: string;
      month: number;
      year: number;
      adjustment?: number | null;
      createdAt: Date | string;
      updatedAt: Date | string;
      fees: {
        __typename?: "FeeList";
        next?: string | null;
        fees: Array<{
          __typename?: "Fee";
          id: string;
          invoiceId: string;
          amount: number;
          quantity: number;
          prepaid?: number | null;
          discount?: number | null;
          plan: {
            __typename?: "Plan";
            id: string;
            code: string;
            model: PlanModel;
          };
          account: {
            __typename?: "Account";
            id: string;
            name: string;
            product: AccountProduct;
          };
          tank?: { __typename?: "Tank"; id: string; name: string } | null;
        }>;
      };
      customer: { __typename?: "Customer"; id: string; name: string };
      linkedInvoice?: {
        __typename?: "LinkedInvoice";
        id: string;
        number: string;
        status: string;
        link: string;
      } | null;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type RegenerateInvoiceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RegenerateInvoiceMutation = {
  __typename?: "Mutation";
  regenerateInvoice: {
    __typename?: "Invoice";
    id: string;
    month: number;
    year: number;
    adjustment?: number | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    fees: {
      __typename?: "FeeList";
      next?: string | null;
      fees: Array<{
        __typename?: "Fee";
        id: string;
        invoiceId: string;
        amount: number;
        quantity: number;
        prepaid?: number | null;
        discount?: number | null;
        plan: {
          __typename?: "Plan";
          id: string;
          code: string;
          model: PlanModel;
        };
        account: {
          __typename?: "Account";
          id: string;
          name: string;
          product: AccountProduct;
        };
        tank?: { __typename?: "Tank"; id: string; name: string } | null;
      }>;
    };
    customer: { __typename?: "Customer"; id: string; name: string };
    linkedInvoice?: {
      __typename?: "LinkedInvoice";
      id: string;
      number: string;
      status: string;
      link: string;
    } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type UpdateInvoiceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: InvoiceInput;
}>;

export type UpdateInvoiceMutation = {
  __typename?: "Mutation";
  updateInvoice: {
    __typename?: "Invoice";
    id: string;
    month: number;
    year: number;
    adjustment?: number | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    linkedInvoice?: {
      __typename?: "LinkedInvoice";
      id: string;
      number: string;
      status: string;
      link: string;
    } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type LevelFragmentFragment = {
  __typename?: "Level";
  id: string;
  value: number;
  maximum: number;
  percent: number;
  signal?: number | null;
  temperature?: number | null;
  voltage?: number | null;
  rssi?: number | null;
  src?: number | null;
  updatedAt: Date | string;
};

export type LogGroupsQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LogGroupsQuery = {
  __typename?: "Query";
  logGroups: {
    __typename?: "LogGroupList";
    next?: string | null;
    logGroups: Array<string>;
  };
};

export type LogsQueryVariables = Exact<{
  group: Scalars["String"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<Scalars["String"]["input"]>;
  from?: InputMaybe<Scalars["DateTimeISO"]["input"]>;
}>;

export type LogsQuery = {
  __typename?: "Query";
  logs: {
    __typename?: "LogList";
    next?: string | null;
    logs: Array<{
      __typename?: "Log";
      id: string;
      timestamp: Date | string;
      message: string;
      requestId?: string | null;
      ipAddress?: string | null;
      level?: string | null;
      content?: string | null;
      parsed?: Array<Record<string, any>> | null;
    }>;
  };
};

export type ParseQueryVariables = Exact<{
  type: Scalars["String"]["input"];
  data: Scalars["String"]["input"];
}>;

export type ParseQuery = { __typename?: "Query"; parse: Record<string, any> };

export type ObservationFragmentFragment = {
  __typename?: "Observation";
  id: string;
  reading: number;
  temperature?: number | null;
  voltage?: number | null;
  sleep?: number | null;
  signal?: number | null;
  rssi?: number | null;
  src?: number | null;
  updatedAt: Date | string;
};

export type PlanAccountsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type PlanAccountsQuery = {
  __typename?: "Query";
  plan?: {
    __typename?: "Plan";
    accounts: {
      __typename?: "AccountList";
      next?: string | null;
      accounts: Array<{
        __typename?: "Account";
        id: string;
        name: string;
        product: AccountProduct;
        createdAt: Date | string;
        updatedAt: Date | string;
        customer?: {
          __typename?: "Customer";
          id: string;
          name: string;
          interval: number;
          createdAt: Date | string;
          updatedAt: Date | string;
          linkedContact?: {
            __typename?: "LinkedContact";
            id: string;
            name: string;
            accountNumber?: string | null;
          } | null;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        } | null;
        plan?: {
          __typename?: "Plan";
          id: string;
          code: string;
          description?: string | null;
          amount: number;
          prepaid?: number | null;
          model: PlanModel;
          createdAt: Date | string;
          updatedAt: Date | string;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        } | null;
        config?:
          | { __typename: "ColibriConfig"; url?: string | null }
          | {
              __typename: "JoyGaugeConfig";
              gauges: {
                __typename?: "GaugeList";
                gauges: Array<{
                  __typename?: "Gauge";
                  id: string;
                  name: string;
                  technology: GaugeTechnology;
                  model?: string | null;
                }>;
              };
            }
          | {
              __typename: "ProGaugeConfig";
              userId?: string | null;
              timezone?: string | null;
              maximum?: Record<string, any> | null;
            }
          | {
              __typename: "RMSDeltaConfig";
              serialNumbers?: Array<string> | null;
            }
          | {
              __typename: "SmartFillConfig";
              reference?: string | null;
              secret?: string | null;
            }
          | null;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      }>;
    };
  } | null;
};

export type CreatePlanMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: PlanInput;
}>;

export type CreatePlanMutation = {
  __typename?: "Mutation";
  createPlan: {
    __typename?: "Plan";
    id: string;
    code: string;
    description?: string | null;
    amount: number;
    prepaid?: number | null;
    model: PlanModel;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type DeletePlanMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeletePlanMutation = {
  __typename?: "Mutation";
  deletePlan: boolean;
};

export type PlanFragmentFragment = {
  __typename?: "Plan";
  id: string;
  code: string;
  description?: string | null;
  amount: number;
  prepaid?: number | null;
  model: PlanModel;
  createdAt: Date | string;
  updatedAt: Date | string;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type PlanQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type PlanQuery = {
  __typename?: "Query";
  plan?: {
    __typename?: "Plan";
    id: string;
    code: string;
    description?: string | null;
    amount: number;
    prepaid?: number | null;
    model: PlanModel;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type PlansQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type PlansQuery = {
  __typename?: "Query";
  plans: {
    __typename?: "PlanList";
    next?: string | null;
    plans: Array<{
      __typename?: "Plan";
      id: string;
      code: string;
      description?: string | null;
      amount: number;
      prepaid?: number | null;
      model: PlanModel;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type UpdatePlanMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: PlanInput;
}>;

export type UpdatePlanMutation = {
  __typename?: "Mutation";
  updatePlan: {
    __typename?: "Plan";
    id: string;
    code: string;
    description?: string | null;
    amount: number;
    prepaid?: number | null;
    model: PlanModel;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type CreateProductMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  fields: ProductInput;
}>;

export type CreateProductMutation = {
  __typename?: "Mutation";
  createProduct: {
    __typename?: "Product";
    id: string;
    label: string;
    colour: string;
    customerId: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type DeleteProductMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteProductMutation = {
  __typename?: "Mutation";
  deleteProduct: boolean;
};

export type ProductFragmentFragment = {
  __typename?: "Product";
  id: string;
  label: string;
  colour: string;
  customerId: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type ProductQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ProductQuery = {
  __typename?: "Query";
  product?: {
    __typename?: "Product";
    id: string;
    label: string;
    colour: string;
    customerId: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type ProductsQueryVariables = Exact<{
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type ProductsQuery = {
  __typename?: "Query";
  products: {
    __typename?: "ProductList";
    next?: string | null;
    products: Array<{
      __typename?: "Product";
      id: string;
      label: string;
      colour: string;
      customerId: string;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type UpdateProductMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: ProductInput;
}>;

export type UpdateProductMutation = {
  __typename?: "Mutation";
  updateProduct: {
    __typename?: "Product";
    id: string;
    label: string;
    colour: string;
    customerId: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type CreateRoleMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: RoleInput;
}>;

export type CreateRoleMutation = {
  __typename?: "Mutation";
  createRole: {
    __typename?: "Role";
    id: string;
    entityType: RoleEntityType;
    entityId: string;
    permissions: Array<RolePermission>;
    createdAt: Date | string;
    user: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
    createdBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteRoleMutation = {
  __typename?: "Mutation";
  deleteRole: boolean;
};

export type UserRolesQueryVariables = Exact<{
  userId: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UserRolesQuery = {
  __typename?: "Query";
  userRoles: {
    __typename?: "RoleList";
    next?: string | null;
    roles: Array<{
      __typename?: "Role";
      id: string;
      entityType: RoleEntityType;
      entityId: string;
      permissions: Array<RolePermission>;
      createdAt: Date | string;
      entity:
        | {
            __typename: "Account";
            id: string;
            name: string;
            product: AccountProduct;
            createdAt: Date | string;
            updatedAt: Date | string;
            customer?: {
              __typename?: "Customer";
              id: string;
              name: string;
              interval: number;
              createdAt: Date | string;
              updatedAt: Date | string;
              linkedContact?: {
                __typename?: "LinkedContact";
                id: string;
                name: string;
                accountNumber?: string | null;
              } | null;
              updatedBy: {
                __typename?: "User";
                id: string;
                email: string;
                firstName?: string | null;
                lastName?: string | null;
                picture?: string | null;
              };
            } | null;
            config?:
              | { __typename: "ColibriConfig"; url?: string | null }
              | {
                  __typename: "JoyGaugeConfig";
                  gauges: {
                    __typename?: "GaugeList";
                    gauges: Array<{
                      __typename?: "Gauge";
                      id: string;
                      name: string;
                      technology: GaugeTechnology;
                      model?: string | null;
                    }>;
                  };
                }
              | {
                  __typename: "ProGaugeConfig";
                  userId?: string | null;
                  timezone?: string | null;
                  maximum?: Record<string, any> | null;
                }
              | {
                  __typename: "RMSDeltaConfig";
                  serialNumbers?: Array<string> | null;
                }
              | {
                  __typename: "SmartFillConfig";
                  reference?: string | null;
                  secret?: string | null;
                }
              | null;
            updatedBy: {
              __typename?: "User";
              id: string;
              email: string;
              firstName?: string | null;
              lastName?: string | null;
              picture?: string | null;
            };
          }
        | {
            __typename: "Customer";
            id: string;
            name: string;
            interval: number;
            createdAt: Date | string;
            updatedAt: Date | string;
            linkedContact?: {
              __typename?: "LinkedContact";
              id: string;
              name: string;
              accountNumber?: string | null;
            } | null;
            updatedBy: {
              __typename?: "User";
              id: string;
              email: string;
              firstName?: string | null;
              lastName?: string | null;
              picture?: string | null;
            };
          }
        | {
            __typename: "Tank";
            id: string;
            name: string;
            unit?: TankUnit | null;
            accountProduct: AccountProduct;
            activatedAt?: Date | string | null;
            customerId: string;
            createdAt: Date | string;
            updatedAt: Date | string;
            account?: {
              __typename?: "Account";
              id: string;
              name: string;
              product: AccountProduct;
              createdAt: Date | string;
              updatedAt: Date | string;
              customer?: {
                __typename?: "Customer";
                id: string;
                name: string;
                interval: number;
                createdAt: Date | string;
                updatedAt: Date | string;
                linkedContact?: {
                  __typename?: "LinkedContact";
                  id: string;
                  name: string;
                  accountNumber?: string | null;
                } | null;
                updatedBy: {
                  __typename?: "User";
                  id: string;
                  email: string;
                  firstName?: string | null;
                  lastName?: string | null;
                  picture?: string | null;
                };
              } | null;
              config?:
                | { __typename: "ColibriConfig"; url?: string | null }
                | {
                    __typename: "JoyGaugeConfig";
                    gauges: {
                      __typename?: "GaugeList";
                      gauges: Array<{
                        __typename?: "Gauge";
                        id: string;
                        name: string;
                        technology: GaugeTechnology;
                        model?: string | null;
                      }>;
                    };
                  }
                | {
                    __typename: "ProGaugeConfig";
                    userId?: string | null;
                    timezone?: string | null;
                    maximum?: Record<string, any> | null;
                  }
                | {
                    __typename: "RMSDeltaConfig";
                    serialNumbers?: Array<string> | null;
                  }
                | {
                    __typename: "SmartFillConfig";
                    reference?: string | null;
                    secret?: string | null;
                  }
                | null;
              updatedBy: {
                __typename?: "User";
                id: string;
                email: string;
                firstName?: string | null;
                lastName?: string | null;
                picture?: string | null;
              };
            } | null;
            tankProduct?: {
              __typename?: "Product";
              id: string;
              label: string;
              colour: string;
            } | null;
            location?: {
              __typename?: "TankLocation";
              display: string;
              latitude: number;
              longitude: number;
            } | null;
            alertLevels?: Array<{
              __typename?: "AlertLevel";
              threshold: number;
              polarity: AlertLevelPolarity;
            }> | null;
            alertRates?: Array<{
              __typename?: "AlertRate";
              threshold: number;
              polarity: AlertRatePolarity;
              period: number;
            }> | null;
            alertUsers?: Array<{
              __typename?: "User";
              id: string;
              email: string;
              firstName?: string | null;
              lastName?: string | null;
              picture?: string | null;
            }> | null;
            updatedBy: {
              __typename?: "User";
              id: string;
              email: string;
              firstName?: string | null;
              lastName?: string | null;
              picture?: string | null;
            };
          };
      user: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
      createdBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type RoleFragmentFragment = {
  __typename?: "Role";
  id: string;
  entityType: RoleEntityType;
  entityId: string;
  permissions: Array<RolePermission>;
  createdAt: Date | string;
  user: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
  createdBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type RoleQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RoleQuery = {
  __typename?: "Query";
  role?: {
    __typename?: "Role";
    id: string;
    entityType: RoleEntityType;
    entityId: string;
    permissions: Array<RolePermission>;
    createdAt: Date | string;
    entity:
      | {
          __typename: "Account";
          id: string;
          name: string;
          product: AccountProduct;
          createdAt: Date | string;
          updatedAt: Date | string;
          config?:
            | { __typename: "ColibriConfig"; url?: string | null }
            | {
                __typename: "JoyGaugeConfig";
                gauges: {
                  __typename?: "GaugeList";
                  gauges: Array<{
                    __typename?: "Gauge";
                    id: string;
                    name: string;
                    technology: GaugeTechnology;
                    model?: string | null;
                  }>;
                };
              }
            | {
                __typename: "ProGaugeConfig";
                userId?: string | null;
                timezone?: string | null;
                maximum?: Record<string, any> | null;
              }
            | {
                __typename: "RMSDeltaConfig";
                serialNumbers?: Array<string> | null;
              }
            | {
                __typename: "SmartFillConfig";
                reference?: string | null;
                secret?: string | null;
              }
            | null;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        }
      | {
          __typename: "Customer";
          id: string;
          name: string;
          interval: number;
          createdAt: Date | string;
          updatedAt: Date | string;
          linkedContact?: {
            __typename?: "LinkedContact";
            id: string;
            name: string;
            accountNumber?: string | null;
          } | null;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        }
      | {
          __typename: "Tank";
          id: string;
          name: string;
          unit?: TankUnit | null;
          accountProduct: AccountProduct;
          activatedAt?: Date | string | null;
          customerId: string;
          createdAt: Date | string;
          updatedAt: Date | string;
          tankProduct?: {
            __typename?: "Product";
            id: string;
            label: string;
            colour: string;
          } | null;
          location?: {
            __typename?: "TankLocation";
            display: string;
            latitude: number;
            longitude: number;
          } | null;
          alertLevels?: Array<{
            __typename?: "AlertLevel";
            threshold: number;
            polarity: AlertLevelPolarity;
          }> | null;
          alertRates?: Array<{
            __typename?: "AlertRate";
            threshold: number;
            polarity: AlertRatePolarity;
            period: number;
          }> | null;
          alertUsers?: Array<{
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          }> | null;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        };
    user: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
    createdBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type RolesQueryVariables = Exact<{
  entityId: Scalars["ID"]["input"];
  entityType: RoleEntityType;
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type RolesQuery = {
  __typename?: "Query";
  roles: {
    __typename?: "RoleList";
    next?: string | null;
    roles: Array<{
      __typename?: "Role";
      id: string;
      entityType: RoleEntityType;
      entityId: string;
      permissions: Array<RolePermission>;
      createdAt: Date | string;
      user: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
      createdBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  permissions: Array<RolePermission> | RolePermission;
}>;

export type UpdateRoleMutation = {
  __typename?: "Mutation";
  updateRole: {
    __typename?: "Role";
    id: string;
    entityType: RoleEntityType;
    entityId: string;
    permissions: Array<RolePermission>;
    createdAt: Date | string;
    user: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
    createdBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type CreateScheduleMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  entityType: ScheduleEntityType;
  entityId: Scalars["ID"]["input"];
  fields: ScheduleInput;
}>;

export type CreateScheduleMutation = {
  __typename?: "Mutation";
  createSchedule: {
    __typename?: "Schedule";
    id: string;
    startTimeUTC: string;
    timezone: string;
    interval: ScheduleInterval;
    date?: number | null;
    entityId: string;
    entityType: ScheduleEntityType;
    startTime: {
      __typename?: "ScheduleStartTime";
      hour: number;
      minute: number;
    };
    days?: {
      __typename?: "ScheduleDays";
      Monday: boolean;
      Tuesday: boolean;
      Wednesday: boolean;
      Thursday: boolean;
      Friday: boolean;
      Saturday: boolean;
      Sunday: boolean;
    } | null;
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    }>;
  };
};

export type DeleteScheduleMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteScheduleMutation = {
  __typename?: "Mutation";
  deleteSchedule: boolean;
};

export type ScheduleFragmentFragment = {
  __typename?: "Schedule";
  id: string;
  startTimeUTC: string;
  timezone: string;
  interval: ScheduleInterval;
  date?: number | null;
  entityId: string;
  entityType: ScheduleEntityType;
  startTime: { __typename?: "ScheduleStartTime"; hour: number; minute: number };
  days?: {
    __typename?: "ScheduleDays";
    Monday: boolean;
    Tuesday: boolean;
    Wednesday: boolean;
    Thursday: boolean;
    Friday: boolean;
    Saturday: boolean;
    Sunday: boolean;
  } | null;
  users: Array<{
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  }>;
};

export type ScheduleQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ScheduleQuery = {
  __typename?: "Query";
  schedule?: {
    __typename?: "Schedule";
    id: string;
    startTimeUTC: string;
    timezone: string;
    interval: ScheduleInterval;
    date?: number | null;
    entityId: string;
    entityType: ScheduleEntityType;
    startTime: {
      __typename?: "ScheduleStartTime";
      hour: number;
      minute: number;
    };
    days?: {
      __typename?: "ScheduleDays";
      Monday: boolean;
      Tuesday: boolean;
      Wednesday: boolean;
      Thursday: boolean;
      Friday: boolean;
      Saturday: boolean;
      Sunday: boolean;
    } | null;
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    }>;
  } | null;
};

export type UpdateScheduleMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: ScheduleInput;
}>;

export type UpdateScheduleMutation = {
  __typename?: "Mutation";
  updateSchedule: {
    __typename?: "Schedule";
    id: string;
    startTimeUTC: string;
    timezone: string;
    interval: ScheduleInterval;
    date?: number | null;
    entityId: string;
    entityType: ScheduleEntityType;
    startTime: {
      __typename?: "ScheduleStartTime";
      hour: number;
      minute: number;
    };
    days?: {
      __typename?: "ScheduleDays";
      Monday: boolean;
      Tuesday: boolean;
      Wednesday: boolean;
      Thursday: boolean;
      Friday: boolean;
      Saturday: boolean;
      Sunday: boolean;
    } | null;
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    }>;
  };
};

export type TankAlertsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TankAlertsQuery = {
  __typename?: "Query";
  tank?: {
    __typename?: "Tank";
    alerts: {
      __typename?: "AlertList";
      next?: string | null;
      alerts: Array<{
        __typename?: "Alert";
        id: string;
        scenario: AlertScenario;
        expected: number;
        actual: number;
        updatedAt: Date | string;
      }>;
    };
  } | null;
};

export type TankFragmentFragment = {
  __typename?: "Tank";
  id: string;
  name: string;
  unit?: TankUnit | null;
  accountProduct: AccountProduct;
  activatedAt?: Date | string | null;
  customerId: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  tankProduct?: {
    __typename?: "Product";
    id: string;
    label: string;
    colour: string;
  } | null;
  location?: {
    __typename?: "TankLocation";
    display: string;
    latitude: number;
    longitude: number;
  } | null;
  alertLevels?: Array<{
    __typename?: "AlertLevel";
    threshold: number;
    polarity: AlertLevelPolarity;
  }> | null;
  alertRates?: Array<{
    __typename?: "AlertRate";
    threshold: number;
    polarity: AlertRatePolarity;
    period: number;
  }> | null;
  alertUsers?: Array<{
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  }> | null;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type TankQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type TankQuery = {
  __typename?: "Query";
  tankAccess: Array<RolePermission>;
  tank?: {
    __typename?: "Tank";
    id: string;
    name: string;
    unit?: TankUnit | null;
    accountProduct: AccountProduct;
    activatedAt?: Date | string | null;
    customerId: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    levels: {
      __typename?: "LevelList";
      levels: Array<{
        __typename?: "Level";
        id: string;
        value: number;
        maximum: number;
        percent: number;
        signal?: number | null;
        temperature?: number | null;
        voltage?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
    gauge?: { __typename?: "Gauge"; id: string; name: string } | null;
    account?: {
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
    } | null;
    tankProduct?: {
      __typename?: "Product";
      id: string;
      label: string;
      colour: string;
    } | null;
    location?: {
      __typename?: "TankLocation";
      display: string;
      latitude: number;
      longitude: number;
    } | null;
    alertLevels?: Array<{
      __typename?: "AlertLevel";
      threshold: number;
      polarity: AlertLevelPolarity;
    }> | null;
    alertRates?: Array<{
      __typename?: "AlertRate";
      threshold: number;
      polarity: AlertRatePolarity;
      period: number;
    }> | null;
    alertUsers?: Array<{
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    }> | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type TankLevelsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TankLevelsQuery = {
  __typename?: "Query";
  tank?: {
    __typename?: "Tank";
    levels: {
      __typename?: "LevelList";
      next?: string | null;
      levels: Array<{
        __typename?: "Level";
        id: string;
        value: number;
        maximum: number;
        percent: number;
        signal?: number | null;
        temperature?: number | null;
        voltage?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
  } | null;
};

export type TanksQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TanksQuery = {
  __typename?: "Query";
  tanks: {
    __typename?: "TankList";
    next?: string | null;
    tanks: Array<{
      __typename?: "Tank";
      id: string;
      name: string;
      unit?: TankUnit | null;
      accountProduct: AccountProduct;
      activatedAt?: Date | string | null;
      customerId: string;
      createdAt: Date | string;
      updatedAt: Date | string;
      levels: {
        __typename?: "LevelList";
        levels: Array<{
          __typename?: "Level";
          id: string;
          value: number;
          maximum: number;
          percent: number;
          signal?: number | null;
          temperature?: number | null;
          voltage?: number | null;
          rssi?: number | null;
          src?: number | null;
          updatedAt: Date | string;
        }>;
      };
      account?: {
        __typename?: "Account";
        name: string;
        product: AccountProduct;
        customer?: { __typename?: "Customer"; name: string } | null;
        plan?: { __typename?: "Plan"; code: string } | null;
      } | null;
      tankProduct?: {
        __typename?: "Product";
        id: string;
        label: string;
        colour: string;
      } | null;
      location?: {
        __typename?: "TankLocation";
        display: string;
        latitude: number;
        longitude: number;
      } | null;
      alertLevels?: Array<{
        __typename?: "AlertLevel";
        threshold: number;
        polarity: AlertLevelPolarity;
      }> | null;
      alertRates?: Array<{
        __typename?: "AlertRate";
        threshold: number;
        polarity: AlertRatePolarity;
        period: number;
      }> | null;
      alertUsers?: Array<{
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      }> | null;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type TankRolesQueryVariables = Exact<{
  tankId: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TankRolesQuery = {
  __typename?: "Query";
  tankRoles: {
    __typename?: "RoleList";
    next?: string | null;
    roles: Array<{
      __typename?: "Role";
      user: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type UpdateTankMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: TankInput;
}>;

export type UpdateTankMutation = {
  __typename?: "Mutation";
  updateTank: {
    __typename?: "Tank";
    id: string;
    name: string;
    unit?: TankUnit | null;
    accountProduct: AccountProduct;
    activatedAt?: Date | string | null;
    customerId: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    levels: {
      __typename?: "LevelList";
      levels: Array<{
        __typename?: "Level";
        id: string;
        value: number;
        maximum: number;
        percent: number;
        signal?: number | null;
        temperature?: number | null;
        voltage?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
    gauge?: { __typename?: "Gauge"; id: string; name: string } | null;
    account?: {
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
    } | null;
    tankProduct?: {
      __typename?: "Product";
      id: string;
      label: string;
      colour: string;
    } | null;
    location?: {
      __typename?: "TankLocation";
      display: string;
      latitude: number;
      longitude: number;
    } | null;
    alertLevels?: Array<{
      __typename?: "AlertLevel";
      threshold: number;
      polarity: AlertLevelPolarity;
    }> | null;
    alertRates?: Array<{
      __typename?: "AlertRate";
      threshold: number;
      polarity: AlertRatePolarity;
      period: number;
    }> | null;
    alertUsers?: Array<{
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    }> | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type ActorFragmentFragment = {
  __typename?: "User";
  id: string;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  picture?: string | null;
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteUserMutation = {
  __typename?: "Mutation";
  deleteUser: boolean;
};

export type UserFragmentFragment = {
  __typename?: "User";
  id: string;
  email: string;
  jlteam?: TeamPermission | null;
  firstName?: string | null;
  lastName?: string | null;
  picture?: string | null;
  identities?: Array<string> | null;
  phone?: string | null;
  company?: string | null;
  title?: string | null;
  createdAt: Date | string;
  lastActiveAt?: Date | string | null;
};

export type ImpersonateUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type ImpersonateUserMutation = {
  __typename?: "Mutation";
  impersonateUser: string;
};

export type UserQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type UserQuery = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    id: string;
    email: string;
    jlteam?: TeamPermission | null;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
    identities?: Array<string> | null;
    phone?: string | null;
    company?: string | null;
    title?: string | null;
    createdAt: Date | string;
    lastActiveAt?: Date | string | null;
  };
};

export type UsersQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UsersQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UserList";
    next?: string | null;
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      jlteam?: TeamPermission | null;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
      identities?: Array<string> | null;
      phone?: string | null;
      company?: string | null;
      title?: string | null;
      createdAt: Date | string;
      lastActiveAt?: Date | string | null;
    }>;
  };
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: UserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser?: {
    __typename?: "User";
    id: string;
    email: string;
    jlteam?: TeamPermission | null;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
    identities?: Array<string> | null;
    phone?: string | null;
    company?: string | null;
    title?: string | null;
    createdAt: Date | string;
    lastActiveAt?: Date | string | null;
  } | null;
};

export const ActorFragmentFragmentDoc = gql`
  fragment ActorFragment on User {
    id
    email
    firstName
    lastName
    picture
  }
` as unknown as DocumentNode<ActorFragmentFragment, unknown>;
export const AccountFragmentFragmentDoc = gql`
  fragment AccountFragment on Account {
    id
    name
    product
    config {
      __typename
      ... on ColibriConfig {
        url
      }
      ... on JoyGaugeConfig {
        gauges(limit: 500) {
          gauges {
            id
            name
            technology
            model
          }
        }
      }
      ... on ProGaugeConfig {
        userId
        timezone
        maximum
      }
      ... on RMSDeltaConfig {
        serialNumbers
      }
      ... on SmartFillConfig {
        reference
        secret
      }
    }
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<AccountFragmentFragment, unknown>;
export const CommentFragmentFragmentDoc = gql`
  fragment CommentFragment on Comment {
    id
    message
    createdAt
    createdBy {
      ...ActorFragment
    }
    updatedAt
  }
` as unknown as DocumentNode<CommentFragmentFragment, unknown>;
export const LinkedContactFragmentFragmentDoc = gql`
  fragment LinkedContactFragment on LinkedContact {
    id
    name
    accountNumber
  }
` as unknown as DocumentNode<LinkedContactFragmentFragment, unknown>;
export const CustomerFragmentFragmentDoc = gql`
  fragment CustomerFragment on Customer {
    id
    name
    interval
    linkedContact {
      ...LinkedContactFragment
    }
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<CustomerFragmentFragment, unknown>;
export const DocumentFragmentFragmentDoc = gql`
  fragment DocumentFragment on Document {
    id
    title
    content
    html
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<DocumentFragmentFragment, unknown>;
export const EmailFragmentFragmentDoc = gql`
  fragment EmailFragment on Email {
    id
    template
    status
    events {
      __typename
      ... on SendEvent {
        at
      }
      ... on DeliveryEvent {
        response
        at
      }
      ... on ComplaintEvent {
        type
        at
      }
      ... on BounceEvent {
        type
        subType
        at
      }
      ... on RejectEvent {
        reason
        at
      }
      ... on SpamCheckEvent {
        userAgent
        ipAddress
        at
      }
      ... on OpenEvent {
        userAgent
        ipAddress
        at
      }
      ... on ClickEvent {
        userAgent
        ipAddress
        link
        at
      }
      ... on RenderingFailureEvent {
        error
        at
      }
      ... on DeliveryDelayEvent {
        type
        at
      }
      ... on SubscriptionEvent {
        source
        at
      }
    }
    createdAt
    updatedAt
  }
` as unknown as DocumentNode<EmailFragmentFragment, unknown>;
export const FeeFragmentFragmentDoc = gql`
  fragment FeeFragment on Fee {
    id
    invoiceId
    amount
    quantity
    prepaid
    discount
    plan {
      id
      code
      model
    }
    account {
      id
      name
      product
    }
    tank {
      id
      name
    }
  }
` as unknown as DocumentNode<FeeFragmentFragment, unknown>;
export const GaugeDetailFragmentDoc = gql`
  fragment GaugeDetail on Gauge {
    strappingTable {
      mm
      l
    }
    detail {
      __typename
      ... on UnregisteredDetail {
        model
        detail
      }
      ... on CippusDetail {
        model
        connection
        range
      }
      ... on TekUltrasonicDetail {
        model
        hardware {
          major
          minor
        }
        firmware {
          major
          minor
        }
      }
      ... on TekADCDetail {
        model
        hardware {
          major
          minor
        }
        firmware {
          major
          minor
        }
      }
    }
    config {
      __typename
      ... on UnregisteredConfig {
        config
      }
      ... on CippusConfig {
        offsetHeight
        offsetVolume
        specificGravityProbe
        specificGravityActual
      }
      ... on TekUltrasonicConfig {
        mountToBottom
        offsetVolume
      }
      ... on TekADCConfig {
        rangeDigital {
          min
          max
        }
        rangeAnalog {
          min
          max
        }
        rangeProbe {
          min
          max
        }
        rangeHeight {
          min
          max
        }
        offsetHeight
        offsetVolume
        specificGravityProbe
        specificGravityActual
      }
    }
  }
` as unknown as DocumentNode<GaugeDetailFragment, unknown>;
export const GaugeFragmentFragmentDoc = gql`
  fragment GaugeFragment on Gauge {
    id
    name
    model
    product
    technology
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<GaugeFragmentFragment, unknown>;
export const GaugeResponseFragmentFragmentDoc = gql`
  fragment GaugeResponseFragment on GaugeResponse {
    id
    timezone
    items
    product
    settings {
      __typename
      ... on TekUltrasonicResponseSettings {
        logger {
          frequency
          sampling
        }
        listen
        schedule {
          days {
            Monday
            Tuesday
            Wednesday
            Thursday
            Friday
            Saturday
            Sunday
          }
          startTime {
            hour
            minute
          }
          endTime
          frequency
          every
        }
        control {
          network
          bundAlarm
          crcCheck
          verboseLogging
        }
        staticAlarms {
          enabled
          polarity
          threshold
          error
        }
        dynamicAlarms {
          enabled
          polarity
          rate
        }
        fallbackPhone
        password
        apn {
          gateway
          username
          password
          authenticated
        }
        server {
          host
          port
        }
        measurement
        height
        network {
          operator
          band
        }
        delivery {
          attempts
          period
        }
        delay
        quality {
          rssi
          src
        }
      }
      ... on TekADCResponseSettings {
        logger {
          frequency
          sampling
        }
        listen
        schedule {
          days {
            Monday
            Tuesday
            Wednesday
            Thursday
            Friday
            Saturday
            Sunday
          }
          startTime {
            hour
            minute
          }
          endTime
          frequency
          every
        }
        control {
          network
          bundAlarm
          crcCheck
          verboseLogging
        }
        staticAlarms {
          enabled
          polarity
          threshold
          error
        }
        dynamicAlarms {
          enabled
          polarity
          rate
        }
        fallbackPhone
        password
        apn {
          gateway
          username
          password
          authenticated
        }
        server {
          host
          port
        }
        battery
        fStop
        eStop
        network {
          operator
          band
        }
        delivery {
          attempts
          period
        }
        delay
      }
    }
    updatedAt
  }
` as unknown as DocumentNode<GaugeResponseFragmentFragment, unknown>;
export const GaugeSettingFragmentFragmentDoc = gql`
  fragment GaugeSettingFragment on GaugeSetting {
    id
    active
    timezone
    product
    settings {
      __typename
      ... on TekUltrasonicSettings {
        password
        measurement
        height
        schedule {
          days {
            Monday
            Tuesday
            Wednesday
            Thursday
            Friday
            Saturday
            Sunday
          }
          startTime {
            hour
            minute
          }
          endTime
          frequency
          every
        }
        staticAlarms {
          enabled
          polarity
          threshold
          error
        }
        dynamicAlarms {
          enabled
          polarity
          rate
        }
        quality {
          rssi
          src
        }
      }
      ... on TekADCSettings {
        password
        schedule {
          days {
            Monday
            Tuesday
            Wednesday
            Thursday
            Friday
            Saturday
            Sunday
          }
          startTime {
            hour
            minute
          }
          endTime
          frequency
          every
        }
        staticAlarms {
          enabled
          polarity
          threshold
          error
        }
        dynamicAlarms {
          enabled
          polarity
          rate
        }
      }
    }
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<GaugeSettingFragmentFragment, unknown>;
export const InviteFragmentFragmentDoc = gql`
  fragment InviteFragment on Invite {
    id
    email
    jlteam
    createdAt
    updatedAt
  }
` as unknown as DocumentNode<InviteFragmentFragment, unknown>;
export const InvoiceFragmentFragmentDoc = gql`
  fragment InvoiceFragment on Invoice {
    id
    linkedInvoice {
      id
      number
      status
      link
    }
    month
    year
    adjustment
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<InvoiceFragmentFragment, unknown>;
export const LevelFragmentFragmentDoc = gql`
  fragment LevelFragment on Level {
    id
    value
    maximum
    percent
    signal
    temperature
    voltage
    rssi
    src
    updatedAt
  }
` as unknown as DocumentNode<LevelFragmentFragment, unknown>;
export const ObservationFragmentFragmentDoc = gql`
  fragment ObservationFragment on Observation {
    id
    reading
    temperature
    voltage
    sleep
    signal
    rssi
    src
    updatedAt
  }
` as unknown as DocumentNode<ObservationFragmentFragment, unknown>;
export const PlanFragmentFragmentDoc = gql`
  fragment PlanFragment on Plan {
    id
    code
    description
    amount
    prepaid
    model
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<PlanFragmentFragment, unknown>;
export const ProductFragmentFragmentDoc = gql`
  fragment ProductFragment on Product {
    id
    label
    colour
    customerId
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<ProductFragmentFragment, unknown>;
export const RoleFragmentFragmentDoc = gql`
  fragment RoleFragment on Role {
    id
    entityType
    entityId
    user {
      ...ActorFragment
    }
    permissions
    createdAt
    createdBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<RoleFragmentFragment, unknown>;
export const ScheduleFragmentFragmentDoc = gql`
  fragment ScheduleFragment on Schedule {
    id
    startTimeUTC
    startTime {
      hour
      minute
    }
    timezone
    interval
    date
    days {
      Monday
      Tuesday
      Wednesday
      Thursday
      Friday
      Saturday
      Sunday
    }
    users {
      ...ActorFragment
    }
    entityId
    entityType
  }
` as unknown as DocumentNode<ScheduleFragmentFragment, unknown>;
export const TankFragmentFragmentDoc = gql`
  fragment TankFragment on Tank {
    id
    name
    unit
    accountProduct
    tankProduct {
      id
      label
      colour
    }
    location {
      display
      latitude
      longitude
    }
    activatedAt
    alertLevels {
      threshold
      polarity
    }
    alertRates {
      threshold
      polarity
      period
    }
    alertUsers {
      ...ActorFragment
    }
    customerId
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<TankFragmentFragment, unknown>;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    id
    email
    jlteam
    firstName
    lastName
    picture
    identities
    phone
    company
    title
    createdAt
    lastActiveAt
  }
` as unknown as DocumentNode<UserFragmentFragment, unknown>;
export const CreateAccountDocument = gql`
  mutation createAccount($id: ID!, $fields: AccountInput!) {
    createAccount(id: $id, fields: $fields) {
      ...AccountFragment
      schedules {
        ...ScheduleFragment
      }
      customer {
        ...CustomerFragment
      }
      plan {
        ...PlanFragment
      }
    }
  }
  ${AccountFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${ScheduleFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${LinkedContactFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<
  CreateAccountMutation,
  CreateAccountMutationVariables
>;
export const DeleteAccountDocument = gql`
  mutation deleteAccount($id: ID!) {
    deleteAccount(id: $id)
  }
` as unknown as DocumentNode<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const AccountDocument = gql`
  query account($id: ID!) {
    account(id: $id) {
      ...AccountFragment
      schedules {
        ...ScheduleFragment
      }
      customer {
        ...CustomerFragment
      }
      plan {
        ...PlanFragment
      }
    }
    accountAccess(id: $id)
  }
  ${AccountFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${ScheduleFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${LinkedContactFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<AccountQuery, AccountQueryVariables>;
export const AccountsDocument = gql`
  query accounts($limit: Int!, $cursor: String) {
    accounts(limit: $limit, cursor: $cursor) {
      next
      accounts {
        ...AccountFragment
        customer {
          ...CustomerFragment
        }
        plan {
          ...PlanFragment
        }
      }
    }
  }
  ${AccountFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${LinkedContactFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<AccountsQuery, AccountsQueryVariables>;
export const AccountTanksDocument = gql`
  query accountTanks($id: ID!, $limit: Int!, $cursor: String) {
    account(id: $id) {
      tanks(limit: $limit, cursor: $cursor) {
        next
        tanks {
          ...TankFragment
          levels(limit: 1) {
            levels {
              ...LevelFragment
            }
          }
        }
      }
    }
  }
  ${TankFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${LevelFragmentFragmentDoc}
` as unknown as DocumentNode<AccountTanksQuery, AccountTanksQueryVariables>;
export const UpdateAccountDocument = gql`
  mutation updateAccount($id: ID!, $fields: AccountInput!) {
    updateAccount(id: $id, fields: $fields) {
      ...AccountFragment
      schedules {
        ...ScheduleFragment
      }
      customer {
        ...CustomerFragment
      }
      plan {
        ...PlanFragment
      }
    }
  }
  ${AccountFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${ScheduleFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${LinkedContactFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const VerifyAccountDocument = gql`
  mutation verifyAccount($id: ID!) {
    verifyAccount(id: $id)
  }
` as unknown as DocumentNode<
  VerifyAccountMutation,
  VerifyAccountMutationVariables
>;
export const ChangesDocument = gql`
  query changes(
    $entityType: ChangeEntity!
    $entityId: ID!
    $limit: Int!
    $cursor: String
  ) {
    changes(
      entityType: $entityType
      entityId: $entityId
      limit: $limit
      cursor: $cursor
    ) {
      next
      changes {
        id
        action
        previous
        current
        updatedAt
        updatedBy {
          ...ActorFragment
        }
      }
    }
  }
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<ChangesQuery, ChangesQueryVariables>;
export const CreateCommentDocument = gql`
  mutation createComment($id: ID!, $fields: CommentInput!) {
    createComment(id: $id, fields: $fields) {
      ...CommentFragment
    }
  }
  ${CommentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  CreateCommentMutation,
  CreateCommentMutationVariables
>;
export const DeleteCommentDocument = gql`
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id)
  }
` as unknown as DocumentNode<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export const CommentsDocument = gql`
  query comments(
    $entityId: ID!
    $entityType: CommentEntity!
    $limit: Int!
    $cursor: String
  ) {
    comments(
      entityId: $entityId
      entityType: $entityType
      limit: $limit
      cursor: $cursor
    ) {
      next
      comments {
        ...CommentFragment
      }
    }
  }
  ${CommentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<CommentsQuery, CommentsQueryVariables>;
export const UpdateCommentDocument = gql`
  mutation updateComment($id: ID!, $fields: CommentInput!) {
    updateComment(id: $id, fields: $fields) {
      ...CommentFragment
    }
  }
  ${CommentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>;
export const CustomerAccountsDocument = gql`
  query customerAccounts($id: ID!, $limit: Int!, $cursor: String) {
    customer(id: $id) {
      accounts(limit: $limit, cursor: $cursor) {
        next
        accounts {
          ...AccountFragment
          customer {
            ...CustomerFragment
          }
          plan {
            ...PlanFragment
          }
        }
      }
    }
  }
  ${AccountFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${LinkedContactFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<
  CustomerAccountsQuery,
  CustomerAccountsQueryVariables
>;
export const LinkedContactsDocument = gql`
  query LinkedContacts($search: String!, $limit: Int!, $cursor: String) {
    linkedContacts(search: $search, limit: $limit, cursor: $cursor) {
      contacts {
        ...LinkedContactFragment
      }
      next
    }
  }
  ${LinkedContactFragmentFragmentDoc}
` as unknown as DocumentNode<LinkedContactsQuery, LinkedContactsQueryVariables>;
export const CreateCustomerDocument = gql`
  mutation createCustomer($id: ID!, $fields: CustomerInput!) {
    createCustomer(id: $id, fields: $fields) {
      ...CustomerFragment
    }
  }
  ${CustomerFragmentFragmentDoc}
  ${LinkedContactFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;
export const DeleteCustomerDocument = gql`
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id)
  }
` as unknown as DocumentNode<
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables
>;
export const CustomerDocument = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      ...CustomerFragment
    }
  }
  ${CustomerFragmentFragmentDoc}
  ${LinkedContactFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<CustomerQuery, CustomerQueryVariables>;
export const CustomersDocument = gql`
  query customers($limit: Int!, $cursor: String) {
    customers(limit: $limit, cursor: $cursor) {
      next
      customers {
        ...CustomerFragment
      }
    }
  }
  ${CustomerFragmentFragmentDoc}
  ${LinkedContactFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<CustomersQuery, CustomersQueryVariables>;
export const UpdateCustomerDocument = gql`
  mutation updateCustomer($id: ID!, $fields: CustomerInput!) {
    updateCustomer(id: $id, fields: $fields) {
      ...CustomerFragment
    }
  }
  ${CustomerFragmentFragmentDoc}
  ${LinkedContactFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
export const CreateDocumentDocument = gql`
  mutation createDocument($id: ID!, $fields: DocumentInput!) {
    createDocument(id: $id, fields: $fields) {
      ...DocumentFragment
    }
  }
  ${DocumentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  CreateDocumentMutation,
  CreateDocumentMutationVariables
>;
export const DeleteDocumentDocument = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id)
  }
` as unknown as DocumentNode<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;
export const DocumentDocument = gql`
  query document($id: ID!) {
    document(id: $id) {
      ...DocumentFragment
    }
  }
  ${DocumentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<DocumentQuery, DocumentQueryVariables>;
export const DocumentsDocument = gql`
  query documents($limit: Int!, $cursor: String) {
    documents(limit: $limit, cursor: $cursor) {
      next
      documents {
        ...DocumentFragment
      }
    }
  }
  ${DocumentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<DocumentsQuery, DocumentsQueryVariables>;
export const UpdateDocumentDocument = gql`
  mutation updateDocument($id: ID!, $fields: DocumentInput!) {
    updateDocument(id: $id, fields: $fields) {
      ...DocumentFragment
    }
  }
  ${DocumentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables
>;
export const EmailsDocument = gql`
  query emails($userId: ID!, $limit: Int!, $cursor: String) {
    emails(userId: $userId, limit: $limit, cursor: $cursor) {
      next
      emails {
        ...EmailFragment
      }
    }
  }
  ${EmailFragmentFragmentDoc}
` as unknown as DocumentNode<EmailsQuery, EmailsQueryVariables>;
export const FairbanksTanksDocument = gql`
  query fairbanksTanks($userId: String!) {
    fairbanksTanks(userId: $userId) {
      id
      items {
        ibank
        time
        tank
        volume
        temperature
        water
        height
      }
    }
  }
` as unknown as DocumentNode<FairbanksTanksQuery, FairbanksTanksQueryVariables>;
export const FairbanksUsersDocument = gql`
  query fairbanksUsers($limit: Int!, $cursor: String) {
    fairbanksUsers(limit: $limit, cursor: $cursor) {
      next
      users
    }
  }
` as unknown as DocumentNode<FairbanksUsersQuery, FairbanksUsersQueryVariables>;
export const UpdateFeeDocument = gql`
  mutation updateFee($id: ID!, $fields: FeeInput!) {
    updateFee(id: $id, fields: $fields) {
      ...FeeFragment
    }
  }
  ${FeeFragmentFragmentDoc}
` as unknown as DocumentNode<UpdateFeeMutation, UpdateFeeMutationVariables>;
export const CreateGaugeDocument = gql`
  mutation createGauge($id: ID!, $fields: GaugeInput!) {
    createGauge(id: $id, fields: $fields) {
      ...GaugeFragment
      ...GaugeDetail
      observations(limit: 1) {
        observations {
          ...ObservationFragment
        }
      }
      tank {
        id
        name
      }
      account {
        id
        name
        product
      }
      customer {
        id
        name
      }
    }
  }
  ${GaugeFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${GaugeDetailFragmentDoc}
  ${ObservationFragmentFragmentDoc}
` as unknown as DocumentNode<CreateGaugeMutation, CreateGaugeMutationVariables>;
export const DeleteGaugeDocument = gql`
  mutation deleteGauge($id: ID!) {
    deleteGauge(id: $id)
  }
` as unknown as DocumentNode<DeleteGaugeMutation, DeleteGaugeMutationVariables>;
export const GaugeDocument = gql`
  query gauge($id: ID!) {
    gauge(id: $id) {
      ...GaugeFragment
      ...GaugeDetail
      observations(limit: 1) {
        observations {
          ...ObservationFragment
        }
      }
      tank {
        id
        name
      }
      account {
        id
        name
        product
      }
      customer {
        id
        name
      }
    }
    gaugeAccess(id: $id)
  }
  ${GaugeFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${GaugeDetailFragmentDoc}
  ${ObservationFragmentFragmentDoc}
` as unknown as DocumentNode<GaugeQuery, GaugeQueryVariables>;
export const GaugesDocument = gql`
  query gauges($limit: Int!, $cursor: String) {
    gauges(limit: $limit, cursor: $cursor) {
      next
      gauges {
        ...GaugeFragment
        ...GaugeDetail
        observations(limit: 1) {
          observations {
            ...ObservationFragment
          }
        }
        account {
          id
          name
          product
        }
        customer {
          id
          name
        }
      }
    }
  }
  ${GaugeFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${GaugeDetailFragmentDoc}
  ${ObservationFragmentFragmentDoc}
` as unknown as DocumentNode<GaugesQuery, GaugesQueryVariables>;
export const GaugeObservationsDocument = gql`
  query gaugeObservations(
    $id: ID!
    $limit: Int!
    $startAt: DateTime
    $cursor: String
  ) {
    gauge(id: $id) {
      observations(limit: $limit, cursor: $cursor, startAt: $startAt) {
        next
        observations {
          ...ObservationFragment
        }
      }
    }
  }
  ${ObservationFragmentFragmentDoc}
` as unknown as DocumentNode<
  GaugeObservationsQuery,
  GaugeObservationsQueryVariables
>;
export const GaugeSettingsDocument = gql`
  query gaugeSettings($id: ID!) {
    gauge(id: $id) {
      id
      name
      product
      deactivatedAt
      setting {
        ...GaugeSettingFragment
      }
      responses(limit: 1) {
        responses {
          ...GaugeResponseFragment
        }
      }
      observations(limit: 1) {
        observations {
          ...ObservationFragment
        }
      }
    }
    gaugeAccess(id: $id)
  }
  ${GaugeSettingFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${GaugeResponseFragmentFragmentDoc}
  ${ObservationFragmentFragmentDoc}
` as unknown as DocumentNode<GaugeSettingsQuery, GaugeSettingsQueryVariables>;
export const UpdateGaugeDocument = gql`
  mutation updateGauge($id: ID!, $fields: GaugeInput!) {
    updateGauge(id: $id, fields: $fields) {
      ...GaugeFragment
      ...GaugeDetail
      observations(limit: 1) {
        observations {
          ...ObservationFragment
        }
      }
      tank {
        id
        name
      }
      account {
        id
        name
        product
      }
      customer {
        id
        name
      }
    }
  }
  ${GaugeFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${GaugeDetailFragmentDoc}
  ${ObservationFragmentFragmentDoc}
` as unknown as DocumentNode<UpdateGaugeMutation, UpdateGaugeMutationVariables>;
export const UpdateGaugeSettingDocument = gql`
  mutation updateGaugeSetting($id: ID!, $fields: GaugeSettingInput!) {
    updateGaugeSetting(id: $id, fields: $fields) {
      ...GaugeSettingFragment
    }
  }
  ${GaugeSettingFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  UpdateGaugeSettingMutation,
  UpdateGaugeSettingMutationVariables
>;
export const VerifyGaugeDocument = gql`
  mutation verifyGauge($id: ID!) {
    verifyGauge(id: $id)
  }
` as unknown as DocumentNode<VerifyGaugeMutation, VerifyGaugeMutationVariables>;
export const InviteUserDocument = gql`
  mutation inviteUser($email: String!, $jlteam: TeamPermission) {
    inviteUser(email: $email, jlteam: $jlteam) {
      ...InviteFragment
    }
  }
  ${InviteFragmentFragmentDoc}
` as unknown as DocumentNode<InviteUserMutation, InviteUserMutationVariables>;
export const DeleteInviteDocument = gql`
  mutation deleteInvite($id: ID!) {
    deleteInvite(id: $id)
  }
` as unknown as DocumentNode<
  DeleteInviteMutation,
  DeleteInviteMutationVariables
>;
export const InvitesDocument = gql`
  query invites($limit: Int!, $cursor: String) {
    invites(limit: $limit, cursor: $cursor) {
      next
      invites {
        ...InviteFragment
      }
    }
  }
  ${InviteFragmentFragmentDoc}
` as unknown as DocumentNode<InvitesQuery, InvitesQueryVariables>;
export const DeleteInvoiceDocument = gql`
  mutation deleteInvoice($id: ID!) {
    deleteInvoice(id: $id)
  }
` as unknown as DocumentNode<
  DeleteInvoiceMutation,
  DeleteInvoiceMutationVariables
>;
export const InvoiceFeesDocument = gql`
  query invoiceFees($id: ID!, $limit: Int!, $cursor: String) {
    invoice(id: $id) {
      fees(limit: $limit, cursor: $cursor) {
        fees {
          ...FeeFragment
        }
        next
      }
    }
  }
  ${FeeFragmentFragmentDoc}
` as unknown as DocumentNode<InvoiceFeesQuery, InvoiceFeesQueryVariables>;
export const GenerateInvoicesDocument = gql`
  mutation generateInvoices($month: Int!, $year: Int!) {
    generateInvoices(month: $month, year: $year)
  }
` as unknown as DocumentNode<
  GenerateInvoicesMutation,
  GenerateInvoicesMutationVariables
>;
export const InvoiceDocument = gql`
  query invoice($id: ID!) {
    invoice(id: $id) {
      ...InvoiceFragment
      customer {
        id
        name
      }
    }
  }
  ${InvoiceFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<InvoiceQuery, InvoiceQueryVariables>;
export const InvoicesDocument = gql`
  query invoices($month: Int!, $year: Int!, $limit: Int!, $cursor: String) {
    invoices(month: $month, year: $year, limit: $limit, cursor: $cursor) {
      next
      invoices {
        ...InvoiceFragment
        fees(limit: 1000) {
          fees {
            ...FeeFragment
          }
          next
        }
        customer {
          id
          name
        }
      }
    }
  }
  ${InvoiceFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${FeeFragmentFragmentDoc}
` as unknown as DocumentNode<InvoicesQuery, InvoicesQueryVariables>;
export const RegenerateInvoiceDocument = gql`
  mutation regenerateInvoice($id: ID!) {
    regenerateInvoice(id: $id) {
      ...InvoiceFragment
      fees(limit: 1000) {
        fees {
          ...FeeFragment
        }
        next
      }
      customer {
        id
        name
      }
    }
  }
  ${InvoiceFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${FeeFragmentFragmentDoc}
` as unknown as DocumentNode<
  RegenerateInvoiceMutation,
  RegenerateInvoiceMutationVariables
>;
export const UpdateInvoiceDocument = gql`
  mutation updateInvoice($id: ID!, $fields: InvoiceInput!) {
    updateInvoice(id: $id, fields: $fields) {
      ...InvoiceFragment
    }
  }
  ${InvoiceFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables
>;
export const LogGroupsDocument = gql`
  query logGroups($limit: Int!, $cursor: String) {
    logGroups(limit: $limit, cursor: $cursor) {
      next
      logGroups
    }
  }
` as unknown as DocumentNode<LogGroupsQuery, LogGroupsQueryVariables>;
export const LogsDocument = gql`
  query logs(
    $group: String!
    $limit: Int!
    $cursor: String
    $filter: String
    $from: DateTimeISO
  ) {
    logs(
      group: $group
      limit: $limit
      cursor: $cursor
      filter: $filter
      from: $from
    ) {
      next
      logs {
        id
        timestamp
        message
        requestId
        ipAddress
        level
        content
        parsed
      }
    }
  }
` as unknown as DocumentNode<LogsQuery, LogsQueryVariables>;
export const ParseDocument = gql`
  query parse($type: String!, $data: String!) {
    parse(type: $type, data: $data)
  }
` as unknown as DocumentNode<ParseQuery, ParseQueryVariables>;
export const PlanAccountsDocument = gql`
  query planAccounts($id: ID!, $limit: Int!, $cursor: String) {
    plan(id: $id) {
      accounts(limit: $limit, cursor: $cursor) {
        next
        accounts {
          ...AccountFragment
          customer {
            ...CustomerFragment
          }
          plan {
            ...PlanFragment
          }
        }
      }
    }
  }
  ${AccountFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${LinkedContactFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<PlanAccountsQuery, PlanAccountsQueryVariables>;
export const CreatePlanDocument = gql`
  mutation createPlan($id: ID!, $fields: PlanInput!) {
    createPlan(id: $id, fields: $fields) {
      ...PlanFragment
    }
  }
  ${PlanFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<CreatePlanMutation, CreatePlanMutationVariables>;
export const DeletePlanDocument = gql`
  mutation deletePlan($id: ID!) {
    deletePlan(id: $id)
  }
` as unknown as DocumentNode<DeletePlanMutation, DeletePlanMutationVariables>;
export const PlanDocument = gql`
  query plan($id: ID!) {
    plan(id: $id) {
      ...PlanFragment
    }
  }
  ${PlanFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<PlanQuery, PlanQueryVariables>;
export const PlansDocument = gql`
  query plans($limit: Int!, $cursor: String) {
    plans(limit: $limit, cursor: $cursor) {
      next
      plans {
        ...PlanFragment
      }
    }
  }
  ${PlanFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<PlansQuery, PlansQueryVariables>;
export const UpdatePlanDocument = gql`
  mutation updatePlan($id: ID!, $fields: PlanInput!) {
    updatePlan(id: $id, fields: $fields) {
      ...PlanFragment
    }
  }
  ${PlanFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const CreateProductDocument = gql`
  mutation createProduct($id: ID!, $customerId: ID, $fields: ProductInput!) {
    createProduct(id: $id, customerId: $customerId, fields: $fields) {
      ...ProductFragment
    }
  }
  ${ProductFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  CreateProductMutation,
  CreateProductMutationVariables
>;
export const DeleteProductDocument = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct(id: $id)
  }
` as unknown as DocumentNode<
  DeleteProductMutation,
  DeleteProductMutationVariables
>;
export const ProductDocument = gql`
  query product($id: ID!) {
    product(id: $id) {
      ...ProductFragment
    }
  }
  ${ProductFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<ProductQuery, ProductQueryVariables>;
export const ProductsDocument = gql`
  query products($customerId: ID) {
    products(customerId: $customerId) {
      next
      products {
        ...ProductFragment
      }
    }
  }
  ${ProductFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<ProductsQuery, ProductsQueryVariables>;
export const UpdateProductDocument = gql`
  mutation updateProduct($id: ID!, $fields: ProductInput!) {
    updateProduct(id: $id, fields: $fields) {
      ...ProductFragment
    }
  }
  ${ProductFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  UpdateProductMutation,
  UpdateProductMutationVariables
>;
export const CreateRoleDocument = gql`
  mutation createRole($id: ID!, $fields: RoleInput!) {
    createRole(id: $id, fields: $fields) {
      ...RoleFragment
    }
  }
  ${RoleFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<CreateRoleMutation, CreateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
  mutation deleteRole($id: ID!) {
    deleteRole(id: $id)
  }
` as unknown as DocumentNode<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const UserRolesDocument = gql`
  query userRoles($userId: ID!, $limit: Int!, $cursor: String) {
    userRoles(userId: $userId, limit: $limit, cursor: $cursor) {
      next
      roles {
        ...RoleFragment
        entity {
          __typename
          ...TankFragment
          ... on Tank {
            account {
              ...AccountFragment
              customer {
                ...CustomerFragment
              }
            }
          }
          ...AccountFragment
          ... on Account {
            customer {
              ...CustomerFragment
            }
          }
          ...CustomerFragment
        }
      }
    }
  }
  ${RoleFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${TankFragmentFragmentDoc}
  ${AccountFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${LinkedContactFragmentFragmentDoc}
` as unknown as DocumentNode<UserRolesQuery, UserRolesQueryVariables>;
export const RoleDocument = gql`
  query role($id: ID!) {
    role(id: $id) {
      ...RoleFragment
      entity {
        __typename
        ...TankFragment
        ...AccountFragment
        ...CustomerFragment
      }
    }
  }
  ${RoleFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${TankFragmentFragmentDoc}
  ${AccountFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${LinkedContactFragmentFragmentDoc}
` as unknown as DocumentNode<RoleQuery, RoleQueryVariables>;
export const RolesDocument = gql`
  query roles(
    $entityId: ID!
    $entityType: RoleEntityType!
    $limit: Int!
    $cursor: String
  ) {
    roles(
      entityId: $entityId
      entityType: $entityType
      limit: $limit
      cursor: $cursor
    ) {
      next
      roles {
        ...RoleFragment
      }
    }
  }
  ${RoleFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<RolesQuery, RolesQueryVariables>;
export const UpdateRoleDocument = gql`
  mutation updateRole($id: ID!, $permissions: [RolePermission!]!) {
    updateRole(id: $id, permissions: $permissions) {
      ...RoleFragment
    }
  }
  ${RoleFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const CreateScheduleDocument = gql`
  mutation createSchedule(
    $id: ID!
    $entityType: ScheduleEntityType!
    $entityId: ID!
    $fields: ScheduleInput!
  ) {
    createSchedule(
      id: $id
      entityType: $entityType
      entityId: $entityId
      fields: $fields
    ) {
      ...ScheduleFragment
    }
  }
  ${ScheduleFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  CreateScheduleMutation,
  CreateScheduleMutationVariables
>;
export const DeleteScheduleDocument = gql`
  mutation deleteSchedule($id: ID!) {
    deleteSchedule(id: $id)
  }
` as unknown as DocumentNode<
  DeleteScheduleMutation,
  DeleteScheduleMutationVariables
>;
export const ScheduleDocument = gql`
  query schedule($id: ID!) {
    schedule(id: $id) {
      ...ScheduleFragment
    }
  }
  ${ScheduleFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<ScheduleQuery, ScheduleQueryVariables>;
export const UpdateScheduleDocument = gql`
  mutation updateSchedule($id: ID!, $fields: ScheduleInput!) {
    updateSchedule(id: $id, fields: $fields) {
      ...ScheduleFragment
    }
  }
  ${ScheduleFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  UpdateScheduleMutation,
  UpdateScheduleMutationVariables
>;
export const TankAlertsDocument = gql`
  query tankAlerts($id: ID!, $limit: Int!, $cursor: String) {
    tank(id: $id) {
      alerts(limit: $limit, cursor: $cursor) {
        next
        alerts {
          id
          scenario
          expected
          actual
          updatedAt
        }
      }
    }
  }
` as unknown as DocumentNode<TankAlertsQuery, TankAlertsQueryVariables>;
export const TankDocument = gql`
  query tank($id: ID!) {
    tank(id: $id) {
      ...TankFragment
      levels(limit: 1) {
        levels {
          ...LevelFragment
        }
      }
      gauge {
        id
        name
      }
      account {
        id
        name
        product
      }
    }
    tankAccess(id: $id)
  }
  ${TankFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${LevelFragmentFragmentDoc}
` as unknown as DocumentNode<TankQuery, TankQueryVariables>;
export const TankLevelsDocument = gql`
  query tankLevels(
    $id: ID!
    $limit: Int!
    $startAt: DateTime
    $cursor: String
  ) {
    tank(id: $id) {
      levels(limit: $limit, cursor: $cursor, startAt: $startAt) {
        next
        levels {
          ...LevelFragment
        }
      }
    }
  }
  ${LevelFragmentFragmentDoc}
` as unknown as DocumentNode<TankLevelsQuery, TankLevelsQueryVariables>;
export const TanksDocument = gql`
  query tanks($limit: Int!, $cursor: String) {
    tanks(limit: $limit, cursor: $cursor) {
      next
      tanks {
        ...TankFragment
        levels(limit: 1) {
          levels {
            ...LevelFragment
          }
        }
        account {
          name
          product
          customer {
            name
          }
          plan {
            code
          }
        }
      }
    }
  }
  ${TankFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${LevelFragmentFragmentDoc}
` as unknown as DocumentNode<TanksQuery, TanksQueryVariables>;
export const TankRolesDocument = gql`
  query tankRoles($tankId: ID!, $limit: Int!, $cursor: String) {
    tankRoles(tankId: $tankId, limit: $limit, cursor: $cursor) {
      roles {
        user {
          ...ActorFragment
        }
      }
      next
    }
  }
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<TankRolesQuery, TankRolesQueryVariables>;
export const UpdateTankDocument = gql`
  mutation updateTank($id: ID!, $fields: TankInput!) {
    updateTank(id: $id, fields: $fields) {
      ...TankFragment
      levels(limit: 1) {
        levels {
          ...LevelFragment
        }
      }
      gauge {
        id
        name
      }
      account {
        id
        name
        product
      }
    }
  }
  ${TankFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${LevelFragmentFragmentDoc}
` as unknown as DocumentNode<UpdateTankMutation, UpdateTankMutationVariables>;
export const DeleteUserDocument = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
` as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>;
export const ImpersonateUserDocument = gql`
  mutation impersonateUser($id: ID!) {
    impersonateUser(id: $id)
  }
` as unknown as DocumentNode<
  ImpersonateUserMutation,
  ImpersonateUserMutationVariables
>;
export const UserDocument = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
` as unknown as DocumentNode<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
  query users($limit: Int!, $cursor: String) {
    users(limit: $limit, cursor: $cursor) {
      next
      users {
        ...UserFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
` as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const UpdateUserDocument = gql`
  mutation updateUser($id: ID!, $fields: UserInput!) {
    updateUser(id: $id, fields: $fields) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
` as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
