import { pluralize } from "@joy/shared-utils";

import { ListField, SlideContent, TextField } from "../../components";
import { useCreateCustomer } from "../../data";

export const CustomerCreateContent = () => {
  const { error, form, validators } = useCreateCustomer();

  return (
    <SlideContent
      title="Add customer"
      description="Get started by filling in the information below to create your new customer."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Creating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Create", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="name"
        validators={validators.name}
        children={(field) => (
          <TextField field={field} label="Customer name" autoFocus />
        )}
      />
      <form.Field
        name="interval"
        validators={validators.interval}
        children={(field) => (
          <ListField
            field={field}
            label="Billing Interval"
            options={[1, 12]}
            optionLabel={(o) => {
              if (o % 12 === 0)
                return pluralize(o / 12, "Yearly", "Yearly") || "";
              return pluralize(o, "Monthly", "Monthly") || "";
            }}
          />
        )}
      />
    </SlideContent>
  );
};
