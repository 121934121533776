import { useMatch } from "@tanstack/react-router";

import { Actions } from "../../components/actions";
import { InvoiceGenerateContent } from "./generate";

export const InvoicesActionPanel = () => {
  const { Modal, props } = useMatch({
    from: "/_super/invoices/$monthYear/",
    select: (s) => {
      const props = {
        month: s.params.monthYear.month,
        year: s.params.monthYear.year,
      };

      switch (s.search.action) {
        case "generate":
          return { Modal: InvoiceGenerateContent, props };
      }

      return {};
    },
  });

  return <Actions modal={Modal && <Modal {...props} />} />;
};
