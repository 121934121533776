import { IconDots } from "@tabler/icons-react";
import { CellContext } from "@tanstack/react-table";

import { tw } from "@joy/shared-utils";

import { ButtonProps } from "../button";
import { Menu } from "../menu";

export const menuCell = <D,>({
  items,
  onClick,
}: {
  items: (
    | (Omit<ButtonProps, "onClick" | "text"> & {
        onClick?: (data: D) => void;
        text: string | ((data: D) => string);
      })
    | undefined
  )[];
  onClick?: (data: D) => void;
}) => ({
  id: "menu",
  meta: { className: tw`w-0` },
  cell: (v: CellContext<D, unknown>) => (
    <Menu
      button={{
        icon: IconDots,
        kind: "menu",
        variant: "standard",
        onClick: () => onClick?.(v.row.original),
      }}
      items={items
        .filter((i) => !!i)
        .map(({ onClick, text, ...item }) => ({
          ...item,
          text: typeof text === "function" ? text(v.row.original) : text,
          onClick: () => onClick?.(v.row.original),
        }))}
    />
  ),
});
