import { standardSchemaValidator, useForm } from "@tanstack/react-form";
import { useState } from "react";
import { z } from "zod";

import { useValidators } from "../../hooks";
import { useAuth } from "./context";

const authValidation = {
  firstName: z.string().min(1, "Please enter your first name"),
  lastName: z.string().min(1, "Please enter your last name"),
  email: z.string().email("Please enter a valid email address"),
  password: z.string().min(1, "Please enter a password"),
  code: z.string().min(1, "Please enter your verification code"),
};

export const useAuthForm = ({
  onSubmit,
}: {
  onSubmit: (value: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    code: string;
  }) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const { client } = useAuth();
  const form = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      code: "",
    },
    onSubmit: async ({ value }) => {
      setError(undefined);
      setLoading(true);
      try {
        await onSubmit(value);
        setLoading(false);
      } catch (e) {
        setError(e as Error);
        setLoading(false);
      }
    },
    onSubmitInvalid: () => setError(undefined),
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(
    authValidation,
    form.state.submissionAttempts,
  );

  return {
    form,
    validators,
    loading,
    error,
    signIn: client.client!.signIn,
    signUp: client.client!.signUp,
  };
};
