import { IconEdit, IconTrashX } from "@tabler/icons-react";
import clsx from "clsx";
import { format } from "date-fns";
import { useState } from "react";

import { displayDays, pluralize } from "@joy/shared-utils";

import { Button, CellStack, cellVariants } from "../../components";
import { ScheduleFragmentFragment, useUpdateSchedule } from "../../data";
import { AccountScheduleFields } from "./schedule.fields";

export const AccountScheduleRow = ({
  schedule,
  onDelete,
}: {
  schedule: ScheduleFragmentFragment;
  onDelete: (schedule: ScheduleFragmentFragment) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { data, users, form, validators } = useUpdateSchedule(
    schedule.id,
    schedule,
  );

  let title;
  switch (data.interval) {
    case "Week":
      title = `Weekly, ${displayDays(data.days)}`;
      break;
    case "Month":
      title = `Monthly, on the ${format(new Date(1, 1, data.date || 1), "do")}`;
      break;
  }

  return (
    <>
      <tr>
        <td className="w-5" />
        <td className={clsx(cellVariants.base, cellVariants.padded)}>
          <CellStack
            value={title}
            detail={[
              [
                format(
                  new Date(
                    1,
                    1,
                    1,
                    data.startTime.hour,
                    data.startTime.minute,
                    0,
                    0,
                  ),
                  "h:mm aaa",
                ),
                data.timezone,
              ],
            ]}
            always
          />
        </td>
        <td className={clsx(cellVariants.base, cellVariants.padded)}>
          {pluralize(data.users.length, "1 recipient", "recipients")}
        </td>
        <td className={clsx(cellVariants.base, "w-0")}>
          <Button
            icon={IconEdit}
            kind="menu"
            variant="action"
            onClick={() => setIsEditing(true)}
          />
        </td>
        <td className={clsx(cellVariants.base, "w-0")}>
          <Button
            icon={IconTrashX}
            kind="menu"
            variant="danger"
            onClick={() => onDelete(schedule)}
          />
        </td>
        <td className="w-5" />
      </tr>
      {isEditing && (
        <tr>
          <td />
          <td colSpan={4} className={clsx(cellVariants.base)}>
            <AccountScheduleFields
              form={form}
              validators={validators}
              users={users}
              onClose={() => setIsEditing(false)}
            />
          </td>
          <td />
        </tr>
      )}
    </>
  );
};
