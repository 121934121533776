import { IconTrashX } from "@tabler/icons-react";

import { toTitle } from "@joy/shared-utils";

import {
  SlideContent,
  TablePage,
  cellKinds,
  dateCell,
  menuCell,
  stackCell,
} from "../../components";
import { invite, invitesQuery, useDeleteInvite } from "../../data";
import { useTable } from "../../hooks";

export const InvitesContent = () => {
  const { onDelete } = useDeleteInvite();
  const invites = useTable(invitesQuery(), {
    columnDefs: (c) => [
      c.accessor(
        "email",
        stackCell({
          header: "Email",
          detail: (invite) => [toTitle(invite.jlteam || "user")],
        }),
      ),
      c.accessor("jlteam", {
        header: "Role",
        cell: (c) => toTitle(c.getValue() || "user"),
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor(
        "createdAt",
        dateCell({
          header: "Sent",
        }),
      ),
      c.display(
        menuCell({
          items: [
            {
              icon: IconTrashX,
              text: "Revoke Invite",
              variant: "danger",
              onClick: (invite) => onDelete(invite.id),
            },
          ],
        }),
      ),
    ],
    word: invite,
  });

  return (
    <SlideContent title="Pending invites">
      <div className="px-4 sm:px-0">
        <TablePage {...invites} variant="slide" />
      </div>
    </SlideContent>
  );
};
