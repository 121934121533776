import { IconCurrencyDollar, IconFileInvoice } from "@tabler/icons-react";
import { standardSchemaValidator, useForm } from "@tanstack/react-form";
import {
  infiniteQueryOptions,
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { addMonths, format, getMonth, getYear } from "date-fns";
import { z } from "zod";

import { range } from "@joy/shared-utils";

import { useValidators } from "../../hooks";
import { Word } from "../helpers";
import { request, requestFn } from "./base";
import {
  DeleteInvoiceDocument,
  FeeFragmentFragment,
  GenerateInvoicesDocument,
  InvoiceDocument,
  InvoiceFeesDocument,
  InvoicesDocument,
  RegenerateInvoiceDocument,
  UpdateFeeDocument,
} from "./operations.generated";

export const invoice: Word = {
  icon: IconFileInvoice,
  article: "an",
  singular: "invoice",
  plural: "invoices",
};

export const fee: Word = {
  icon: IconCurrencyDollar,
  article: "a",
  singular: "fee",
  plural: "fees",
};

export const monthOptions = range(1, 12);

export const yearOptions = range(2023, new Date().getFullYear() + 2);

export const useInvoicesCalendar = (
  calendar: { month: number; year: number },
  setCalendar: (calendar: { month: number; year: number }) => void,
) => {
  return {
    month: {
      options: monthOptions,
      optionLabel: (month: number) =>
        format(new Date(calendar.year, month - 1), "MMM"),
      value: calendar.month,
      onChange: (month: number) => setCalendar({ ...calendar, month }),
    },
    year: {
      options: yearOptions,
      value: calendar.year,
      onChange: (year: number) => setCalendar({ ...calendar, year }),
    },
    adjust: (months: number) => {
      const date = addMonths(
        new Date(calendar.year, calendar.month - 1),
        months,
      );
      setCalendar({
        month: getMonth(date) + 1,
        year: getYear(date),
      });
    },
  };
};

export const invoicesQuery = ({
  month,
  year,
}: {
  month: number;
  year: number;
}) =>
  infiniteQueryOptions({
    queryKey: ["invoices", year, month],
    queryFn: ({ pageParam }) =>
      request(InvoicesDocument, {
        month,
        year,
        limit: 100,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.invoices.next,
    initialPageParam: "",
    select: (data) => data.pages.flatMap((p) => p.invoices.invoices),
  });

export const invoiceQuery = (id: string) =>
  queryOptions({
    queryKey: ["invoice", id],
    queryFn: () => request(InvoiceDocument, { id }),
    select: (data) => data.invoice!,
  });

export const invoiceFeesQuery = (id: string) =>
  infiniteQueryOptions({
    queryKey: ["invoice-fees", id],
    queryFn: ({ pageParam }) =>
      request(InvoiceFeesDocument, {
        id,
        limit: 500,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.invoice?.fees.next,
    initialPageParam: "",
    select: (data) => data.pages.flatMap((p) => p?.invoice?.fees?.fees || []),
  });

const generateInvoicesFn = requestFn(GenerateInvoicesDocument);

export const useGenerateInvoices = (month: number, year: number) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: generateInvoicesFn,
    onSuccess: async () => {
      queryClient.invalidateQueries(invoicesQuery({ month, year }));
      await navigate({
        to: "/invoices/$monthYear",
        params: { monthYear: { month, year } },
        replace: true,
      });
    },
  });

  return { error, isPending, onGenerate: () => mutateAsync({ month, year }) };
};

const regenerateInvoiceFn = requestFn(RegenerateInvoiceDocument);

export const useRegenerateInvoice = (invoiceId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(invoiceQuery(invoiceId));
  const queryClient = useQueryClient();
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: regenerateInvoiceFn,
    onSuccess: async (result, { id }) => {
      if (result.regenerateInvoice) {
        queryClient.setQueryData(
          invoicesQuery({ month: data.month, year: data.year }).queryKey,
          (existing) => {
            if (!existing) return undefined;

            return {
              ...existing,
              pages: existing.pages.map((p) => ({
                ...p,
                invoices: {
                  ...p.invoices,
                  invoices: p.invoices.invoices.map((i) =>
                    i.id === result.regenerateInvoice.id
                      ? result.regenerateInvoice
                      : i,
                  ),
                },
              })),
            };
          },
        );

        await navigate({
          to: "/invoices/$monthYear/$invoiceId",
          params: {
            monthYear: { month: data.month, year: data.year },
            invoiceId: result.regenerateInvoice.id,
          },
        });
        queryClient.invalidateQueries(invoiceQuery(id));
        queryClient.invalidateQueries(invoiceFeesQuery(id));
      }
    },
    onError: () => {
      queryClient.invalidateQueries(invoiceQuery(invoiceId));
      queryClient.invalidateQueries(invoiceFeesQuery(invoiceId));
    },
  });

  return {
    data,
    error,
    isPending,
    onDelete: () => mutateAsync({ id: invoiceId }),
  };
};

const updateFeeFn = requestFn(UpdateFeeDocument);

const validation = {
  amount: z.string().regex(/^\d+(\.\d\d?)?$/, "Must be a valid amount"),
};

export const useUpdateFee = (fee: FeeFragmentFragment) => {
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: updateFeeFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(
        invoiceFeesQuery(fee.invoiceId).queryKey,
        (existing) => {
          if (!existing) return undefined;

          return {
            ...existing,
            pages: existing.pages.map((p) => ({
              ...p,
              invoice: p.invoice
                ? {
                    ...p.invoice,
                    fees: {
                      ...p.invoice.fees,
                      fees: p.invoice.fees.fees.map((f) =>
                        f.id === result.updateFee.id ? result.updateFee : f,
                      ),
                    },
                  }
                : p.invoice,
            })),
          };
        },
      );
    },
  });

  const form = useForm({
    defaultValues: {
      discount: 0,
    },
    onSubmit: async ({ value }) => {
      await mutateAsync({
        id: fee.id,
        fields: {
          discount: value.discount,
        },
      });
    },
    onSubmitInvalid: () => reset(),
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { error, form, validators };
};

const deleteInvoiceFn = requestFn(DeleteInvoiceDocument);

export const useDeleteInvoice = (invoiceId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(invoiceQuery(invoiceId));
  const queryClient = useQueryClient();
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: deleteInvoiceFn,
    onSuccess: async (result, { id }) => {
      if (result.deleteInvoice) {
        queryClient.setQueryData(
          invoicesQuery({ month: data.month, year: data.year }).queryKey,
          (existing) => {
            if (!existing) return undefined;

            return {
              ...existing,
              pages: existing.pages.map((p) => ({
                ...p,
                invoices: {
                  ...p.invoices,
                  invoices: p.invoices.invoices.filter((u) => u.id !== id),
                },
              })),
            };
          },
        );

        await navigate({
          to: "/invoices/$monthYear",
          params: { monthYear: { month: data.month, year: data.year } },
        });
        queryClient.removeQueries(invoiceQuery(id));
      }
    },
  });

  return {
    data,
    error,
    isPending,
    onDelete: () => mutateAsync({ id: invoiceId }),
  };
};
