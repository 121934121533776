export const locale = Intl.DateTimeFormat().resolvedOptions().locale;

export const currentTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const timezoneOptions = Intl.supportedValuesOf("timeZone").map(
  (value) => ({
    value,
  }),
);

export const formatTimezone = (timezone: string, version: "long" | "short") =>
  new Intl.DateTimeFormat(locale, {
    timeZone: timezone,
    timeZoneName: version,
  })
    .formatToParts()
    .find((p) => p.type === "timeZoneName")?.value || timezone;
