import { DependencyList, useCallback, useEffect, useState } from "react";

type AsyncState = {
  mounted: boolean;
};

export const useAsync = <T>(
  fn: (props: AsyncState) => Promise<T>,
  deps: DependencyList,
) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T | null>(null);

  const memoFn = useCallback(fn, deps);

  useEffect(() => {
    const props: AsyncState = { mounted: true };
    (async () => {
      const result = await fn(props);
      if (props.mounted) {
        setData(result);
        setLoading(false);
      }
    })().catch(console.error);

    return () => {
      props.mounted = false;
    };
  }, [memoFn]);

  return [data, setData, loading] as const;
};
