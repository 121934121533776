import {
  FormatDistanceFn,
  FormatDistanceToken,
  formatDistanceToNow,
  intervalToDuration,
} from "date-fns";

import { specialChars } from "@joy/shared-utils";

const locales = {
  "1": {
    lessThanXSeconds: "{{count}}s",
    xSeconds: "{{count}}s",
    halfAMinute: "30s",
    lessThanXMinutes: "{{count}}m",
    xMinutes: "{{count}}m",
    aboutXHours: "{{count}}h",
    xHours: "{{count}}h",
    xDays: "{{count}}d",
    aboutXWeeks: "{{count}}w",
    xWeeks: "{{count}}w",
    aboutXMonths: "{{count}}m",
    xMonths: "{{count}}m",
    aboutXYears: "{{count}}y",
    xYears: "{{count}}y",
    overXYears: "{{count}}y",
    almostXYears: "{{count}}y",
  },
} satisfies Record<string, Record<FormatDistanceToken, string>>;

export const formatShort =
  (localeKey: keyof typeof locales): FormatDistanceFn =>
  (token, count, options) => {
    options = options || {};

    const result = locales[localeKey][token].replace(
      "{{count}}",
      count.toString(),
    );

    if (options.addSuffix) {
      if (options.comparison && options.comparison > 0) {
        return "in " + result;
      } else {
        return result + " ago";
      }
    }

    return result;
  };

export type TimeAgoOptions = {
  format?: keyof typeof locales;
  clean?: boolean;
  fallback?: string;
};

export const refreshGap = (date: string | Date | null | undefined) => {
  const duration = intervalToDuration({ start: date || "", end: new Date() });

  if (duration.years) return undefined;
  if (duration.months) return undefined;
  if (duration.days) return 1000 * 60 * 60;
  if (duration.hours) return 1000 * 60;
  if (duration.minutes) return 1000 * 10;
  if (duration.seconds) return 1000;
  if (date) return 1000;

  return undefined;
};

export const timeAgo = (
  date: string | Date | null | undefined,
  opts?: TimeAgoOptions,
) =>
  date
    ? formatDistanceToNow(date, {
        addSuffix: !opts?.clean,
        locale: opts?.format
          ? { formatDistance: formatShort(opts.format) }
          : undefined,
      })
    : opts?.fallback || specialChars.endash;
