import { IconDots } from "@tabler/icons-react";
import { useInfiniteQuery } from "@tanstack/react-query";
import clsx from "clsx";

import { mapOrEmpty, specialChars } from "@joy/shared-utils";

import {
  ComboField,
  Field,
  Label,
  Menu,
  cellKinds,
  cellVariants,
  tableParts,
  text,
} from "../../components";
import { UploadGaugesContentProps, customersQuery } from "../../data";

export const GaugeUploadItems = ({ form }: UploadGaugesContentProps) => {
  const customers = useInfiniteQuery(customersQuery());

  return (
    <>
      <form.Field
        name="customer"
        children={(field) => (
          <ComboField
            field={field}
            label="Customer"
            options={customers.data || []}
            accessors={[(o) => o.name]}
            optionKey={(o) => o.id}
            optionLabel={(o) => o.name}
            immediate
          />
        )}
      />
      <form.Field
        name="valid"
        mode="array"
        children={(list) => (
          <Field>
            <Label>Gauges to create ({list.state.value.length}):</Label>
            <table className={tableParts.table}>
              <thead>
                <tr>
                  <th className={clsx(text, cellKinds.title)}>IMEI</th>
                  <th className={clsx(text, cellKinds.title)}>Product</th>
                  <th className={clsx(text, cellKinds.title)}>Model</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {mapOrEmpty(
                  list.state.value,
                  (item, idx) => (
                    <tr key={item.id}>
                      <td className={clsx(cellVariants.base, "px-3 py-2")}>
                        {item.id}
                      </td>
                      <td className={clsx(cellVariants.base, "px-3 py-2")}>
                        {item.fields.technology || specialChars.endash} (
                        {item.fields.product || specialChars.endash})
                      </td>
                      <td className={clsx(cellVariants.base, "px-3 py-2")}>
                        {[item.fields.name, item.fields.model]
                          .filter(Boolean)
                          .join(" | ") || specialChars.endash}
                      </td>
                      <td className={clsx(cellVariants.base, "px-3 py-2")}>
                        <Menu
                          anchor="bottom end"
                          button={{
                            icon: IconDots,
                            kind: "menu",
                            variant: "standard",
                          }}
                          items={[
                            {
                              text: "Remove item",
                              onClick: () => list.removeValue(idx),
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  ),
                  <tr>
                    <td
                      colSpan={4}
                      className={clsx(cellVariants.base, "px-3 py-6")}
                    >
                      <p className="text-center font-semibold italic text-gray-400">
                        No gauges to create.
                      </p>
                    </td>
                  </tr>,
                )}
              </tbody>
            </table>
          </Field>
        )}
      />
      <form.Field
        name="invalid"
        mode="array"
        children={(list) =>
          !!list.state.value.length && (
            <Field>
              <Label>Invalid gauges ({list.state.value.length}):</Label>
              <table className={tableParts.table}>
                <thead>
                  <tr>
                    <th className={clsx(text, cellKinds.title)}>Row</th>
                    <th className={clsx(text, cellKinds.title)}>Error</th>
                  </tr>
                </thead>
                <tbody>
                  {list.state.value.map((item, idx) => (
                    <tr key={idx}>
                      <td className={clsx(cellVariants.base, "px-3 py-2")}>
                        {item.label}
                      </td>
                      <td
                        className={clsx(cellVariants.base, "px-3 text-red-700")}
                      >
                        {item.error}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Field>
          )
        }
      />
    </>
  );
};
