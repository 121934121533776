import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { displayPrice, pluralize } from "@joy/shared-utils";

import {
  StatsHeader,
  TablePage,
  actionCell,
  cellKinds,
  dateCell,
  stackCell,
} from "../../../../components";
import {
  listLoader,
  plan,
  plansQuery,
  useAuth,
  userName,
} from "../../../../data";
import { useTable } from "../../../../hooks";
import { PlansActionPanel } from "../../../../panels";

const validateSearch = z.object({
  action: z.enum(["create"]).optional(),
});

export const Route = createFileRoute("/_admin/library/plans/")({
  validateSearch,
  loader: async ({ context }) => listLoader(context.queryClient, plansQuery()),
  component: Component,
});

export function Component() {
  const { hasTeamPermission } = useAuth();
  const plans = useTable(plansQuery(), {
    columnDefs: (c) => [
      c.accessor(
        "code",
        stackCell({
          header: "Code",
          detail: (data) => [
            `${displayPrice(data.amount)} / ${data.model} / month`,
            [
              data.prepaid &&
                pluralize(data.prepaid, "1 prepaid month", "prepaid months"),
            ],
          ],
        }),
      ),
      c.accessor("description", {
        header: "Description",
        cell: (v) => v.getValue(),
        meta: { className: cellKinds.appears },
      }),
      c.accessor("amount", {
        header: "Amount",
        cell: (v) => displayPrice(v.getValue()),
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor("model", {
        header: "Model",
        cell: (v) => `Per ${v.getValue()}`,
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor("prepaid", {
        header: "Prepaid",
        cell: (v) => pluralize(v.getValue(), "1 month", "months"),
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor(
        "updatedAt",
        dateCell({
          header: "Updated",
        }),
      ),
      c.accessor("updatedBy", {
        header: "By",
        meta: { className: cellKinds.appears },
        cell: (v) => userName(v.getValue()),
      }),
      c.display(actionCell()),
    ],
    select: (planId) => ({
      to: "/library/plans/$planId",
      params: { planId },
    }),
    create: hasTeamPermission("super")
      ? { to: "/library/plans", search: { action: "create" }, replace: true }
      : undefined,
    word: plan,
  });

  return (
    <TablePage {...plans}>
      <StatsHeader stats={plans.stats} />
      <PlansActionPanel />
    </TablePage>
  );
}
