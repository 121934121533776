import { pluralize } from "@joy/shared-utils";

import {
  ListField,
  LookupField,
  SlideContent,
  TextField,
} from "../../components";
import { linkedContactsQuery, useUpdateCustomer } from "../../data";

export const CustomerEditContent = ({ customerId }: { customerId: string }) => {
  const { data, error, form, validators } = useUpdateCustomer(customerId);

  return (
    <SlideContent
      title={`Update ${data.name}`}
      description="Update and save the customer information below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="name"
        validators={validators.name}
        children={(field) => (
          <TextField field={field} label="Customer name" autoFocus />
        )}
      />
      <form.Field
        name="interval"
        validators={validators.interval}
        children={(field) => (
          <ListField
            field={field}
            label="Billing Interval"
            options={[1, 12]}
            optionLabel={(o) => {
              if (o % 12 === 0)
                return pluralize(o / 12, "Yearly", "Yearly") || "";
              return pluralize(o, "Monthly", "Monthly") || "";
            }}
          />
        )}
      />
      <form.Field
        name="linkedContact"
        children={(field) => (
          <LookupField
            field={field}
            label="Linked Contact"
            lookup={linkedContactsQuery}
            optionKey={(o) => o.id}
            optionLabel={(o) =>
              `${o.name}${o.accountNumber ? ` (${o.accountNumber})` : ""}`
            }
          />
        )}
      />
    </SlideContent>
  );
};
