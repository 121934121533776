import {
  IconArrowNarrowRight,
  IconEdit,
  IconTrashX,
} from "@tabler/icons-react";
import { NavigateOptions, useNavigate } from "@tanstack/react-router";
import { ColumnHelper } from "@tanstack/react-table";

import { specialChars } from "@joy/shared-utils";

import { cellKinds, checkCell, menuCell, stackCell } from "../../components";
import { RoleItem, RolePermission, useAuth, usePrefetchRole } from "../../data";

export const permissionColumns = <V extends { permissions: RolePermission[] }>(
  c: ColumnHelper<V>,
) => [
  c.accessor(
    (v) => v.permissions.includes("read"),
    checkCell({ header: "View" }),
  ),
  c.accessor(
    (v) => v.permissions.includes("write"),
    checkCell({ header: "Edit" }),
  ),
  c.accessor(
    (v) => v.permissions.includes("install"),
    checkCell({ header: "Install" }),
  ),
  c.accessor(
    (v) => v.permissions.includes("manage"),
    checkCell({ header: "Manage" }),
  ),
  c.accessor(
    (v) => {
      const value = v.permissions;
      if (value.includes("manage")) return "Manage";
      if (value.includes("install") && value.includes("write"))
        return "Install & Edit";
      if (value.includes("install")) return "Install";
      if (value.includes("write")) return "Edit";
      if (value.includes("read")) return "View";

      return specialChars.endash;
    },
    {
      id: "permission",
      header: "Permission",
      meta: {
        className: cellKinds.hiddenxl,
      },
    },
  ),
];

export const roleColumns =
  ({
    prefetchRole,
    hasTeamPermission,
    navigate,
    current,
    permissions,
  }: {
    prefetchRole: ReturnType<typeof usePrefetchRole>;
    hasTeamPermission: ReturnType<typeof useAuth>["hasTeamPermission"];
    navigate: ReturnType<typeof useNavigate>;
    current: NavigateOptions;
    permissions: RolePermission[];
  }) =>
  (c: ColumnHelper<RoleItem>) => [
    c.accessor(
      "user.name",
      stackCell({
        header: "Name",
        detail: (data) => [data.user.email],
        always: true,
      }),
    ),
    ...permissionColumns(c),
    c.display(
      menuCell({
        onClick: ({ id }) => prefetchRole(id),
        items: [
          hasTeamPermission("admin")
            ? {
                icon: IconArrowNarrowRight,
                text: "View User",
                variant: "standard",
                onClick: (r) =>
                  navigate({
                    to: "/users/$userId",
                    params: { userId: r.user.id },
                  }),
              }
            : undefined,
          permissions.includes("manage")
            ? {
                icon: IconEdit,
                text: "Update Permissions",
                variant: "standard",
                onClick: (r) =>
                  navigate({
                    ...current,
                    search: { action: "edit", role: r.id },
                    replace: true,
                  }),
              }
            : undefined,
          permissions.includes("manage")
            ? {
                icon: IconTrashX,
                text: "Remove Role",
                variant: "danger",
                onClick: (r) =>
                  navigate({
                    ...current,
                    search: { action: "delete", role: r.id },
                    replace: true,
                  }),
              }
            : undefined,
        ],
      }),
    ),
  ];
