import { IconMail, IconUserScreen } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { backgrounds } from "../../../assets";
import { ItemHeader, Tabs } from "../../../components";
import { singleItemLoader, userName, userQuery } from "../../../data";
import {
  UserActionPanel,
  UserEmailsPanel,
  UserInfoPanel,
  UserRolesPanel,
} from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["add", "edit", "delete", "impersonate"]).optional(),
  entity: z.enum(["tank", "account", "customer"]).optional(),
  role: z.string().optional(),
});

export const Route = createFileRoute("/_admin/users/$userId")({
  validateSearch,
  loader: async ({ context, params }) =>
    singleItemLoader(context.queryClient, userQuery(params.userId)),
  head: ({ loaderData }) => ({ meta: [{ title: userName(loaderData) }] }),
  component: Component,
});

export function Component() {
  const userId = Route.useParams({ select: (p) => p.userId });
  const { data } = useSuspenseQuery(userQuery(userId));

  return (
    <>
      <ItemHeader
        image={data.picture || backgrounds.userFallback}
        title={userName(data, { hideEmail: true })}
        subtitle={data.email}
      />
      <Tabs
        name="user"
        tabs={[
          {
            name: "Permissions",
            icon: IconUserScreen,
            panel: <UserRolesPanel userId={userId} />,
          },
          {
            name: "Emails",
            icon: IconMail,
            panel: <UserEmailsPanel userId={userId} />,
          },
        ]}
        info={<UserInfoPanel />}
      />
      <UserActionPanel />
    </>
  );
}
