import {
  Menu as HeadlessMenu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuItemsProps,
} from "@headlessui/react";
import clsx from "clsx";

import { Button, ButtonProps } from "./button";

export type MenuProps = {
  button: ButtonProps;
  items: ButtonProps[] | undefined;
  anchor?: MenuItemsProps["anchor"];
  className?: string;
};

export const Menu = ({
  button,
  items,
  anchor = { to: "bottom start", padding: 10 },
  className,
}: MenuProps) =>
  items && items.length >= 1 ? (
    <HeadlessMenu>
      <MenuButton as={Button} {...button} />
      <MenuItems
        transition
        anchor={anchor}
        className={clsx(
          "z-10 mt-1 rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in",
          className,
        )}
      >
        {items.map((item) => (
          <MenuItem key={item.text}>
            <Button
              kind="menu"
              className="w-full justify-start rounded-none p-2 sm:rounded-none"
              {...item}
            />
          </MenuItem>
        ))}
      </MenuItems>
    </HeadlessMenu>
  ) : null;
