import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { toTitle } from "@joy/shared-utils";

import {
  StatsHeader,
  TablePage,
  actionCell,
  cellKinds,
  dateCell,
  stackCell,
} from "../../../components";
import { invitesQuery, listLoader, user, usersQuery } from "../../../data";
import { useTable } from "../../../hooks";
import { UsersActionPanel } from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["invite", "invites"]).optional(),
  inviteId: z.string().optional(),
});

export const Route = createFileRoute("/_admin/users/")({
  validateSearch,
  loader: async ({ context }) => {
    await listLoader(context.queryClient, invitesQuery());
    await listLoader(context.queryClient, usersQuery());
  },
  component: Component,
});

export function Component() {
  const navigate = Route.useNavigate();
  const invites = useSuspenseInfiniteQuery(invitesQuery());
  const users = useTable(usersQuery(), {
    columnDefs: (c) => [
      c.accessor(
        "name",
        stackCell({
          header: "Name",
          detail: (data) => [[toTitle(data.jlteam || "user"), data.email]],
        }),
      ),
      c.accessor("jlteam", {
        header: "Role",
        cell: (c) => toTitle(c.getValue() || "user"),
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor("email", {
        header: "Email",
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor(
        "createdAt",
        dateCell({
          header: "Created",
          kind: "appears",
        }),
      ),
      c.accessor(
        "lastActiveAt",
        dateCell({
          header: "Active",
        }),
      ),
      c.display(actionCell()),
    ],
    select: (userId) => ({ to: "/users/$userId", params: { userId } }),
    create: { to: "/users", search: { action: "invite" }, replace: true },
    word: user,
  });

  return (
    <TablePage {...users}>
      <StatsHeader
        stats={[
          ...users.stats,
          {
            label: "Pending invites",
            value: `${invites.data?.length || 0}${invites.hasNextPage ? "+" : ""}`,
            onClick: () =>
              navigate({ search: { action: "invites" }, replace: true }),
          },
        ]}
      />
      <UsersActionPanel />
    </TablePage>
  );
}
