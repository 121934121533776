import { Table as TanstackTable, flexRender } from "@tanstack/react-table";
import clsx from "clsx";
import { RefObject } from "react";

import { tw } from "@joy/shared-utils";

import { EmptyTable, EmptyTableProps } from "./cells/empty";
import { Progress } from "./navigation";
import { tableVariants } from "./table";

export type ListProps<I> = {
  table: TanstackTable<I>;
  container: {
    ref: RefObject<HTMLDivElement>;
    itemRef: RefObject<HTMLDivElement>;
    className?: string;
  };
  variant?: keyof typeof tableVariants;
  updating?: boolean;
  loading?: boolean;
  empty?: EmptyTableProps;
};

const listParts = {
  row: tw`relative mx-2 my-3 flex flex-wrap items-center gap-x-1 gap-y-3 border-b border-b-gray-200 p-1`,
};

export const List = <I,>({
  table,
  container,
  variant = "card",
  updating,
  loading,
  empty,
}: ListProps<I>) => {
  return (
    <div
      ref={container.ref}
      className={clsx(tableVariants[variant], container.className, "relative")}
    >
      <Progress
        position="top-0"
        background="bg-gradient-to-b from-sky-600/40"
        show={updating}
      />
      {table.getRowModel().rows.map((row) => (
        <div
          key={row.id}
          onClick={row.getToggleSelectedHandler()}
          className={clsx(
            listParts.row,
            row.getCanSelect() && "cursor-pointer",
          )}
        >
          {row.getVisibleCells().map((cell) => (
            <div
              key={cell.id}
              className={clsx(cell.column.columnDef.meta?.className)}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
          ))}
        </div>
      ))}
      {/* loading items */}
      {!loading && !!empty && table.getRowCount() === 0 && (
        <div className="flex flex-col items-center justify-center px-2 py-8">
          <EmptyTable {...empty} />
        </div>
      )}
      <div className="h-5" ref={container.itemRef} />
    </div>
  );
};
