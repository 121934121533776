import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { clsx } from "clsx";
import { z } from "zod";

import { specialChars } from "@joy/shared-utils";

import {
  Button,
  ListInput,
  StatsHeader,
  TablePage,
  cellKinds,
  pageParts,
  stackCell,
} from "../../../components";
import {
  invoice,
  invoicesQuery,
  listLoader,
  useInvoicesCalendar,
} from "../../../data";
import { useTable } from "../../../hooks";
import { InvoicesActionPanel } from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["generate"]).optional(),
});

export const Route = createFileRoute("/_super/invoices/$monthYear/")({
  validateSearch,
  loader: async ({ params, context }) =>
    listLoader(context.queryClient, invoicesQuery(params.monthYear)),
  component: Component,
});

export function Component() {
  const monthYear = Route.useParams({ select: (p) => p.monthYear });
  const navigate = Route.useNavigate();
  const { month, year, adjust } = useInvoicesCalendar(monthYear, (updated) =>
    navigate({ params: { monthYear: updated } }),
  );
  const invoices = useTable(invoicesQuery(monthYear), {
    columnDefs: (c) => [
      c.accessor(
        "customer.name",
        stackCell({
          header: "Customer",
          detail: (data) => [
            [
              data.linkedInvoice?.number || specialChars.endash,
              data.linkedInvoice?.status,
            ],
          ],
        }),
      ),
      c.accessor((i) => i.linkedInvoice?.number, {
        id: "invoice",
        header: "Invoice",
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor((c) => ({ count: c.fees.fees.length, more: !!c.fees.next }), {
        id: "count",
        header: "Line Items",
        cell: (c) => `${c.getValue().count}${c.getValue().more ? "+" : ""}`,
      }),
    ],
    select: (invoiceId) => ({
      to: "/invoices/$monthYear/$invoiceId",
      params: { invoiceId },
    }),
    create: {
      to: "/invoices/$monthYear",
      params: { monthYear },
      search: { action: "generate" },
      replace: true,
      text: "Generate Invoices",
    },
    word: invoice,
  });

  return (
    <TablePage
      {...invoices}
      actions={
        <div className={clsx(pageParts.actionItems, "justify-end")}>
          <Button
            icon={IconChevronLeft}
            kind="menu"
            variant="standard"
            onClick={() => adjust(-1)}
          />
          <div>
            <ListInput className="min-w-[86px]" {...month} />
          </div>
          <div>
            <ListInput className="min-w-[92px]" {...year} />
          </div>
          <Button
            icon={IconChevronRight}
            kind="menu"
            variant="standard"
            onClick={() => adjust(1)}
          />
        </div>
      }
    >
      <StatsHeader stats={invoices.stats} />
      <InvoicesActionPanel />
    </TablePage>
  );
}
