import { IconAlertTriangle } from "@tabler/icons-react";
import { format } from "date-fns";

import { ModalContent } from "../../components";
import { useRegenerateInvoice } from "../../data";

export const InvoiceRegenerateContent = ({
  invoiceId,
}: {
  invoiceId: string;
}) => {
  const { data, error, isPending, onDelete } = useRegenerateInvoice(invoiceId);

  return (
    <ModalContent
      icon={IconAlertTriangle}
      variant="warning"
      title={`Regenerate invoice for ${data.customer.name} - ${format(
        new Date(data.year, data.month - 1, 1),
        "MMMM yyyy",
      )}`}
      description={`Are you sure you want to regenerate ${data.linkedInvoice ? `invoice ${data.linkedInvoice.number}` : "this invoice"}? All existing fees will be deleted and new fees will be generated. This action cannot be undone.`}
      error={error}
      onSubmit={onDelete}
      loading={{ show: isPending, text: "Regenerating..." }}
      buttons={[
        {
          text: "Regenerate",
          type: "submit",
          variant: "action",
          autoFocus: true,
        },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
