import { IconAlertTriangle } from "@tabler/icons-react";
import { useContext } from "react";

import { ModalContent, ModalContext } from "../../components";
import { ScheduleFragmentFragment, useDeleteSchedule } from "../../data";

export const ScheduleDeleteContent = ({
  schedule,
}: {
  schedule: ScheduleFragmentFragment;
}) => {
  const { onClose } = useContext(ModalContext);
  const { error, isPending, onDelete } = useDeleteSchedule(
    schedule.id,
    schedule,
  );

  return (
    <ModalContent
      icon={IconAlertTriangle}
      variant="danger"
      title={`Delete scheduled email?`}
      description={`Are you sure you want to delete this scheduled email? This action cannot be undone.`}
      error={error}
      onSubmit={async () => {
        await onDelete();
        onClose();
      }}
      loading={{ show: isPending, text: "Deleting..." }}
      buttons={[
        {
          text: "Delete",
          type: "submit",
          variant: "danger",
          autoFocus: true,
        },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
