import { LinkProps, useNavigate } from "@tanstack/react-router";
import clsx from "clsx";
import { ReactNode } from "react";

import { tw } from "@joy/shared-utils";

import { fieldParts } from "./parts";

export const formKinds = {
  column: tw`mt-4 flex flex-col gap-4`,
  full: tw`flex h-full flex-col overflow-y-scroll`,
  group: fieldParts.group,
};

export const Form = ({
  className,
  children,
  kind = "column",
  hidden,
  onSubmit,
  onReset,
  afterReset,
}: {
  children: ReactNode;
  className?: string;
  kind?: keyof typeof formKinds;
  hidden?: boolean;
  onSubmit?: () => void;
  onReset?: () => void;
  afterReset?: LinkProps;
}) => {
  const navigate = useNavigate();

  if (!onSubmit)
    return (
      <div aria-hidden={hidden} className={clsx(formKinds[kind], className)}>
        {children}
      </div>
    );

  return (
    <form
      aria-hidden={hidden}
      className={clsx(formKinds[kind], className)}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit?.();
      }}
      onReset={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onReset) onReset();
        if (afterReset) navigate(afterReset);
      }}
      noValidate
    >
      {children}
    </form>
  );
};
