import { IconCalendarPlus } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Button, Modal, SlideContent, tableParts } from "../../components";
import {
  ScheduleFragmentFragment,
  accountQuery,
  useCreateSchedule,
} from "../../data";
import { ScheduleDeleteContent } from "./schedule.delete";
import { AccountScheduleFields } from "./schedule.fields";
import { AccountScheduleRow } from "./schedule.row";

export const AccountScheduleContent = ({
  accountId,
}: {
  accountId: string;
}) => {
  const { data } = useSuspenseQuery(accountQuery(accountId));
  const [isAdding, setIsAdding] = useState(data.schedules.length === 0);
  const [isDeleting, setIsDeleting] = useState<ScheduleFragmentFragment>();
  const { form, validators, users } = useCreateSchedule({
    entityId: accountId,
    entityType: "account",
  });

  return (
    <>
      <Modal show={!!isDeleting} onClose={() => setIsDeleting(undefined)}>
        {isDeleting && <ScheduleDeleteContent schedule={isDeleting} />}
      </Modal>
      <SlideContent
        title={`Update Schedules for ${data.name}`}
        description="Update and save the email schedules below."
      >
        <table className={tableParts.table}>
          <tbody>
            {data.schedules.map((schedule) => (
              <AccountScheduleRow
                key={schedule.id}
                schedule={schedule}
                onDelete={setIsDeleting}
              />
            ))}
            <tr>
              <td />
              <td colSpan={4} className="py-2">
                {isAdding ? (
                  <AccountScheduleFields
                    form={form}
                    validators={validators}
                    users={users}
                    onClose={() => setIsAdding(false)}
                  />
                ) : (
                  <Button
                    icon={IconCalendarPlus}
                    kind="menu"
                    variant="action"
                    text="Add scheduled email"
                    onClick={() => setIsAdding(true)}
                  />
                )}
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </SlideContent>
    </>
  );
};
