import { IconFileInvoice } from "@tabler/icons-react";
import { format } from "date-fns";

import { ModalContent } from "../../components";
import { useGenerateInvoices } from "../../data";

export const InvoiceGenerateContent = ({
  month,
  year,
}: {
  month: number;
  year: number;
}) => {
  const monthDisplay = format(new Date(year, month - 1), "MMMM yyyy");
  const { error, isPending, onGenerate } = useGenerateInvoices(month, year);

  return (
    <ModalContent
      icon={IconFileInvoice}
      variant="action"
      title={`Generate invoices for ${monthDisplay}`}
      description={`New invoices will be generated for all active accounts in ${monthDisplay}. Existing invoices will not be changed. To do so, select the specific invoice.`}
      error={error}
      onSubmit={onGenerate}
      loading={{ show: isPending, text: "Generating..." }}
      buttons={[
        {
          text: "Generate",
          type: "submit",
          variant: "action",
          autoFocus: true,
        },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
