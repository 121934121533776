import { IconReceiptDollar } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { format } from "date-fns";
import { z } from "zod";

import { EntityUpdated, ItemHeader, NotFound, Tabs } from "../../../components";
import { invoiceQuery, singleItemLoader } from "../../../data";
import {
  InvoiceActionPanel,
  InvoiceFeesPanel,
  InvoiceInfoPanel,
} from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["delete", "regenerate"]).optional(),
});

export const Route = createFileRoute("/_super/invoices/$monthYear/$invoiceId")({
  validateSearch,
  loader: ({ context, params }) =>
    singleItemLoader(context.queryClient, invoiceQuery(params.invoiceId)),
  component: Component,
  notFoundComponent: NotFoundComponent,
  head: ({ loaderData }) => ({
    meta: [
      {
        title: loaderData?.customer.name,
      },
    ],
  }),
});

function NotFoundComponent() {
  const params = Route.useParams();

  return (
    <NotFound
      item="Invoice"
      action={{
        to: "/invoices/$monthYear",
        params: { monthYear: params.monthYear },
      }}
      actionLabel="View invoices"
    />
  );
}

function Component() {
  const invoiceId = Route.useParams({ select: (p) => p.invoiceId });
  const { data } = useSuspenseQuery(invoiceQuery(invoiceId));

  return (
    <>
      <ItemHeader
        title={[
          data.linkedInvoice?.number,
          data.customer.name,
          format(new Date(data.year, data.month - 1), "MMMM yyyy"),
        ]
          .filter(Boolean)
          .join(" - ")}
        subtitle={<EntityUpdated {...data} />}
      />
      <Tabs
        name="invoice"
        tabs={[
          {
            name: "Items",
            icon: IconReceiptDollar,
            panel: <InvoiceFeesPanel invoiceId={invoiceId} />,
          },
        ]}
        info={<InvoiceInfoPanel invoiceId={invoiceId} />}
      />
      <InvoiceActionPanel />
    </>
  );
}
