import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { z } from "zod";

import {
  Field,
  Label,
  ListInput,
  TextAreaInput,
  Tree,
  formKinds,
  pageParts,
} from "../../../components";
import { parseQuery } from "../../../data";
import { useDebounce } from "../../../hooks";

const parseTypes = z.enum(["tektcp"]);

const validateSearch = z.object({
  type: parseTypes.optional().default("tektcp"),
  data: z.string().optional(),
});

export const Route = createFileRoute("/_god/dev/parse")({
  validateSearch,
  component: Component,
  head: () => ({ meta: [{ title: "Parsers" }] }),
});

export function Component() {
  const navigate = Route.useNavigate();
  const { data, type } = Route.useSearch();
  const [value, setValue] = useState(data);

  const debounced = useDebounce(() => value, 500, [value]);
  const parsed = useQuery(parseQuery(type, debounced));

  return (
    <div className={pageParts.page}>
      <div className={pageParts.contents}>
        <div className={formKinds.column}>
          <Field>
            <Label>Parser</Label>
            <ListInput
              className="w-80 max-w-full"
              options={parseTypes.options}
              value={type}
              onChange={(e) => navigate({ search: { type: e }, replace: true })}
            />
          </Field>
          <Field>
            <Label>Data</Label>
            <TextAreaInput
              value={value}
              placeholder="Enter data..."
              onChange={(e) => setValue(e.currentTarget.value)}
            />
          </Field>
          <Field>
            <Label>Result</Label>
            <Tree
              className="text-base"
              data={parsed.data?.parse}
              initiallyExpanded
            />
          </Field>
        </div>
      </div>
    </div>
  );
}
