import { IconUserBolt } from "@tabler/icons-react";

import { ModalContent } from "../../components";
import { useImpersonateUser, userName } from "../../data";

export const UserImpersonateContent = ({ userId }: { userId: string }) => {
  const { data, error, isPending, onImpersonate } = useImpersonateUser(userId);

  return (
    <ModalContent
      icon={IconUserBolt}
      variant="warning"
      title={`Impersonate "${userName(data)}"?`}
      description={`Are you sure you want to impersonate "${userName(data)}"? Any actions you take will be attributed to them.`}
      error={error}
      onSubmit={onImpersonate}
      loading={{ show: isPending, text: "Impersonating..." }}
      buttons={[
        {
          text: "Impersonate",
          type: "submit",
          variant: "action",
          autoFocus: true,
        },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    />
  );
};
