import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { z } from "zod";

import { technologyIcons } from "../../../assets";
import {
  ListInput,
  StatsHeader,
  TablePage,
  actionCell,
  cellKinds,
  dateCell,
  imageCell,
  pageParts,
  stackCell,
} from "../../../components";
import {
  gauge,
  gaugesQuery,
  listLoader,
  useAuth,
  userName,
} from "../../../data";
import { useTable } from "../../../hooks";
import { GaugesActionPanel } from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["upload"]).optional(),
});

export const Route = createFileRoute("/_authenticated/gauges/")({
  validateSearch,
  loader: async ({ context }) => listLoader(context.queryClient, gaugesQuery()),
  component: Component,
});

const assignStateOptions = [
  "all",
  "unassigned",
  "customer",
  "account",
] as const;
const assignStateLabel: Record<AssignState, string> = {
  all: "All",
  unassigned: "Unassigned",
  customer: "Customer",
  account: "Account",
};
type AssignState = (typeof assignStateOptions)[number];

export function Component() {
  const { hasTeamPermission } = useAuth();
  const gauges = useTable(gaugesQuery(), {
    columnDefs: (c) => [
      c.accessor(
        "technology",
        imageCell({ header: "", icons: technologyIcons }),
      ),
      c.accessor(
        "name",
        stackCell({
          header: "Name",
          detail: (g) => [[g.account?.name || "Unassigned", g.customer?.name]],
        }),
      ),
      c.accessor((g) => g.account?.name, {
        id: "account_name",
        header: "Account",
        meta: {
          className: clsx(
            cellKinds.appearsxl,
            "divide-x divide-gray-400 truncate",
          ),
        },
        filterFn: (row, _, filterValue: AssignState) => {
          if (filterValue === "unassigned") return !row.original.customer;
          if (filterValue === "customer")
            return !!row.original.customer && !row.original.account;
          if (filterValue === "account") return !!row.original.account;

          return true;
        },
        cell: (v) =>
          [v.getValue() || "Unassigned", v.row.original.customer?.name]
            .filter((i) => !!i)
            .map((i, idx) => (
              <span className="px-1 first-of-type:pl-0" key={idx}>
                {i}
              </span>
            )),
      }),
      c.accessor("updatedAt", dateCell({ header: "Updated" })),
      c.accessor("updatedBy", {
        header: "By",
        meta: { className: cellKinds.appears },
        cell: (v) => userName(v.getValue()),
      }),
      c.display(actionCell()),
    ],
    select: (gaugeId) => ({
      to: "/gauges/$gaugeId",
      params: { gaugeId },
    }),
    initialVisibility: {
      customer_name: hasTeamPermission("admin"),
    },
    create: hasTeamPermission("admin")
      ? {
          to: "/gauges",
          search: { action: "upload" },
          replace: true,
          text: "Upload gauges",
        }
      : undefined,
    word: gauge,
  });

  return (
    <TablePage
      {...gauges}
      actions={
        hasTeamPermission("admin") ? (
          <div className={clsx(pageParts.actionItems, "justify-end")}>
            <div>
              <ListInput
                className="min-w-44"
                options={[...assignStateOptions]}
                optionLabel={(o) => assignStateLabel[o]}
                value={(gauges.column.value[0]?.value as AssignState) || "all"}
                onChange={(o) =>
                  gauges.column.onChange([{ id: "account_name", value: o }])
                }
              />
            </div>
          </div>
        ) : null
      }
    >
      <StatsHeader stats={gauges.stats} />
      <GaugesActionPanel />
    </TablePage>
  );
}
