import { jsonrepair } from "jsonrepair";

export const toTitle = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1).toLocaleLowerCase()}`;

export const pluralize = (
  count: number | null | undefined,
  single: string,
  plural: string = `${single}s`,
) => {
  if (count === null || count === undefined) return undefined;
  if (count === 1) return single;
  return `${count} ${plural}`;
};

export const extractJson = (content: string) => {
  const start = content.indexOf("{");
  if (start === -1) return { content };

  let levels = 1;
  let position = start + 1;
  while (position < content.length) {
    if (content[position] === "{") levels++;
    if (content[position] === "}") levels--;
    position++;

    if (levels === 0) break;
  }
  const json = content.slice(start, position);

  try {
    const object = JSON.parse(jsonrepair(json));
    return { content: content.replace(json, "").trim(), object };
  } catch {
    return { content };
  }
};

export const specialChars = {
  dot: "\u22C5",
  endash: "\u2013",
  plusMinus: "\u00B1",
  nbsp: "\u00A0",
  masked: "\u2022",
  multiply: "\u00D7",
};

export const tw = (strings: TemplateStringsArray, ...values: unknown[]) =>
  String.raw({ raw: strings }, ...values);
