import { IconTemperatureSun } from "@tabler/icons-react";
import { useInfiniteQuery, useSuspenseQuery } from "@tanstack/react-query";
import { formatDate, subDays } from "date-fns";

import {
  displayPercent,
  displayTemperature,
  displayVolume,
  isNotNullOrUndefined,
  specialChars,
} from "@joy/shared-utils";

import { Box, Chart, Gauge, displayBattery, pageParts } from "../../components";
import { IndicatorRow } from "../../components/indicators";
import { tankLevelStyle, tankLevelsQuery, tankQuery } from "../../data";

export const TankLevelsPanel = ({ tankId }: { tankId: string }) => {
  const { data, isLoading } = useInfiniteQuery(tankLevelsQuery(tankId, 14));
  const {
    data: { level, tankProduct, unit, alertLevels },
  } = useSuspenseQuery(tankQuery(tankId));
  const style = tankLevelStyle(level?.percent, tankProduct);

  return (
    <div className={pageParts.page}>
      <Box header={{ title: "Current Status" }}>
        <IndicatorRow
          indicators={[
            isNotNullOrUndefined(level?.temperature)
              ? {
                  label: "Temperature",
                  icon: <IconTemperatureSun />,
                  value:
                    displayTemperature(level.temperature) ||
                    specialChars.endash,
                }
              : undefined,
            isNotNullOrUndefined(level?.voltage)
              ? {
                  label: "Battery",
                  icon: displayBattery(level.voltage),
                  value: displayPercent(level.voltage) || specialChars.endash,
                }
              : undefined,
          ]}
        />
        <Gauge
          value={level?.value}
          maximum={level?.maximum}
          unit={unit}
          alertLevels={alertLevels}
          {...style}
        />
      </Box>
      <Box header={{ title: "History" }}>
        <Chart
          xScale={{
            type: "time",
            clamp: true,
          }}
          yScale={{ type: "linear", clamp: true, domain: [0, 100] }}
          yFormat={(d) => `${d}%`}
          data={data}
          loading={isLoading}
          mock={() => {
            const end = new Date();
            return [
              {
                id: "",
                maximum: 0,
                percent: 20,
                value: 0,
                temperature: 13,
                updatedAt: subDays(end, 14),
              },
              {
                id: "",
                maximum: 0,
                percent: 60,
                value: 0,
                temperature: 15,
                updatedAt: subDays(end, 11),
              },
              {
                id: "",
                maximum: 0,
                percent: 50,
                value: 0,
                temperature: 17,
                updatedAt: subDays(end, 6),
              },
              {
                id: "",
                maximum: 0,
                percent: 85,
                value: 0,
                temperature: 18,
                updatedAt: subDays(end, 2),
              },
              {
                id: "",
                maximum: 0,
                percent: 80,
                value: 0,
                temperature: 18,
                updatedAt: end,
              },
            ];
          }}
          series={[
            {
              key: "volume",
              label: "Volume",
              variant: "emerald",
              default: true,
              type: "area",
              xAccessor: (d) => new Date(d.updatedAt),
              yAccessor: (d) => d.percent,
            },
            data?.[0]?.temperature
              ? {
                  key: "temperature",
                  label: "Temperature",
                  variant: "red",
                  default: false,
                  type: "line",
                  xAccessor: (d) => new Date(d.updatedAt),
                  yAccessor: (d) => d.temperature,
                }
              : undefined,
            data?.[0]?.voltage
              ? {
                  key: "battery",
                  label: "Battery",
                  variant: "sky",
                  default: false,
                  type: "line",
                  xAccessor: (d) => new Date(d.updatedAt),
                  yAccessor: (d) => d.voltage,
                }
              : undefined,
          ]}
          tooltip={({ item }) => (
            <>
              <p className="font-semibold">
                {item.updatedAt
                  ? formatDate(item.updatedAt, "LLLL d, haaa")
                  : specialChars.endash}
              </p>
              <p className="divide-x divide-gray-300">
                <span className="pr-1 font-semibold">
                  {displayPercent(item.percent)}
                </span>
                <span className="pl-1">{displayVolume(item.value, unit)}</span>
              </p>
              {item.temperature && (
                <p>
                  <span className="font-semibold">Temp:</span>{" "}
                  {displayTemperature(item.temperature)}
                </p>
              )}
              {item.voltage && (
                <p>
                  <span className="font-semibold">Battery:</span>{" "}
                  {displayPercent(item.voltage)}
                </p>
              )}
            </>
          )}
        />
      </Box>
    </div>
  );
};
