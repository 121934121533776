import {
  IconAlertHexagon,
  IconBox,
  IconHistory,
  IconUsers,
} from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { brandIcons } from "../../../assets";
import { ItemHeader, NotFound, Tabs, Time } from "../../../components";
import { singleItemLoader, tankQuery, useAuth } from "../../../data";
import {
  ChangeHistoryPanel,
  TankActionPanel,
  TankAlertsPanel,
  TankInfoPanel,
  TankLevelsPanel,
  TankUsersPanel,
} from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["add", "edit", "alerts", "delete", "download"]).optional(),
  comment: z.string().optional(),
  role: z.string().optional(),
});

export const Route = createFileRoute("/_authenticated/tanks/$tankId")({
  validateSearch,
  loader: ({ context, params }) =>
    singleItemLoader(context.queryClient, tankQuery(params.tankId)),
  component: Component,
  notFoundComponent: () => (
    <NotFound
      item="Tank"
      fix="Head back to the home page to find what you're looking for"
      action={{ to: "/" }}
      actionLabel="Go to home page"
    />
  ),
  head: ({ loaderData }) => ({
    meta: [
      {
        title: loaderData?.name,
      },
    ],
  }),
});

export function Component() {
  const tankId = Route.useParams({ select: (p) => p.tankId });
  const { data } = useSuspenseQuery(tankQuery(tankId));
  const { hasTeamPermission } = useAuth();

  return (
    <>
      <ItemHeader
        image={brandIcons[data.accountProduct]}
        title={data.name}
        subtitle={<Time date={data.level?.updatedAt} prefix="Last active " />}
      />
      <Tabs
        name="tank"
        tabs={[
          {
            icon: IconBox,
            name: "Tank",
            panel: <TankLevelsPanel tankId={tankId} />,
          },
          {
            icon: IconAlertHexagon,
            name: "Alerts",
            panel: <TankAlertsPanel tankId={tankId} />,
          },
          {
            name: "Users",
            icon: IconUsers,
            hidden: !data.permissions.includes("manage"),
            panel: <TankUsersPanel tankId={tankId} />,
          },
          {
            icon: IconHistory,
            name: "History",
            hidden: !hasTeamPermission("admin"),
            panel: <ChangeHistoryPanel entityType="tank" entityId={tankId} />,
          },
        ]}
        info={<TankInfoPanel tankId={tankId} />}
      />
      <TankActionPanel />
    </>
  );
}
