import { TeamPermission, specialChars } from "@joy/shared-utils";

declare global {
  interface UserPublicMetadata {
    jlteam?: TeamPermission | null | undefined;
  }

  interface UserUnsafeMetadata {
    phone?: string | undefined | null;
    company?: string | undefined | null;
    title?: string | undefined | null;
  }
}

export type DisplayUser =
  | {
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
    }
  | undefined
  | null;

export const userInitials = (user: DisplayUser) => {
  if (!user) return "";

  return (
    [user.firstName, user.lastName]
      .filter(Boolean)
      .map((part) => part?.[0]?.toLocaleUpperCase())
      .join("") || user.email?.[0]?.toLocaleUpperCase()
  );
};

export const userName = (
  user: DisplayUser,
  options?: { hideEmail?: boolean; hideDash?: boolean },
) => {
  const fallback = options?.hideDash ? "" : specialChars.endash;
  if (!user) return fallback;

  return (
    [user.firstName, user.lastName].filter(Boolean).join(" ") ||
    (options?.hideEmail ? "" : user.email) ||
    fallback
  );
};

const colours = [
  "bg-amber-500",
  "bg-emerald-500",
  "bg-teal-500",
  "bg-cyan-600",
  "bg-indigo-500",
  "bg-purple-600",
  "bg-fuchsia-700",
  "bg-rose-600",
];
const mod = (dividend: number, divisor: number) =>
  ((dividend % divisor) + divisor) % divisor;
const hashCode = (fromString = "") =>
  fromString
    .split("")
    .reduce((hash, letter) => letter.charCodeAt(0) + ((hash << 5) - hash), 0);
export const userColour = (user: DisplayUser) =>
  user ? colours[mod(hashCode(user?.id), colours.length)] : "bg-emerald-500";
