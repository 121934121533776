import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import clsx from "clsx";

import { displayVolume, mapOrEmpty, timezoneOptions } from "@joy/shared-utils";

import {
  ComboField,
  FieldError,
  TextInputField,
  cellKinds,
  cellVariants,
  tableParts,
} from "../../components";
import { AccountConfigContentProps } from "../../data";
import {
  fairbanksTanksQuery,
  fairbanksUsersQuery,
} from "../../data/api/fairbanks";

const ProGaugeTanks = ({
  form,
  validators,
  userId,
}: AccountConfigContentProps & { userId: string | undefined }) => {
  const fairbanksTanks = useQuery(fairbanksTanksQuery(userId));

  let text = "Select a User ID to load tanks...";
  if (userId) text = `No tanks found for "${userId}".`;
  if (fairbanksTanks.isLoading) text = "Loading tanks...";
  if (fairbanksTanks.isError) text = "Failed to load tanks.";

  return (
    <table className={tableParts.table}>
      <thead className={tableParts.head}>
        <tr>
          <th className={clsx(text, cellKinds.title)}>Tank</th>
          <th className={clsx(text, cellKinds.title)}>Current</th>
          <th className={clsx(text, cellKinds.title)}>Safe Fill Level</th>
        </tr>
      </thead>
      <tbody>
        {mapOrEmpty(
          fairbanksTanks.data,
          ({ id, volume }) => (
            <tr key={id}>
              <td className={clsx(cellVariants.base, "px-5 font-bold")}>
                {id}
              </td>
              <td className={clsx(cellVariants.base, "px-3 text-right")}>
                {displayVolume(volume, "l")} /
              </td>
              <td className={clsx(cellVariants.base, "px-3")}>
                <form.Field
                  name={`maximum.${id}`}
                  validators={validators.maximumLevel}
                  children={(field) => (
                    <div>
                      <TextInputField
                        field={field}
                        kind="simple"
                        type="number"
                        inputMode="decimal"
                        placeholder="l"
                      />
                      <FieldError field={field} />
                    </div>
                  )}
                />
              </td>
            </tr>
          ),
          <tr>
            <td colSpan={3} className={clsx(cellVariants.base, "px-3 py-6")}>
              <p className="text-center font-semibold italic text-gray-400">
                {text}
              </p>
            </td>
          </tr>,
        )}
      </tbody>
    </table>
  );
};

export const ProGaugeContent = ({
  data,
  form,
  validators,
}: AccountConfigContentProps) => {
  const fairbanksUsers = useInfiniteQuery(fairbanksUsersQuery());

  return (
    <>
      <form.Field
        name="userId"
        validators={validators.userId}
        children={(field) => (
          <ComboField
            field={field}
            label="User ID"
            options={fairbanksUsers.data || []}
            accessors={[(o) => o.value]}
            optionKey={(o) => o.value}
            optionLabel={(o) => o.value}
            optionCreate={(value) => ({ value })}
            immediate
          />
        )}
      />
      <form.Field
        name="timezone"
        validators={validators.timezone}
        children={(field) => (
          <ComboField
            field={field}
            label="Timezone"
            options={timezoneOptions}
            accessors={[(o) => o.value]}
            optionKey={(o) => o.value}
            optionLabel={(o) => o.value}
            immediate
          />
        )}
      />
      <form.Subscribe
        selector={(v) => v.values.userId?.value}
        children={(userId) => (
          <ProGaugeTanks
            data={data}
            form={form}
            validators={validators}
            userId={userId}
          />
        )}
      />
    </>
  );
};
