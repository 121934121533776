import { IconCloudUpload, IconTrashX } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import {
  Button,
  Editor,
  EntityUpdated,
  FormError,
  ItemHeader,
  LinkButton,
  Loading,
  NotFound,
  TextField,
} from "../../../../components";
import {
  documentQuery,
  singleItemLoader,
  useAuth,
  useUpdateDocument,
} from "../../../../data";
import { DocumentActionPanel } from "../../../../panels";

const validateSearch = z.object({
  action: z.enum(["delete"]).optional(),
});

export const Route = createFileRoute("/_admin/library/documents/$documentId")({
  validateSearch,
  loader: ({ context, params }) =>
    singleItemLoader(context.queryClient, documentQuery(params.documentId)),
  component: Component,
  notFoundComponent: () => (
    <NotFound
      item="Document"
      action={{ to: "/library/documents", search: { action: "create" } }}
      actionLabel="Add document"
    />
  ),
  head: ({ loaderData }) => ({
    meta: [
      {
        title: loaderData?.title,
      },
    ],
  }),
});

export function Component() {
  const { hasTeamPermission } = useAuth();
  const documentId = Route.useParams({ select: (p) => p.documentId });

  const { data, error, form, validators } = useUpdateDocument(documentId);

  return (
    <div className="flex h-full min-h-0 flex-col">
      <ItemHeader title={data.title} subtitle={<EntityUpdated {...data} />}>
        {hasTeamPermission("super") && (
          <div className="flex flex-1 items-center justify-end gap-x-4">
            <form.Subscribe
              selector={(state) => state.isSubmitting || state.isPristine}
              children={(disabled) => (
                <Button
                  disabled={disabled}
                  onClick={form.handleSubmit}
                  icon={IconCloudUpload}
                  text="Update"
                  collapseText
                />
              )}
            />
            <LinkButton
              search={{ action: "delete" }}
              variant="danger"
              icon={IconTrashX}
              text="Delete"
              collapseText
              replace
            />
          </div>
        )}
      </ItemHeader>
      {hasTeamPermission("super") ? (
        <>
          <div className="container mx-auto mt-6">
            <FormError error={error} />
            <form.Field
              name="title"
              validators={validators.title}
              children={(field) => <TextField field={field} label="Title" />}
            />
          </div>
          <div className="container relative mx-auto mt-6 min-h-0 flex-1">
            <form.Field
              name="content"
              children={(field) => (
                <Editor
                  placeholder={`${data.title}...`}
                  initialValue={field.state.value}
                  onChange={(value) => field.handleChange(value)}
                />
              )}
            />
          </div>
          <Loading show={form.state.isSubmitting} text="Updating..." />
          <DocumentActionPanel />
        </>
      ) : (
        <article
          className="container mx-auto py-6"
          dangerouslySetInnerHTML={{ __html: data.html || "" }}
        />
      )}
    </div>
  );
}
