import { Transition } from "@headlessui/react";
import { IconArrowRight, IconLineDashed } from "@tabler/icons-react";
import clsx from "clsx";
import { ForwardedRef, ReactNode, forwardRef, useState } from "react";

import { keys } from "@joy/shared-utils";

type TreeRenderProps = {
  value: any;
  isObject: boolean;
  renderer?: (value: any) => ReactNode;
};

const TreeRender = ({ value, isObject, renderer }: TreeRenderProps) => {
  if (renderer) return renderer(value);
  if (isObject) return null;

  let asString;
  switch (typeof value) {
    case "string":
      asString = value;
      break;
    case "number":
      asString = value.toString();
      break;
    default:
      asString = JSON.stringify(value);
  }

  return (
    <div className="whitespace-pre-line text-wrap break-all">{asString}</div>
  );
};

type TreeItemProps = {
  label: string;
  value: any;
  renderers?: {
    [key: string]: (value: any) => ReactNode;
  };
  initiallyExpanded?: boolean;
};

const TreeItem = forwardRef(
  (
    { label, value, renderers, initiallyExpanded }: TreeItemProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [show, setShow] = useState(initiallyExpanded || false);
    const isObject =
      typeof value === "object" && !(value instanceof Date) && value !== null;

    return (
      <>
        <div ref={ref} className="flex items-start gap-1">
          <button
            className="flex items-center gap-1"
            onClick={() => isObject && setShow(!show)}
          >
            {isObject ? (
              <IconArrowRight className={clsx("size-4", show && "rotate-90")} />
            ) : (
              <IconLineDashed className="size-4" />
            )}
            <span className="font-semibold">{label}: </span>
          </button>
          <TreeRender
            value={value}
            isObject={isObject}
            renderer={renderers?.[label]}
          />
        </div>
        {isObject ? (
          <Transition show={show}>
            <Tree
              data={value}
              renderers={renderers}
              initiallyExpanded={initiallyExpanded}
              className="ml-5"
            />
          </Transition>
        ) : null}
      </>
    );
  },
);

export type TreeProps = {
  data: object | null | undefined;
  initiallyExpanded?: boolean;
  className?: string;
  renderers?: {
    [key: string]: (value: any) => ReactNode;
  };
};

export const Tree = forwardRef(
  (
    { data, className, renderers, initiallyExpanded }: TreeProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) =>
    data ? (
      <div ref={ref} className={clsx(className, "flex flex-col gap-1")}>
        {keys(data).map((key) => (
          <TreeItem
            key={key}
            label={key}
            value={data[key]}
            renderers={renderers}
            initiallyExpanded={initiallyExpanded}
          />
        ))}
      </div>
    ) : null,
);
