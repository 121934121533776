import { useMatch } from "@tanstack/react-router";

import { Actions } from "../../components";
import { InvoiceDeleteContent } from "./delete";
import { InvoiceRegenerateContent } from "./regenerate";

export const InvoiceActionPanel = () => {
  const { Modal, props } = useMatch({
    from: "/_super/invoices/$monthYear/$invoiceId",
    select: (s) => {
      const props = {
        invoiceId: s.params.invoiceId,
      };

      switch (s.search.action) {
        case "delete":
          return {
            Modal: InvoiceDeleteContent,
            props,
          };
        case "regenerate":
          return {
            Modal: InvoiceRegenerateContent,
            props,
          };
      }

      return { props };
    },
  });

  return <Actions modal={Modal && <Modal {...props} />} />;
};
