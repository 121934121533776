import { standardSchemaValidator, useForm } from "@tanstack/react-form";
import {
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { z } from "zod";

import { useValidators } from "../../hooks";
import { request, requestFn } from "./base";
import {
  GaugeSettingsDocument,
  UpdateGaugeSettingDocument,
} from "./operations.generated";

export const gaugeSettingsQuery = (id: string) =>
  queryOptions({
    queryKey: ["gauge-settings", id],
    queryFn: () => request(GaugeSettingsDocument, { id }),
    select: (data) => ({
      id: data.gauge?.id,
      name: data.gauge?.name,
      product: data.gauge?.product,
      deactivatedAt: data.gauge?.deactivatedAt,
      setting: data.gauge?.setting,
      response: data.gauge?.responses.responses[0],
      observation: data.gauge?.observations.observations[0],
      permissions: data.gaugeAccess,
    }),
  });

export type GaugeSettingsItem = ReturnType<
  NonNullable<ReturnType<typeof gaugeSettingsQuery>["select"]>
>;
const updateGaugeSettingFn = requestFn(UpdateGaugeSettingDocument);

export const useGaugeSettingMutation = (gaugeId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(gaugeSettingsQuery(gaugeId));
  const queryClient = useQueryClient();
  const update = useMutation({
    mutationFn: updateGaugeSettingFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(
        gaugeSettingsQuery(gaugeId).queryKey,
        (existing) => {
          if (!existing) return undefined;
          return {
            ...existing,
            gauge: existing.gauge
              ? {
                  ...existing.gauge,
                  setting: result.updateGaugeSetting,
                }
              : null,
          };
        },
      );
      await navigate({ to: "/gauges/$gaugeId", params: { gaugeId } });
    },
  });

  return { data, update };
};

export const useDisableGauge = (gaugeId: string) => {
  const {
    data,
    update: { error, isPending, mutateAsync },
  } = useGaugeSettingMutation(gaugeId);

  return {
    data,
    error,
    isPending,
    onDisable: () => mutateAsync({ id: gaugeId, fields: { active: false } }),
  };
};

const gaugeScheduleValidation = {
  every: z.enum(["Week", "Fortnight", "Month"], {
    invalid_type_error: "Please select a schedule type",
  }),
  timezone: z.object(
    { value: z.string() },
    { invalid_type_error: "Please select a timezone" },
  ),
  time: z.object(
    { hour: z.number().min(1).max(23) },
    { invalid_type_error: "Please select a time" },
  ),
  frequency: z.object(
    { value: z.number().min(1).max(24) },
    { invalid_type_error: "Please select a frequency" },
  ),
};

export const useUpdateGaugeSchedule = (gaugeId: string) => {
  const {
    data,
    update: { error, mutateAsync, reset },
  } = useGaugeSettingMutation(gaugeId);

  const form = useForm({
    defaultValues: {
      timezone: {
        value: data.setting?.timezone || "UTC",
      },
      every: data?.setting?.settings.schedule?.every || "Week",
      days: {
        Sunday: data.setting?.settings.schedule?.days.Sunday ?? true,
        Monday: data.setting?.settings.schedule?.days.Monday ?? true,
        Tuesday: data.setting?.settings.schedule?.days.Tuesday ?? true,
        Wednesday: data.setting?.settings.schedule?.days.Wednesday ?? true,
        Thursday: data.setting?.settings.schedule?.days.Thursday ?? true,
        Friday: data.setting?.settings.schedule?.days.Friday ?? true,
        Saturday: data.setting?.settings.schedule?.days.Saturday ?? true,
      },
      startTime: {
        hour: data.setting?.settings.schedule?.startTime.hour ?? 8,
      },
      frequency: {
        value: data.setting?.settings.schedule?.frequency || 24,
      },
      endTime: {
        hour: data.setting?.settings.schedule?.endTime || 23,
      },
    },
    onSubmit: async ({
      value: { timezone, every, days, startTime, frequency, endTime },
    }) => {
      await mutateAsync({
        id: gaugeId,
        fields: {
          timezone: timezone.value,
          settings: {
            schedule: {
              every,
              days,
              startTime: {
                hour: startTime.hour,
                minute: 0,
              },
              frequency: frequency.value,
              endTime: endTime.hour,
            },
          },
        },
      });
    },
    onSubmitInvalid: () => {
      reset();
    },
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(
    gaugeScheduleValidation,
    form.state.submissionAttempts,
  );

  return { data, error, form, validators };
};

const gaugeSonicQualityValidation = {
  measurement: z.enum(["standard", "waveguide"], {
    invalid_type_error: "Please select a measurement type",
  }),
  filter: z.coerce.number().int().min(1).max(10),
  height: z.coerce.number().int(),
};

export const useUpdateGaugeSonicQuality = (gaugeId: string) => {
  const {
    data,
    update: { error, mutateAsync, reset },
  } = useGaugeSettingMutation(gaugeId);

  const form = useForm({
    defaultValues: {
      measurement:
        (data.setting?.settings &&
          "measurement" in data.setting.settings &&
          data.setting.settings.measurement) ||
        "standard",
      rssi: (
        (data.setting?.settings &&
          "quality" in data.setting.settings &&
          data.setting.settings.quality?.rssi) ||
        1
      ).toString(),
      src: (
        (data.setting?.settings &&
          "quality" in data.setting.settings &&
          data.setting.settings.quality?.src) ||
        9
      ).toString(),
      height: (
        (data.setting?.settings &&
          "height" in data.setting.settings &&
          data.setting.settings.height) ||
        0
      ).toString(),
    },
    onSubmit: async ({ value: { measurement, rssi, src, height } }) => {
      await mutateAsync({
        id: gaugeId,
        fields: {
          settings: {
            measurement,
            quality: {
              rssi: parseInt(rssi),
              src: parseInt(src),
            },
            height: parseInt(height),
          },
        },
      });
    },
    onSubmitInvalid: () => {
      reset();
    },
    validatorAdapter: standardSchemaValidator(),
  });
  const validators = useValidators(
    gaugeSonicQualityValidation,
    form.state.submissionAttempts,
  );

  return { data, error, form, validators };
};
