import { IconHistory } from "@tabler/icons-react";
import { infiniteQueryOptions } from "@tanstack/react-query";

import { Word } from "../helpers";
import { request } from "./base";
import { ChangeEntity, ChangesDocument } from "./operations.generated";

export const change: Word = {
  icon: IconHistory,
  article: "a",
  singular: "change",
  plural: "changes",
};

export const changesQuery = (entityType: ChangeEntity, entityId: string) =>
  infiniteQueryOptions({
    queryKey: ["changes", entityType, entityId],
    queryFn: ({ pageParam }) =>
      request(ChangesDocument, {
        entityType,
        entityId,
        limit: 500,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.changes.next,
    initialPageParam: "",
    select: (data) => data.pages.flatMap((p) => p.changes.changes),
  });
